import localisable from 'Commons/config/strings/localisable';
import { withStyles } from
'Commons/components/generic/componentlibrary/components/Components';
import BaseComponent from 'Commons/components/business/basecomponent/components/BaseComponent';
import STATUS from 'Commons/config/constants/StoreKeyStatus';
import { TABLE_ROW_HEIGHT_VARIANT, SOURCE, DASHBOARD_TYPE, EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import getName from 'Commons/helpers/utils/NameHelper';
import getUserColumns from '../config/UserListConfig';
import userListFormattersStyle from '../style/UserListFormatterStyle';
import { USER_DEFAULT_SORT, USER_DEFAULT_FILTER } from '../config/Constants';

class UserList extends BaseComponent {
    constructor(props) {
        super(props);
        const { withProvider } = window;
        this.SmartList = withProvider('SmartList', this.props);
    }

    getUserListConfig = () => {
        const { classes, match: { params: { accountId } } } = this.props;
        return {
            source: SOURCE.user.value,
            store: 'user',
            columns: getUserColumns(classes),
            createUrl: buildUrl('userCreate', EMPTY_OBJECT, DASHBOARD_TYPE.SETTINGS, accountId),
            name: localisable.userDisplayName,
            emptyBodyText: localisable.userListEmpty,
            tableProps: {
                headerRowHeight: TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER,
                rowHeight: 48,
            },
            tablePaperProps: { hideSearch: true, pageTitle: localisable.users },
            getCustomData: (props) => {
                const { user: { data: { data: userData = [] } = {}, status = STATUS.UNLOADED } = {} } = props;
                if (status !== this.prevStatus && (status === STATUS.LOADED || status === STATUS.UPDATED)) {
                    this.userData = userData.map((user) => {
                        const { firstName, lastName } = user;
                        return { ...user, label: getName({ lastName, firstName }) };
                    });
                }
                this.prevStatus = status;
                return this.userData;
            },
            defaultSort: USER_DEFAULT_SORT,
            defaultFilters: USER_DEFAULT_FILTER,
            filtersAndSortsProps: { hideSearch: true },
            showFilterAndSorts: true,
            useAccountContext: true,
        };
    }

    render() {
        const { routeList, ...props } = this.props;
        const { SmartList } = this;
        return (
            <SmartList
                listConfig={this.getUserListConfig()}
                routeList={routeList}
                {...props}
            />
        );
    }
}

UserList.propTypes = {
    match: PropTypes.object,
    routeList: PropTypes.object,
};

export default withStyles(userListFormattersStyle)(UserList);
