import { memo, useState } from 'react';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import Typography from 'Generic/typography/components/Typography';
import Icon from 'Generic/icon/components/Icon';
import localisable from 'Commons/config/strings/localisable';
import { clsx } from 'Commons/helpers/utils/clsx';
import bevLeftSidebarStyles
from 'Commons/components/business/bev/components/setup/bevLeftSideBar/styles/BevLeftSidebarStyles';
import { screenMaxWidth } from 'Commons/config/constants/ScreenWidths';
import { DIRECTIONS } from '../../../../config/Constants';

const useStyles = makeStyles(bevLeftSidebarStyles, { name: 'BevLeftSideBar' });

const Add = ({
    formProps,
    activeDirection: activeDirectionInParent,
    onDirectionChange,
    handleAddingEntityOnPlusButtonClick,
}) => {
    const { innerWidth } = window;
    const classes = useStyles();
    const { isValid } = formProps;
    const { UP, RIGHT, DOWN, LEFT } = DIRECTIONS;

    const [activeDirection, setActiveDirection] = useState(activeDirectionInParent);

    const onDirectionClick = direction => () => {
        const newDirection = activeDirection !== direction ? direction : null;
        setActiveDirection(newDirection);
        onDirectionChange(newDirection);
    };

    return (
        <Grid
            container
            className={classes.sectionContainer}
            direction={innerWidth <= screenMaxWidth.laptop ? 'column' : 'row'}
            justify="center"
            alignItems="center"
        >
            <Grid
                container
                item
                xl={6}
            >
                <Typography variant="body2" fontFamily="Open Sans">
                    {localisable.bevDirectionButtonsDescription}
                </Typography>
            </Grid>
            <Grid
                container
                direction="column"
                item
                xl={6}
                className={classes.addButtonsContainer}
            >
                <Grid container justify="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={clsx(classes.button,
                            activeDirection === DIRECTIONS.UP && classes.keepButtonActive)}
                        onClick={onDirectionClick(UP)}
                    >
                        <Icon
                            icon="cp-collapse"
                            type="custom"
                        />
                    </Button>
                </Grid>
                <Grid container justify="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={clsx(classes.button,
                            activeDirection === DIRECTIONS.LEFT && classes.keepButtonActive)}
                        onClick={onDirectionClick(LEFT)}
                    >
                        <Icon
                            icon="cp-chevron-left"
                            type="custom"
                        />
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={`${classes.button} ${classes.addButton}`}
                        onClick={handleAddingEntityOnPlusButtonClick}
                        disabled={!isValid}
                    >
                        <Icon
                            icon="cp-plus"
                            type="custom"
                        />
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={clsx(classes.button,
                            activeDirection === DIRECTIONS.RIGHT && classes.keepButtonActive)}
                        onClick={onDirectionClick(RIGHT)}
                    >
                        <Icon
                            icon="cp-chevron-right"
                            type="custom"
                        />
                    </Button>
                </Grid>
                <Grid container justify="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={clsx(classes.button,
                            activeDirection === DIRECTIONS.DOWN && classes.keepButtonActive)}
                        onClick={onDirectionClick(DOWN)}
                    >
                        <Icon
                            icon="cp-expand"
                            type="custom"
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

Add.propTypes = {
    formProps: PropTypes.object.isRequired,
    activeDirection: PropTypes.string,
    onDirectionChange: PropTypes.func,
    handleAddingEntityOnPlusButtonClick: PropTypes.func.isRequired,
};

Add.defaultProps = {
    activeDirection: DIRECTIONS.RIGHT,
    onDirectionChange: () => {},
};

export default memo(Add);
