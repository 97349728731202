import Theme from 'Commons/theme/Theme';
import { useState, useEffect, memo, useRef } from 'react';
import { Grid, Divider, Typography, Collapse, makeStyles }
from 'Generic/componentlibrary/components/Components';
import Icon from 'Generic/icon/components/Icon';
import { clsx } from 'Commons/helpers/utils/clsx';
import sectionHeaderStyle from '../styles/SectionStyle';

const useStyles = makeStyles(sectionHeaderStyle, { name: 'Section' });

const Section = ({
    open: openProp, updateStateOfOpenInParent, onRef, controlled,
    onClick: onClickProp, titleVariant, subHeaderVariant, getChildrenContainerRef, ...props
}) => {
    const classes = useStyles(props);
    const childrenContainerRef = useRef();
    const [openState, setOpenState] = useState(openProp);

    useEffect(() => {
        onRef({});
        if (getChildrenContainerRef) {
            getChildrenContainerRef(childrenContainerRef);
        }
    }, []);

    const onClick = (id) => {
        if (!controlled) {
            setOpenState(() => !openState);
        }
        updateStateOfOpenInParent();
        onClickProp(id);
    };

    const renderSectionHeaderOrSubHeader = (startElement, isHeader = false) => {
        const {
            title, subHeader,
            disabled, shouldShowCursorPointer,
        } = props;
        const elementStyleClass = isHeader ? classes.title : classes.subHeader;
        const variant = isHeader ? titleVariant : subHeaderVariant;
        const value = isHeader ? title : subHeader;
        return (
            <Typography
                variant={variant}
                component="div"
                className={clsx(
                    elementStyleClass,
                    {
                        [classes.marginLeft]: startElement,
                        [classes.disabled]: disabled,
                        [classes.cursorPointer]: shouldShowCursorPointer,
                    },

                )}
            >
                {value}
            </Typography>
        );
    };

    const {
        globalClasses: {
            subsectionPadding: { className: subsectionPadding },
            inner: { className: inner },
        },
    } = Theme;
    const {
        children, DividerProps, title, className, cardHeader, collapsable, id, classes: overriddenClasses,
        subsection, divider, addon: { start, end }, subHeader, staticContext, CollapseProps, disabled,
        collapseIcon, expandIcon, LabelWithIconProps, history, location, match, onDemandSection,
        subsectionPadding: ignore, subsectionLeftPadding, shouldShowCursorPointer, onAddOrRemoveSection,
        ...otherProps
    } = props;
    const isOpen = controlled ? openProp : openState;
    const icon = isOpen ? collapseIcon : expandIcon;
    const startElement = start || (collapsable && (icon ? (<Icon disabled={disabled}> {icon} </Icon>) : null));

    const renderChildren = (shouldApplyPadding = true, shouldApplyLeftPadding = true) => (
        shouldApplyPadding
            ? (
                <Grid
                    item
                    className={clsx({
                        [subsectionPadding]: shouldApplyLeftPadding,
                        [inner]: shouldApplyLeftPadding,
                    })}
                >
                    {children}
                </Grid>
            )
            : children
    );
    return (
        <Grid
            container
            className={`${classes.section} ${className}`}
            {...otherProps}
        >
            <Grid
                container
                direction="column"
                wrap="nowrap"
                className={classes.sectionChildren}
            >
                <Grid
                    item
                    className={
                        clsx({
                            [classes.cardHeader]: cardHeader,
                            [classes.collapsedSection]: !isOpen,
                            [classes.subsection]: subsection,
                            [classes.header]: !subsection,
                        })}
                >
                    <Grid container justify="space-between">
                        <Grid
                            container
                            onClick={() => onClick(id)}
                            wrap="nowrap"
                            alignItems="center"
                            className={clsx(classes.addonStart, { [classes.cursorPointer]: shouldShowCursorPointer })}
                        >
                            {startElement}
                            {renderSectionHeaderOrSubHeader(startElement, true)}
                        </Grid>
                        {end}
                    </Grid>
                    {subHeader && typeof (subHeader) === 'string'
                        ? renderSectionHeaderOrSubHeader(startElement)
                        : subHeader}
                    {divider && <Divider className={classes.divider} {...DividerProps} />}
                </Grid>
                <Grid
                    ref={childrenContainerRef}
                    item
                    className={classes.children}
                >
                    {
                        collapsable
                            ? (
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    {...CollapseProps}
                                >
                                    {renderChildren(true, subsectionLeftPadding)}
                                </Collapse>
                            )
                            : (
                                renderChildren(ignore, subsectionLeftPadding)
                            )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

Section.propTypes = {
    children: PropTypes.node,
    DividerProps: PropTypes.object,
    open: PropTypes.bool,
    collapsable: PropTypes.bool,
    className: PropTypes.string,
    subsection: PropTypes.bool,
    shouldShowCursorPointer: PropTypes.bool,
    divider: PropTypes.bool,
    addon: PropTypes.shape({
        start: PropTypes.node,
        end: PropTypes.node,
    }),
    classes: PropTypes.object,
    title: PropTypes.node,
    cardHeader: PropTypes.bool,
    subHeader: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    match: PropTypes.object,
    id: PropTypes.any,
    staticContext: PropTypes.any,
    onRef: PropTypes.func,
    CollapseProps: PropTypes.object,
    location: PropTypes.object,
    updateStateOfOpenInParent: PropTypes.func,
    disabled: PropTypes.bool,
    collapseIcon: PropTypes.node,
    expandIcon: PropTypes.node,
    LabelWithIconProps: PropTypes.object,
    history: PropTypes.object,
    onDemandSection: PropTypes.bool,
    controlled: PropTypes.bool,
    onClick: PropTypes.func,
    subsectionPadding: PropTypes.bool,
    subsectionLeftPadding: PropTypes.bool,
    titleVariant: PropTypes.string,
    getChildrenContainerRef: PropTypes.func,
    onAddOrRemoveSection: PropTypes.func,
    subHeaderVariant: PropTypes.string,
};

Section.defaultProps = {
    className: '',
    open: true,
    collapsable: false,
    subsection: false,
    shouldShowCursorPointer: false,
    divider: false,
    addon: {},
    cardHeader: false,
    match: {},
    onRef: () => { },
    CollapseProps: {},
    location: {},
    disabled: false,
    updateStateOfOpenInParent: () => { },
    onClick: () => { },
    collapseIcon: 'expand_more',
    expandIcon: 'expand_less',
    subsectionPadding: false,
    subsectionLeftPadding: true,
    titleVariant: 'h6',
    subHeaderVariant: 'subtitle1',
};

export default memo(Section);
