const localisable = {
    // Commons
    admin: 'Admin',
    listStatus: 'STATUS',
    clone: 'Clone',
    between: 'Between',
    and: 'And',
    from: 'From',
    to: 'To',
    atLeastOneRequired: '(At-least one option has to be selected)',
    reverse: 'Reverse',
    undo: 'Undo',
    markedForDeletionNew: 'Do You Want To Remove?',
    markedForDeletionExisting: 'Marked For Deletion',
    noPermissions: 'User does not have permissions to view this.',
    noActionPermission: 'User does not have permission to perform this action',
    syrasoftConnect: 'Syrasoft Connect',
    sales: 'Sales',
    support: 'Support',
    copyright: 'Copyrights Reserved @ Syrasoft',
    allRightReserved: 'All right reserved @ Syrasoft',
    createdBy: 'Created By',
    setupStatus: 'Setup Status',
    softwareAdoptionStatus: 'Software Adoption Status',
    softwareAdoptionStatusHeading: 'S/W Status',
    recurring: 'Recurring',
    now: 'Now',
    once: 'Once',
    recur: 'Recur',
    all: 'All',
    before: 'before',
    warnings: 'Warnings',
    resolved: 'Resolved',
    affectedUnits: 'Affected Units',
    pleaseEnterAValueInTheTextfield: 'Please enter a value in the textfield',
    std: 'Std',
    frequency: 'Frequency',
    savedDocuments: 'Saved Documents',
    noSavedDocuments: 'No Saved Documents',
    link: 'Link',
    writeOff: 'Write Off',
    id: 'ID',
    na: 'NA',

    // All long texts which provides user a detailed information
    messages: { defaultNoContent: 'The list is empty.' },

    // All short texts such as button label etc
    loadingMoreResults: 'Loading More Results',
    noMoreResults: 'No More Results',
    keepChanges: 'Keep Changes',
    reserve: 'Reserve',

    activate: 'Activate',
    deactivate: 'Deactivate',
    save: 'Save',
    saving: 'Saving',
    applyFilters: 'Apply',
    clearBalance: 'Clear Balance',
    cancel: 'Cancel',
    discardChangesCaps: 'DISCARD CHANGES',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    separator: ' | ',
    nbsp: '\u00A0',
    somethingWentWrong: 'Something Went Wrong',
    couldNotLoadLink: 'Could not load link !',
    takingLongerThanExpected: 'The process is experiencing an extended duration compared to the norm. We suggest completing the task and subsequently verifying the reservation quote within the saved documents of the tenants.',
    search: 'Search',
    noResultFound: 'No Results Found',
    noDataFound: 'No Data Found',
    key: 'Key',
    reset: 'Reset',
    failedToSave: 'Failed to save',
    view: 'View',
    dismiss: 'Dismiss',
    dismissed: 'Dismissed',
    dismissing: 'Dismissing',

    formSaveNote: 'Note: Please Save Change before navigating to any other page',
    saveConfirmation: 'Are you sure you want to save it?',
    discardConfirmation: 'Are you sure you want to discard changes?',

    unitActivateConfirmation: 'Are you sure you want to activate unit?',
    unitDeactivateConfirmation: 'Are you sure you want to deactivate unit?',
    unitDisplayName: 'UNIT LIST',
    unitCreate: 'Create Unit',

    unitListLabel: 'UNIT NUMBER',
    unitListType: 'UNIT TYPE',
    unitListRented: 'RENTAL STATUS',
    unitListStatus: 'UNIT STATUS',
    unitListRate: 'RATE',
    unitListTenantName: 'TENANT NAME',
    unitListNextRentAssessmentDate: 'NEXT RENT\nASSESSMENT DATE',
    unitListComments: 'COMMENTS',
    unitListActions: 'ACTIONS',
    unitListEmpty: 'No Units Found',
    unitListLoading: 'Units Loading...',
    unitCardComments: 'Comments',
    unitCardType: 'Unit Type',

    unitFormError: 'Unit Form Error',
    unitFormLoading: 'Unit Form Loading...',

    unitSettingDisplayName: 'Unit',

    vendorFormError: 'Vendor Form Error',
    vendorFormLoading: 'Vendor Form Loading...',

    tenantCardUnits: 'Units',
    tenantCardBalance: 'Balance',
    tenantCardComments: 'Comments',
    tenantCardLedger: 'Ledger',
    tenantCardLedgers: 'Ledgers',
    tenantCardAlternateContact: 'Alternate Contact',
    alternateContactInformation: 'Alternate Contact Information',

    reservationCardCode: 'Reservation',
    vacant: 'Vacant',
    noUnitsVacant: 'No Units Vacant',

    whatDoYouWantToDo: 'What do you want to do ?',

    // Tenant Localises

    tenantTitle: 'TENANT',
    tenantListAddress: 'ADDRESS',
    tenantListContact: 'CONTACT',
    tenantListAlternateContactName: 'ALTERNATE CONTACT',
    tenantListUnits: 'UNITS',
    tenantListBalance: 'BALANCE',
    tenantListActions: 'ACTIONS',
    tenantListEmpty: 'No Tenants Found',
    tenantListLedger: 'LEDGER',
    tenantSectionCap: 'TENANT SECTION',

    tenantFormError: 'Tenant Form Error',
    tenantFormLoading: 'Tenant Form Loading...',
    tenantFormMinimumLedgerMessage: 'Please enter at least one ledger',
    tenantFormAddLedger: 'ADD LEDGER',
    tenantFormDiscardLedger: 'Discard Ledger',
    tenantFormAddPhone: 'Add Phone',
    tenantFormAddAuthorizedPeople: 'Add Additional Authorized People',
    tenantFormAddIdProof: 'Add ID Proof',
    tenantActivateConfirmation: 'Are you sure you want to activate tenant?',
    tenantDeactivateConfirmation: 'Are you sure you want to deactivate tenant?',
    duplicatePhones: 'Multiple phone types with same number',
    duplicateNumber: 'Duplicate Number',

    tenantCreated: 'Tenant Created Successfully',
    tenantUpdated: 'Tenant Updated Successfully',

    tenantNotFound: 'Tenant Not Found',

    tenantDisplayName: 'TENANT LIST',

    sections: 'Sections',

    requiredFieldsMissing: 'Required fields missing! ',
    missingFields: 'Please add the missing details of the tenant.',

    showCredentials: 'Show Credentials',
    hideCredentials: 'Hide Credentials',

    sendLink: 'Send Link',
    reSendLink: 'Resend Link',

    // Name
    tenantName: 'Tenant Name',
    tenantNameSalutation: 'Salutation',
    tenantNamePreferredName: 'Preferred Name',
    tenantNameBusinessName: 'Business Name',
    tenantNameSuffix: 'Suffix',

    fax: 'Fax',

    // Address
    address: 'Address',
    city: 'City',
    state: 'State',
    country: 'Country',
    zipCode: 'Zip Code',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',

    // Contact
    enableText: 'Enable Text',
    enablePrimaryNumber: 'Enable this as Primary Phone',
    enableEmail: 'Enable Email',
    phone: 'Phone',
    primaryPhone: 'Primary Phone',
    cellPhone: 'Cell Phone',
    homePhone: 'Home Phone',
    businessPhone: 'Business Phone',
    chooseSpecificPhone: 'Choose Specific Phone',
    faxNumber: 'Fax Number',
    emailId: 'Email ID',
    collectAnyPhone: 'Collect any Phone',
    selectPhone: 'Select Phone',
    enterPhone: 'Enter Phone',
    enterFax: 'Enter Fax Number',
    textEnabled: 'Text Enabled',
    anyPhone: 'Any Phone',
    faxPhone: 'Fax Phone',
    messagingService: 'Messaging service is not enabled for the facility',
    tooltipInfoMessage: 'Text message service is only available for cell phone',
    conversationsTooltipMessage: 'Text message service is not enabled for this facility',
    notSupportedText: 'Messaging service is not supported for this country',

    // Other Info
    tenantLanguage: 'Language',
    tenantDob: 'Date of Birth',

    // ID Proof
    idProof: 'Id Proof',
    idProofCaps: 'ID PROOF',
    idProofDriverLicense: 'Driver\'s License',
    idProofLicenseExpiryDate: 'Expiry Date',
    idProofStateId: 'State ID',
    idProofSsn: 'SSN',
    collectAnyId: 'Collect Any ID',
    idProofResidentId: 'Resident ID',
    idProofVoterId: 'Voter ID',
    idProofPassportId: 'Passport ID',
    chooseSpecificId: 'Choose Specific Id',
    anyId: 'Any Id',
    ids: 'ID\'s',

    // Ledger
    ledgerActivateConfirmation: 'Are you sure you want to activate ledger?',
    ledgerDeactivateConfirmation: 'Are you sure you want to deactivate ledger?',
    ledgerLabel: 'Ledger Label',
    ledgerDelayedBy: 'Delayed By',
    ledgerDays: 'days',
    ledgerExemptFromSurcharge: 'Exempt From Surcharge / Discount',
    generateDeclinedLetter: 'Generate Declined Letter',
    ledgerPrintReceipt: 'Print Receipt',
    deleteTransaction: 'Delete Transaction',
    reverseTransaction: 'Reverse Transaction',
    reverseComments: 'Reason for reversal',
    deleteComments: 'Reason for deletion',

    // Payment Information
    preferredPaymentType: 'Preferred Payment Type',
    achAccountNumber: 'Account Number',
    achRoutingNumber: 'Routing Number',
    achBankName: 'Bank Name',
    creditCardNumber: 'Credit Card Number',
    creditCardNo: 'Credit Card No.',
    creditCardExpiry: 'Expiry',
    creditCardBillingName: 'Name on Card',
    creditCardCvv: 'CVV',
    monthFormat: 'MM',
    yearFormat: 'YY',
    selectCardProcessor: 'Select Card Processor',

    // MilitaryInfo
    militaryInfoId: 'Military ID',
    militaryInfoType: 'Military Type',
    militaryInfoDeployedUntil: 'Deployed Until',
    militaryInfoBranch: 'Branch',
    militaryInfoDivision: 'Division',
    militaryInfoCommandingOfficer: 'Commanding Officer',
    militaryInfoCOPhone: 'C.O. Phone',

    // Seasonal Contact
    seasonalContactFrom: 'From Date',
    seasonalContactTo: 'To Date',
    seasonalInformation: 'Seasonal Information',
    employerInformation: 'Employer Information',

    // Tax
    taxExempted: 'Tax Exempted',
    taxExempt: 'Tax Exempt',
    taxExemptNumber: 'Tax Exempt Number',
    taxExemptCategory: 'Tax Exempt Category',

    // Additional Authorized People
    ok: 'Ok',
    accessNumber: 'Access Number',
    unitNumber: 'Unit Number',
    relationship: 'Relationship',
    aap: 'Additional Authorized People',
    okInCapitals: 'OK',

    // Photos
    noImagesYet: 'You don\'t have any images yet.',
    clickButtonToAddPhoto: 'Click the "Add Photo" button below to add photos.',
    addPhoto: 'Add Photo',
    chooseAFile: 'Choose a file',
    orDragItHere: 'or drag it here',
    acceptedFormats: 'Accepted formats .jpeg and .png only and image size should be lesser than {} mb',
    accessWebcam: 'Access Webcam',
    uploadStatus: 'Upload Status',
    uploadPending: 'upload pending',
    successful: 'successful',
    unsuccessful: 'unsuccessful',
    addMorePhotos: 'Add More Photos',
    yesDiscardChangesCaps: 'YES, DISCARD CHANGES',
    noKeepChangesCaps: 'NO, KEEP CHANGES',
    photosNotSavedYet: 'Photos have not been saved yet',
    unableToAccessWebcam: 'Unable to access webcam, please allow "{}" to access your camera',
    photoUpload: 'Photo Upload',
    orCaps: 'OR',
    upload: 'Upload',
    exitPhotoUpload: 'Are you sure you want to exit photo upload?',
    yesExit: 'YES, EXIT',
    noContinueUpload: 'NO, CONTINUE UPLOAD',
    capture: 'Capture',
    reCapture: 'Recapture',
    markAsPrimary: 'Mark as Primary',
    deletePhoto: 'Delete Photo',

    // Global Placeholder Examples
    exampleName: 'e.g.: John Doe',
    exampleFirstName: 'e.g.: John',
    exampleLastName: 'e.g.: Doe',
    exampleBusinessName: 'e.g.: Syrasoft LLC',
    exampleRelation: 'e.g.: Relation',
    exampleAccessNumber: 'e.g.: 0000',
    exampleUnitNumber: 'e.g.: A001, A002',
    exampleEmail: 'e.g.: abc@xyz.com',
    examplePhone: 'e.g.: +1-132-879654',
    exampleCreditCardNumber: '0000   -   0000   -   0000   -   0000',

    // Sections
    tenantAddress: 'Tenant Address',
    militaryInformation: 'Military Information',
    idProofTab: 'ID\'s',
    tax: 'Tax',
    photos: 'Photos',
    otherAddress: 'Other Address',
    alternateContact: 'Alternate Contact',
    seasonalContact: 'Seasonal Address',
    employerContact: 'Employer Details',
    ledgers: 'Ledgers',
    paymentInfo: 'Payment Information',
    additionalAuthorizedPeople: 'Authorized People',

    // Lead related
    lead: 'Lead',
    leadName: 'Lead Name',
    leadInfo: 'LEAD INFO',
    leadListName: 'NAME',
    leadListContactDetails: 'CONTACT  DETAILS',
    leadListInterestedUnitTypes: 'INTERESTED UNIT\nTYPES',
    leadListLastContactedDate: 'LAST CONTACTED\nDATE',
    leadListAppointmentDate: 'APPOINTMENT DATE',
    leadListExpectedMoveInDate: 'EXPECTED MOVE IN\nDATE',
    leadListUserHandlingTheLead: 'USER HANDLING\nTHE LEAD',
    leadListEmpty: 'No Leads Found',
    leadActiveStatusChipLabel: 'Lead',
    leadFormError: 'Lead Form Error',
    leadFormLoading: 'Lead Form Loading...',
    newLead: 'New Lead',
    leadUpdated: 'Lead Updated Successfully',
    leadCreated: 'Lead Created Successfully',
    leadStatus: 'Lead Status',
    leadActivateConfirmation: 'Are you sure you want to activate lead?',
    leadDeactivateConfirmation: 'Are you sure you want to deactivate lead?',
    initialContact: 'Initial Contact',
    selectDate: 'Select Date',
    selectTime: 'Select Time',
    appointmentDate: 'Appointment Date',
    interestedUnitType: 'Interested Unit Type',

    // Estimate table in lead form
    estimateCodeCreatedOn: 'Estimate Code/\nCreated On',
    validTill: 'Valid Till',
    movedIn: 'Moved In',
    estimateCreatedFor: 'Estimate Created for',
    estimateCode: 'ESTIMATE CODE',
    reservationCreatedFor: 'Reservation Created for',
    reservationCode: 'RESERVATION CODE',
    clickOnLink: 'Click on link to view the',


    vendorUpdated: 'Vendor Updated Successfully',
    vendorCreated: 'Vendor Created Successfully',

    inventoryUpdated: 'Inventory Updated Successfully',
    inventoryCreated: 'Inventory Created Successfully',

    leaseSignStatus: 'Lease Sign Status',
    LeaseExpired: 'Lease sign link has expired',
    leaseSignComplete: 'Note: The Lease sign status is "complete" indicates that the tenant has finished signing the required documents.',
    leaseSignPending: 'Note: The Lease sign status is pending as we are awaiting the tenant\'s signature via Esign.',
    leaseSignStatusUpdatedSuccess: 'Lease sign status has been updated successfully.',
    leaseSignExpired: 'Note: Please generate and sign a new lease ( from Generate New Lease) to complete the process as the Lease sign link has expired.',
    eSignPending: 'E-sign Pending',
    leadDisplayName: 'LEAD LIST',

    // NOTE: Below strings are temporary for financial List
    financialListLabel: 'Financial',

    manualCharge: 'Create Manual Charges',
    charge: 'Charge $ ',

    arListID: 'ID',
    arTransactionType: 'Transaction Type',
    description: 'Description',
    amount: 'Amount',
    arAmountRemaining: 'Amount Remaining',
    arStatus: 'Status',
    arReversalID: 'Reversal',
    arTaxID: 'Tax',
    arPaymentID: 'Payment',
    arListActions: 'Action',
    category: 'Category',

    cashID: 'ID',
    cashFromAR: 'From ar_id',
    cashToAR: 'To ar_id',
    cashStatus: 'Status',
    cashAmount: 'Amount',
    cashCategory: 'Category',
    cashReversal: 'Reversal',

    chargeAddedSuccessfully: 'Charge Added Successfully',
    makeAnotherCharge: 'Make Another Charge',
    // NOTE: Above strings are temporary for financial List

    // User related
    listName: 'NAME',
    userListEmail: 'EMAIL',
    userListFirstName: 'FIRST NAME',
    userListLastName: 'LAST NAME',
    userListHasTwoFactor: 'HAS TWO FACTOR?',
    userListPhoneNumber: 'PHONE NUMBER',
    userListUserType: 'USER TYPE',
    userListAccountNumber: 'ACCOUNT NUMBER',
    userListDefaultFacilityId: 'DEFAULT FACILITY ID',
    userDisplayName: 'USER LIST',
    userListEmpty: 'No Users Found',
    userListLoading: 'Users Loading...',
    unlockUser: 'Unlock User',
    failedToUnlockUser: 'Failed to unlock user',
    failedToForcePasswordReset: 'Failed to force password reset',
    anEmailWillBeSentTo: 'An email will be sent to',
    forUnlock: 'for Unlock',
    resetPassword: 'Reset Password',
    forcePasswordReset: 'Force Password Reset',
    forcePasswordResetConfirmation: 'Send email to force the user to reset the password?',
    mailSentToUserSuccessfully: 'Mail sent to user successfully!',
    userActivateConfirmation: 'Are you sure you want to activate this user?',
    userDeactivateConfirmation: 'Are you sure you want to deactivate this user?',
    accountNameOrNumber: 'Account Name/Number',
    userEmail: 'User Email',
    userName: 'User Name',
    searchUserEmail: 'Search User Email',
    searchUserName: 'Search User Name',

    scheduleDisplayName: 'SCHEDULED TASKS',
    scheduleListEmpty: 'No schedules Found',
    scheduleListLoading: 'Schedules Loading...',
    scheduleName: 'Task Name',
    scheduleStartDate: 'Start Date',
    scheduleEndDate: 'End Date',
    scheduleLastExecutionDate: 'Last Execution Date',
    scheduleRunCount: 'Total Execution Count',
    scheduleDescription: 'Description',
    scheduleEnable: 'Enabled',
    scheduleFacilityNumber: 'Facility Number',
    scheduleFacilityName: 'Facility Name',
    scheduledDate: 'Scheduled Date',

    facilityListNumber: 'FACILITY NUMBER',
    facilityListAbbreviation: 'ABBREVIATION',
    facilityListAddress: 'ADDRESS',
    facilityListTimezone: 'TIMEZONE',
    facilityListTenantDefaultAddress: 'TENANT DEFAULT ADDRESS',
    facilityListFaxNumber: 'FAX NUMBER',
    facilityListNumbers: 'PHONE NUMBERS',
    facilityLicenseEndDate: 'LICENSE END DATE',
    facilityCreated: 'Facility Created Successfully',
    facilityUpdated: 'Facility Updated Successfully',

    accountListNumber: 'ACCOUNT NUMBER',
    accountListName: 'NAME',
    accountListAddress: 'ADDRESS',
    accountListContact: 'CONTACT',
    accountCreated: 'Account Created Successfully',
    accountUpdated: 'Account Updated Successfully',

    rentAssessmentHistory: 'RA History',
    dailySummary: 'Daily Summary',
    ledgerId: 'Ledger Id',
    unitId: 'Unit Id',
    unitTypeId: 'Unit Type Id',
    blockedAutoPayWindow: 'Blocked window',
    balanceAtThatTime: 'Balance at that time',
    for: 'for',
    on: 'on',
    rentAssessmentHistoryListDisplayName: 'Rent-Assessment History List',
    rentAssessmentHistoryListEmpty: 'No Rent-Assessments Processed',
    rentAssessmentHistoryListLoading: 'Rent-Assessment History Loading...',
    rentAssessmentHistoryListFacilityNumber: 'Facility Number',
    rentAssessmentHistoryListFacilityName: 'Facility Name',
    rentAssessmentHistoryListMode: 'Mode',
    rentAssessmentHistoryListType: 'Type',
    rentAssessmentHistoryListCreatedDate: 'Date Started',
    rentAssessmentHistoryListActionDate: 'Assessment Date',
    rentAssessmentHistoryListUser: 'User',
    rentAssessmentHistoryListStatus: 'Status',
    rentAssessmentHistoryListDuration: 'Time Taken',
    rentAssessmentHistoryListSummary: 'Summary',
    rentAssessmentHistoryListActions: 'Actions',
    invoiceDate: 'Invoice Date',
    autoInvoicing: 'Auto Invoicing',

    asyncTaskListDisplayName: 'Async Tasks List',
    asyncTaskListEmpty: 'No Async-Tasks Executed',
    asyncTaskListLoading: 'Async-Tasks Loading...',
    asyncTaskList: 'Async-Task History',
    asyncTaskListTaskId: 'Task Id',
    asyncTaskListStatus: 'Status',
    asyncTaskListResult: 'Result',
    asyncTaskListDateDone: 'Completion Date',
    asyncTaskListTaskName: 'Task Name',
    asyncTaskListWorker: 'Worker Host',
    asyncTaskListDateCreated: 'Date Started',

    actions: 'ACTIONS',
    invalidValue: 'Please enter a valid value',
    isAlphanumeric: 'Please Enter Letters and Numbers Only',
    isEmail: 'Please Enter A Valid Email',
    invalidEmailAddress: '{} is not a valid email address',
    duplicateEntry: '{} is a duplicate entry',
    isMaxLength: 'Text entered exceeds max length of ',
    isNumeric: 'Please Enter Numbers Only',
    minLength: 'Please Enter A Min Length Of:',
    isValidPasswords: 'Please Enter A Valid Password',
    isValidAccessCode: 'Please Enter A Valid Access Code',
    isRequired: 'This field cannot be blank',
    isValidFacilityAbbreviation: 'Please Enter A Valid Facility Abbreviation(3 Letters)',
    isValidAmount: 'Please Enter Positive Amount',
    invalidDate: 'Invalid Date Format',
    twoDecimalPlaces: 'Please Enter the value till two decimal places',
    enterInteger: 'Please enter integer value',
    isPositiveNumeric: 'Please enter a positive value',
    isValidAngle: 'Please enter a value below 360',
    noCommonPassword: 'Please do not use a common Password',
    noContextualString: 'Please do not use organization name or user name',
    invalidPastDate: 'Past Date not allowed',
    lessThanMoveInDate: 'Date cannot be less than Move In date',
    greaterThanExpectedMoveOutDate: 'Date cannot be more than Expected Move Out date',

    createTenant: 'Create Tenant',
    createLedger: 'Create Ledger',
    createLead: 'Create Lead',

    createVendor: 'Create Vendor',
    createInventory: 'Create Inventory',

    editFacility: 'Edit Facility',
    facilityManagementSubtitle: 'User can change facility general information apart from facility ID for the facilities they have access to',


    vendorActivateConfirmation: 'Are you sure you want to activate vendor?',
    vendorDeactivateConfirmation: 'Are you sure you want to deactivate vendor?',

    inventoryActivateConfirmation: 'Are you sure you want to activate inventory?',
    inventoryDeactivateConfirmation: 'Are you sure you want to deactivate inventory?',

    sectionScrollerTitle: 'SECTIONS',
    collapseAll: 'Collapse All',
    expandAll: 'Expand All',
    autoSuggestMinChars: 'Enter at least 3 characters',

    basicInformation: 'Basic Information',
    contact: 'Contact',
    otherInfo: 'Other Information',

    noUnitRented: 'No Unit Rented',

    oops: 'Oops!',
    serverError: 'Server Error',
    serverErrorTitle: 'Sorry, It’s not you. It’s us',
    serverErrorDetail: 'We are experiencing an internal server problem. Please try again later',
    underMaintenanceTitle: 'Under Maintenance',
    underMaintenanceDetail: 'We will be here shortly',
    notFound: 'Page Not Found',
    notFoundTitle: 'Sorry',
    notFoundDetail: 'Page Not Found !',
    unauthorised: 'Unauthorised',
    noAccess: 'You don’t have access to this area of application.',
    noOptions: 'No Options Found',
    searchTenantOrLead: 'Search Tenant or Lead',
    goToHomePage: 'Go to home page',
    reportIssue: 'Report Issue',


    validating: 'Validating',
    movingIn: 'Moving in',
    movingOut: 'Moving out',
    // Filters Related
    noFiltersApplied: 'No Filters Applied',
    filtersApplied: 'Filters Applied',
    // Payment Related
    savePreferredPayment: 'Save as preferred payment',
    paymentSuccessful: 'Payment Successful',
    selectTenantLedger: 'Select Tenant Ledger',
    makeAnotherPayment: 'Make Another Payment',
    payment: 'Payment',
    pay: 'Pay',
    makePayment: 'Payment',
    makeRefund: 'Refund',
    doNotProcessCard: 'Don\'t process Card',
    makingPayment: 'Making Payment',
    disableAutoPay: 'Disable Auto Pay',
    enableAutoPay: 'Enable Auto Pay',

    rentalInformation: 'RENTAL INFORMATION',
    rentalInformationLabel: 'Rental Information',

    tenantAndUnit: 'TENANT AND UNIT',

    noNotificationPenaltyAmount: 'No Notification Penalty Amount',
    prorateMoveOut: 'Prorate Move Out',
    tenantAndUnitSummary: 'Tenant And Unit Summary',
    recentUnpaidARCharges: 'Recent Unpaid AR Charges',
    notificationPenaltyAmount: 'Notification Penalty Amount',
    rate: 'Rate',
    daysLate: 'No. of Days Late',
    documentation: 'Documentation',
    documentationLabel: 'DOCUMENTATION',

    shouldProcessLateFee: 'Late fee of :fee is pending. Do you want to process this now?',
    process: 'Process',
    skipNow: 'Skip Now',
    reProcess: 'Reprocess',
    reProcessing: 'Reprocessing',
    prepayNextPeriodAmount: 'Prepay next period amount?',

    // User Form
    passwordUpdate: 'Password Update',
    createUser: 'Create User',
    editUser: 'Edit User',
    copyRoleFrom: 'Copy Role From',
    roleSelection: 'Role Selection',
    roleSelectionName: 'NAME',
    roleSelectionCity: 'CITY',
    roleSelectionID: 'ID',
    roleSelectionAbbreviation: 'ABBR',
    roleSelectionAppliedRoles: 'APPLIED ROLES',
    rolePreview: 'Role Preview',
    account: 'ACCOUNT',
    noComments: 'No Comments',
    noUnits: 'No Units',
    total: 'Total',
    paymentComment: 'Payment Comment',
    configuration: 'CONFIGURATION',
    chargeCategoryEmpty: 'No Charge Categories Found',
    paymentVsRefund: 'PAYMENT/REFUND',
    cardProcessorEmpty: 'No Card Processors Found',
    processor: 'Processor',
    taxExemptListEmpty: 'No Tax Exempt List Found',
    taxCodeEmpty: 'No Tax codes found',
    paymentMethodListEmpty: 'No Payment Method Found',
    noUnitTypeFound: 'No Unit Types Found',
    type: 'Type',
    secure: 'Secure',
    status: 'Status',
    taxes: 'Taxes',
    general: 'General',
    chargeCategory: 'Charge Category',
    facility: 'Facility',
    unitType: 'Unit Type',
    paymentMethod: 'Payment Method',
    paymentLabel: 'Payment',
    taxCode: 'Tax Code',
    taxExemptList: 'Tax Exempt',
    exemptType: 'Exempt Type',
    customExemptList: 'Custom Exempt List',
    modifier: 'Modifier',
    discountRate: 'Discount Rate',
    surcharge: 'Surcharge',
    dimension: 'Dimensions',
    dimensionLabel: 'DIMENSION',
    amountByPeriod: 'Amount By Period',
    width: 'Width',
    depth: 'Depth',
    setupFee: 'Setup Fee',
    rentalDeposit: 'Rental Deposit',
    monthly: 'Monthly',
    unitGeneral: 'Unit General',
    rentalGeneral: 'Configure General',
    rental: 'Rental',
    tenantDeposit: 'Tenant Deposit',
    showOnlyOneUnavailableStatus: 'Show only one unavailable status',
    allowNonRentChargeCategoryType: 'Allow non rent charge category type',
    skipDuplicateTenantCheck: 'Skip Duplicate Tenant Check',
    configurationCreated: 'Configuration created',
    configurationUpdated: 'Configuration updated',
    customer: 'Customer',
    customerGeneral: 'Customer General',
    facilityGeneral: 'General Settings',
    financialGeneral: 'Financial general',
    businessType: 'Business Type',
    currency: 'Currency',
    identifier: 'Identifier',
    userEmailConflict: 'User Email Conflict!',
    emailConflictMessage: 'A user with this email address is already associated with another account. If you wish to include this user in the current account, kindly get in touch with our support team for further assistance.',
    customerSupportNumber: 'Customer Support Number',
    customerSupportMail: 'Customer Support Email',
    callSupport: 'Call Support',
    emailSupport: 'Email Support',

    // Promo Plan
    promoPlan: 'Promo Plan',
    promoPlans: 'Promo Plans',
    billUpto: 'Bill upto',
    recurringPlan: 'This is a recurring plan',
    oneTimePlan: 'This is a one-time plan',
    prepayRequired: 'This plan requires prepayment',
    noOf: 'No. of',
    periods: 'Periods',
    copyPlans: 'Copy Plans From',
    promoPlanPeriod: 'Promo Plan Period',
    noPromoPlanFound: 'No Promo Plans Found',
    noPromoPlanTemplateFound: 'No Promo Plan Templates Found',
    promoPlanTitle: 'PLAN NAME',
    promoPlanActions: 'ACTION ITEMS',
    promoPlanValidity: 'Promo Plan Validity',
    promoPlanOccupancy: 'If Occupancy goes below',
    enterPercentage: 'Percentage value',
    promoPlanRecurringLabel: 'Is this a one time promo plan?',
    promoPlanPrepaymentLabel: 'Do you require pre-payment for the plan?',
    promoPlanAddBill: 'Add Additional Charge/Discount',
    promoPlanBillType: 'Charge/Discount',
    promoPlanCalculationStyle: 'Calculation Style',
    promoPlanFlatRate: 'Flat Rate',
    promoPlanPercentageRate: 'Percentage Rate',
    promoPlanStartPeriod: 'Start Period',
    promoPlanEndPeriod: 'End Period',
    prorateAsFirst: 'Pro-Rate period as 1st Period',
    untilMoveOut: 'Until Move-Out',
    promoPlanSeasonal: 'Seasonal Between',
    promoPlanPeriodic: 'Periodic',
    promoPlanFeatureAccessibility: 'Feature Accessibility',
    promoPlanDeviceAccessibility: 'Device Accessibility',
    selectBillType: 'Select Bill Type',
    selectCalculationStyle: 'Select Calculation Style',
    promoPlanDetails: 'Promo Plans',
    selectPromoPlans: 'Select Promo Plan',
    selectArea: 'Select Area',
    selectDevice: 'Select Device',
    removePromoPlans: 'This action will remove all the applied Promo Plans!',
    addNewPromoPlan: 'Add New Promo Plan',
    addPromoPlan: 'Add Promo Plan',
    chargeOrDiscount: 'Charge/Discount',
    promoPlanStartDate: 'Start Date',
    promoPlanEndDate: 'End Date',
    markedForDelete: 'This plan is marked for Deletion',
    promoPlansUpdated: 'Promo Plans are updated for this unit',
    chargeDetails: 'Charge Details',
    planVisibility: 'Plan Visibility',
    showSummary: 'Show Summary',
    promoPlanTemplate: 'Promo Plan Templates',

    dashboard: 'Dashboard',
    internalDashboard: 'Internal Dashboard',
    externalDashboard: 'External Dashboard',
    backToDashboard: 'Back to dashboard',
    settings: 'Settings',
    businessGroup: 'Business Group',

    selectBusinessType: 'Select Business Type',
    selectCurrency: 'Select Currency',
    selectIdentifier: 'Select Identifier',
    allocationOrder: 'Allocation Order',
    modifyAllocationOrder: 'Users can modify allocation order',
    selectAllocationOrder: 'Select Allocation Order',
    selectChargeCategory: 'Select Charge Category',
    chargeCategoryInfo: 'This charge won\'t take effect if the charge category is not a part of the unit bill',
    selectTaxType: 'Select the type of tax',
    selectCategory: 'Select Category',
    selectDays: 'Select Days',
    selectPeriod: 'Select Period',
    selectPeriods: 'Select Periods',
    selectStatus: 'Select Status',
    selectBillingType: 'Select billing type',
    selectLeaseStyle: 'Select Lease Style',
    selectActionType: 'Select Action Type',
    selectLease: 'Select Lease',
    selectCalcType: 'Select Calculation Type',
    selectDepositType: 'Select Deposit Type',
    selectCancellationType: 'Select Cancellation Type',
    select: 'Select',
    selectSource: 'Select Source',
    enterDays: 'Enter Days',
    enterDescription: 'Enter Description',
    enterNumber: 'Enter a number',
    enterDepth: 'Enter Depth',
    enterWidth: 'Enter Width',
    enterUnitRate: 'Enter Unit Rate',
    selectExemptType: 'Select Exempt Type',
    selectTaxCodes: 'Select Tax Codes',
    selectChargeCategoryType: 'Select Charge Category Type',
    selectAccountType: 'Select Account type',
    selectAccount: 'Select Account',
    selectAccounts: 'Select Account(s)',
    selectPaymentMethodType: 'Select Payment Method Type',
    selectPaymentMethod: 'Select Payment Method',
    selectModifierType: 'Select Modifier Type',
    selectChargeStyle: 'Select Charge Style',
    selectDeliveryMethod: 'Select Delivery Method',
    validateChargesOver: 'Validate Charges Over',
    depositLog: 'Deposit Log',
    charges: 'Charges',
    letter: 'LETTER',
    letterLabel: 'Letter',
    nsf: 'Non Sufficient Funds',
    period: 'PERIOD',
    periodLabel: 'Period',
    feeAndDeposit: 'FEE AND DEPOSIT',
    rateCalculation: 'RATE CALCULATION',
    includeCharges: 'INCLUDE CHARGES',
    resolveCharges: 'RESOLVE CHARGES',
    balanceResolution: 'BALANCE RESOLUTION',
    deposit: 'DEPOSIT',
    modifierCalculation: 'MODIFIER CALCULATION',
    prepayment: 'Pre-Payment Options',
    pendingTaskNotificationDismissal: 'PENDING TASK NOTIFICATION DISMISSAL',
    receiveEmailNotifications: 'RECEIVE EMAIL NOTIFICATIONS',
    promptForAddressChange: 'PROMPT FOR ADDRESS CHANGE',
    contactLogCategory: 'CONTACT LOG CATEGORY',
    language: 'LANGUAGE',
    demographics: 'DEMOGRAPHICS',
    generateLetter: 'Generate Letter',
    assessment: 'Assessment',
    prorate: 'PRORATE',
    cancellation: 'Cancellation',
    rentSlider: 'Rent Slider',
    nsfAmount: 'Amount to be assessed',
    enterAmount: 'Enter Amount',
    enterAccountNumber: 'Enter Account Number',
    enterDeposit: 'Enter Deposit',
    enterSetupFee: 'Enter Setup Fee',
    enterEmailId: 'Enter e-mail Id',
    accountNumber: 'Account Number',
    chooseLetter: 'Select Letter Template',
    calculateFlatFirst: 'Calculate Flat First For Multiple Recurring',
    daysForProrateCalc: 'Days For Prorate Calc',
    isAnniversary: 'Is Anniversary',
    standard: 'Standard',
    availableAtSim: 'Available at sim',
    availableAtKiosk: 'Available at kiosk',
    numDaysEarly: 'Num Days Early',
    assessAutomatically: 'Assess Automatically',
    overbookBy: 'Overbook by',
    leaseStyle: 'Lease Style',
    unitRate: 'Unit Rate',
    rentSliderEnabled: 'Rent slider enabled',
    rentSliderDiscount: 'Rent slider discount',
    premiumRate: 'Premium Rate',
    rentSliderCalcStyle: 'Rate Calculation Style',
    thresholdCalcStyle: 'Threshold Calc Style',
    highThreshold: 'High Threshold',
    lowThreshold: 'Low Threshold',
    moveIn: 'Move In',
    editReservation: 'Edit Reservation',
    reservationUpdated: 'You have successfully updated the reservation!',
    reservation_mode: 'reservation',
    moveOut: 'Move Out',
    finish: 'Finish',
    enableSyncWithExistingUnits: 'Enable sync with existing units',
    isEnabled: 'Is Enabled',
    billingType: 'Billing Type',
    days: 'Days',
    previewLeaseAgreement: 'Preview Lease Agreement',
    generateWelcomeLetter: 'Generate Welcome Letter',
    welcomeLetter: 'Welcome Letter',
    vacateNotificationInDays: 'Vacate Notification In Days',
    actionWhileResolvingCharges: 'Action To Take While Resolving Charges',
    numberOfDaysElapsed: 'Number Of Days Elapsed',
    actionOnBalanceDue: 'Action To Take On Due Balance',
    actionOnCreditDue: 'Action To Take On Due Credit',
    generateMoveOutDocument: 'Generate Move Out Document',
    numberOfCopies: 'Number Of Copies',
    printCopies: 'Print Copies',
    contactHistory: 'Contact History',
    ledgerHistory: 'Ledger History',
    generateThankYouLetter: 'Generate Thank You Letter',
    reservation: 'Reservation',
    reservedBy: 'Reserved By',
    maxDaysBeforeMoveIn: 'Max Days Before Move In',
    promptForReviewAfter: 'Prompt For Review After',
    lease: 'Lease',
    calculation: 'Calculation',
    calculationType: 'Calculation Type',
    value: 'Value',
    depositType: 'Deposit Type',
    cancellationType: 'Cancellation Type',
    printDocument: 'Print Document',
    chargeCategoryType: 'Charge Category Type',
    generalLedgerAccountNumber: 'General Ledger account Number',
    financialExportAccountType: 'Financial Export Account Type',
    isSecureCategory: 'Is Secure Category',
    matchTaxSignatureOfUnit: 'Match Tax Signature Of Unit',
    paymentMethodType: 'Payment Method Type',
    alternateExportDescription: 'Alternate Export Description',
    showPaymentNumber: 'Show Payment Number',
    paymentNumberMandatory: 'Mandate Payment Number',
    modifierType: 'Modifier Type',
    chargeStyle: 'Charge Style',
    flatAmount: 'Flat Amount',
    percentAmount: 'Percent Amount',
    requireCredentials: 'Require Password for accessing Payments',
    requireComment: 'Require Comment',
    refuseCheckFromFlaggedTenants: 'Refuse Check From Flagged Tenants',
    advancedPaidToDate: 'Advanced Paid To Date according to prepayment amount',
    forceRentAssessment: 'Force Rent Assessment',
    allowPartialCredit: 'Allowed paying partially for the Unit',
    generateReceipt: 'Generate Receipt',
    numCopies: 'Number Of Copies',
    deliveryMethod: 'Delivery Method',
    generatePartialPaymentLetter: 'Partial Payment Letter',
    hstNumber: 'HST',
    gstNumber: 'GST',
    pstNumber: 'PST',
    chargeCategoryMapping: 'Charge Category Mapping',
    notificationPenaltyFee: 'Notification Penalty Fee',
    prepay: 'Prepay',
    tenantRefund: 'Tenant Refund',
    tenantChangeTracking: 'Tenant Change Tracking',
    tenantAccountType: 'Tenant Account Type',
    tenantSections: 'Tenant Sections',
    tenantEngagement: 'Tenant Engagement',
    tenantChanges: 'Tenant Changes',
    payments: 'Payments',
    generateAddressChangeLetter: 'Generate Address Change Letter',
    defaultAccountType: 'Default Account Type',
    manual: 'Manual',
    source: 'Source',
    showAt: 'Show At',
    addAmountByPeriod: 'Add Amount By Period',
    addRentSlider: 'Add Rent Slider',
    rentSliderCalculation: 'Rent Slider Calculation',
    required: 'Required',
    preferred: 'Preferred',
    optional: 'Optional',
    showOptionalFields: 'Show Optional Fields',
    accountCamel: 'Account',
    name: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    primary: 'Primary',
    comments: 'Comments',
    defaultFacility: 'Default Facility',
    scrollToSections: 'Scroll To Sections',
    enableTwoFactorAuthentication: 'Enable  Two Factor  Authentication',
    userFormUpdated: 'User Form Updated Successfully.',
    userFormCreated: 'User Form Created Successfully.',
    searchReservationOrEstimate: 'Search Reservation / Estimate Code',
    searchEstimate: 'Search Estimate Code',
    searchReservation: 'Search Reservation Code',
    estimate: 'Estimate',
    estimates: 'Estimates',
    createEstimate: 'Create Estimate',
    estimateDetails: 'Estimate Details',
    estimateValidity: 'Estimate Validity',
    estimateExpiresAfter: 'Estimate expire after',
    estimateCreatedOn: 'Estimate Created On:',
    expiryDate: 'Expiry Date',
    create: 'Create',
    unitAndMoveInDetails: 'Unit and Move in Details',
    selectUnitType: 'Select Unit Type',
    unit: 'Unit',
    selectUnit: 'Select Unit',
    moveInDate: 'Move in Date',
    selectMoveInDate: 'Select Move in Date',
    rentalInfo: 'Rental Info',
    billToDate: 'Bill to Date',
    setRate: 'Set Rate',
    resetBackToOriginalRate: 'Reset Back to Original Rate',
    selectBillToDate: 'Select Bill to Date',
    prorateAmount: 'Prorate Amount',
    expectedMoveOutDate: 'Expected Move out Date',
    selectMoveOutDate: 'Select Move out Date',
    cannotIncrease: 'Do not have permission to increase the amount',
    cannotDecrease: 'Do not have permission to decrease the amount',
    breakDown: 'Break Down',
    totalAmount: 'Total Amount',
    createTenantOrLead: 'Create Tenant Or Lead',
    selectTenantOrLead: 'Select Tenant Or Lead',
    selectTenantLead: 'Select Tenant/Lead',
    selectTenant: 'Select Tenant',
    ledger: 'Ledger',
    addLedger: 'Add Ledger',
    selectLedger: 'Select Ledger',
    ledgerBalance: 'Ledger Balance',
    includeInactiveTenants: 'Include Inactive Tenants',
    syncBillingDate: 'Sync Billing Date',
    greaterAmount: 'Amount is greater than its maximum value',
    lesserAmount: 'Amount is lesser than its minimum value',
    back: 'Back',
    next: 'Next',
    summary: 'Summary',
    particulars: 'Particulars',
    currentCharges: 'Current Charges',
    upcomingCharges: 'Upcoming Charges',
    date: 'Date',
    debit: 'Debit',
    credit: 'Credit',
    loading: 'Loading',
    pleaseSelectTenant: 'Please Select a Tenant/Lead',
    newAmount: 'New amount is',
    error: 'Error',
    syncWithExistingUnits: 'Sync billing date',
    is: 'is',
    true: 'on',
    false: 'off',
    willLoseData: 'Any changes made will be lost. Are you sure you want to continue?',
    warning: 'Warning',
    switchingTo: 'Switching to',
    tenantLead: 'Tenant/Lead',
    after: 'after',
    lockReservationRates: 'Lock Reservation rates',
    unitReservedOn: 'Unit Reserved On:',
    lastDayMonth: 'Last Day Of The Month',
    leadConvertedToTenantSuccessfully: 'Lead converted to Tenant successfully',

    // Create Reservation
    createReservation: 'Create Reservation',

    // Unit related
    editUnitNumber: 'Edit Unit Number',
    level: 'Level',
    selectLevel: 'Select Level',
    keypadZone: 'Keypad Zone',
    selectKeypadZone: 'Select Keypad Zone',
    overlockStatus: 'Overlock Status',
    selectOverlockStatus: 'Select Overlock Status',
    accessCode: 'Access Code',
    enterAccessCode: 'Enter Access Code',
    accessCodeType: 'Access Code Type',
    accessCodeSent: 'Access Code Sent To Your E-mail.',
    rentalStatus: 'Rental Status',
    availabilityStatus: 'Availability Status',
    available: 'Available',
    unavailable: 'Unavailable',
    inventoryReport: 'Inventory Report',
    enterComments: 'Enter Comments',
    enterRate: 'Enter Rate',
    nextRentAssessmentRate: 'Next Rent Assessment Rate',
    nextRentAssessmentDate: 'Next Rent Assessment Date',
    selectNextRateAssessmentDate: 'Select Next Rate Assessment Date',
    nextAmountPayable: 'Next Amount Payable',
    paidToDate: 'Paid To Date',
    selectPaidToDate: 'Select Paid To Date',
    expectedDepartureDate: 'Expected Departure Date',
    selectExpectedDepartureDate: 'Select Expected Departure Date',
    notificationReceived: 'Notification Received',
    selectNotificationReceived: 'Select Notification Received',
    unitCreated: 'Unit Created Successfully',
    unitUpdated: 'Unit Updated Successfully',
    unitDetails: 'Unit Details',
    dateChangeWarning: 'DATE CHANGE WARNING',
    activePromoPlansWillBeAffected: 'Any active promo plans will be affected.',
    changeInNrad: 'Change in Next Rent Assessment Date will assess a prorated charge of',
    createCharges: 'CREATE CHARGES',
    waiveCharges: 'WAIVE CHARGES',
    smallFrom: 'from',
    smallTo: 'to',
    promoPlansWillBeAffected: 'Promo plans will be affected.',
    expectedMoveOut: 'Expected Move Out',
    rateHistoryLabel: 'Rate History',
    scheduleRentAdjustment: 'Schedule Rent Adjustment',
    excludeFromRentAdjustmentWizard: 'Exclude From Rent Adjustment Wizard',
    newRate: 'New Rate',
    expectedNewRate: 'Expected New Rate',
    oldRate: 'Old Rate',
    waivedOrReversedBy: 'Waived/Reversed By',
    waiveMessage: 'Are you sure you want to waive?',
    showTenantActivity: 'Show Tenant Activity',
    rented: 'Rented',
    futureNRADChange: 'You are pushing the next rent assessment date to a future date.',
    changeTheDateAndAvoidCharges: 'Do you wish to change the date and not create charges',
    wantToAssessRent: 'do you want to assess rent ?',
    or: 'or',
    assessRent: 'ASSESS RENT',
    justChangeDate: 'JUST CHANGE DATE',
    takeAction: 'Take Action',
    pastNRADChange: 'You are moving the Next Rent Assessment Date to a past date. Please ensure the following',
    reverseOrDeleteFollowingCharges: 'Reverse / Delete following charges (if any) on Ledger Review for the period Modified NRAD to Current NRAD',
    rentCharges: 'Rent Charges',
    insuranceCharges: 'Insurance Charges',
    promoPlanCharges: 'Promo Plan Charges',
    verifyFollowingData: 'Verify following data on Unit',
    changeDate: 'CHANGE DATE',
    doNotChangeDate: 'DON\'T CHANGE DATE',

    // Login
    password: 'Password',
    newPassword: 'New Password',
    charactersLength: '8-12 characters',
    atLeastOneCapital: 'At least one capital letter',
    atLeastOneNumber: 'At least one number',
    atLeastOneSpecialCharacter: 'At least one special character',
    noCommonWords: 'No common words',
    noUsername: 'Should not be same as username',
    email: 'Email',
    oldPassword: 'Old Password',
    confirmPassword: 'Confirm Password',
    continue: 'Continue',
    enterYourMail: 'Enter Your Mail',
    signingIn: 'Signing In',
    signIn: 'Sign In',
    enterAccessCodeSent: 'Enter Access Code sent to your email',
    forgotPassword: 'Forgot Password?',
    goToAccount: 'Go to account',
    resendCode: 'Resend Code?',

    copy: 'Copy',
    createRole: 'Create Role',
    hasActiveAdminSessions: 'There is already an active session. Do you want to start a new session ?',
    yesLogin: 'Yes, Login',
    noRetainSessions: 'No, Retain existing sessions',

    passwordAdherence: 'Adhere to password policy:',
    passwordLength: 'Password length should be in between 8-20 characters.',
    passwordContents: 'Password should contain at least one,',
    contentNumber: 'Number.',
    contentCapital: 'Capital Letter.',
    contentSpecialCharacter: 'Special Character.',
    notSamePassword: 'Password shouldn\'t be same as previous 5 passwords.',
    noCommonWordPassword: 'Password shouldn\'t contain any common words.',
    noUsernameOrEmailPassword: 'Password shouldn\'t contain username or email ID.',

    // User role treeview
    access: 'Access',
    add: 'Add',
    remove: 'Remove',
    edit: 'Edit',
    delete: 'Delete',
    bgManagement: 'BG Management',
    split: 'Split',
    merge: 'merge',
    modifyPayOrder: 'User can modify pay order',
    editChargeCategories: 'Edit Charge Categories',
    modifyChargeCategory: 'Users can modify charge categories',
    secureCategory: 'Users can Modify Secure Charge Category',
    secureChargeCategory: 'Secure Charge Category',
    modifyPromoPlanRules: 'User can modify Promo Plan Rules',
    configurationCapitalise: 'Configuration',
    editConfig: 'Users can modify most configurations',
    refundsConfig: 'Users can perform refunds',
    recurringCharge: 'Users can modify recurring charge for a unit',
    UnitType: 'Users can modify unit type details including rent slider',
    tenantRequiredFields: 'Tenant Required Fields',
    tenantRequired: 'Users can modify required/preferred/optional configuration for tenant',
    leaseManagement: 'Lease Management',
    bgReservation: 'Users can change the configured charge amount for a reservation',
    standardCategories: 'Standard Categories',
    reportsGeneral: 'Users can access standard report categories except audit, income, management',
    auditReports: 'Audit Reports',
    auditReportsPermission: 'Users can access reports listed under audit reports',
    incomeReports: 'Income Reports',
    incomeReportsPermission: 'Users can access reports listed under income reports',
    userMoveIn: 'User can perform Move In',
    userPayment: 'User can perform payments',
    tenantInformation: 'Tenant Information and ledger',
    secureInformation: 'Secure Information',
    corporateReports: 'Corporate Reports',
    corporateReportsPermission: 'Users can access corporate reports',
    managementReportsTitle: 'Management Reports',
    managementReports: 'Users can view summary, units- rented by type, units- non-standard rent, units- square footage, tenants- auto credit cards ',
    customCategories: 'Custom Categories',
    addOrEdit: 'Users can add or edit custom categories',
    unitsGeneral: 'Users can modify all unit related details',
    generalDetails: 'General Details',
    unitsCurrentRate: 'Users can modify the current rate of a Unit',
    NextRentAssessmentDate: 'Users can modify Next Rent Assessment Date on a Unit',
    NextRentAssessmentDateLabel: 'Next Rent Assessment Date',
    PaidToDate: 'Users can change the Paid To Date while editing a Unit',
    PaidToDateLabel: 'Paid To date',
    unitsUnitType: 'Users can modify unit type, dimensions and BEV level of a unit',
    unitTypeDimension: 'Unit Type / Dimension',
    unitsDimension: 'Users can modify width, depth and level of the unit',
    unitsInventoryReport: 'Users can add or remove Units from Inventory Reports',
    unitsMoveInDate: 'Users can modify move in date on a unit',
    occupancy: 'Occupancy',
    tenant: 'Tenant',
    setup: 'SETUP',
    setupLabel: 'Setup',
    tenantsGeneral: 'Users can modify Tenant and Ledger details except Ledger Balance',
    tenantsAllowSecureInfo: 'Users can modify secure information like ID proof, Credit card details',
    lateExemption: 'Late Exemption',
    AllowLateExemption: 'Users can modify late exemption on a tenant',
    AllowGateExemption: 'Users can modify gate exemption on a tenant',
    gateExemption: 'Gate Exemption',
    tenantsStatus: 'Users can change whether Tenants are Active or Inactive',
    modifyActiveStatus: 'Modify Active Status',
    modifyDeposit: 'Modify Deposit',
    tenantsDeposit: 'Users can change the deposit amounts held in the Tenant Profile',
    facilityManagement: 'Facility Management',
    tenantsContactLog: 'Users can modify manual entry logged at contact log',
    composeCustomEmail: 'Users can compose custom emails/text to tenants from conversation work center',
    composeEmailText: 'Compose Email/Text',
    viewSecureInfo: 'Users can view secure information',
    unitsRentalStatus: 'Users can modify Rental Status on a unit',
    performMoveIn: 'Users can move tenants into a unit',
    rentAssessment: 'Rent Assessment',
    rentAssessmentText: 'Users can process manual rent assessment',
    editMoveInDate: 'Users can modify move in date while performing move in',
    modifyBillTodate: 'User can modify bill to date during move in',
    increaseCurrentRate: 'Increase Current Rate',
    unitRateUp: 'Users can modify the units Current rate to be Higher than the configured amount only during Move in and reservation',
    decreaseCurrentRate: 'Decrease Current Rate',
    unitRateDown: 'Users can modify the units Current rate to be lower than the configured amount only during Move in and reservation',
    waiveSetupFeeLabel: 'Waive Setup Fee',
    waiveSetupFee: 'Users can waive the Setup Fee during a move-in',
    editSetupFee: 'Users can modify the Setup Fee during a move-in',
    waiveDeposits: 'Users can waive Tenant/Rental Deposit during a move-in',
    waiveDepositLabel: 'Waive Deposit',
    editDeposits: 'Users can modify Tenant/Rental Deposit during a move-in',
    waiveProrate: 'Users can modify prorate amount during move in',
    LicenseManagement: 'License Management',
    nonStandardPeriods: 'Users can choose any available period as the Unit rent period during a move-in',
    noPromoPlan: 'Users can perform a Move-in without being required to choose a Promo Plan',
    userRole: 'User Role',
    accountManagement: 'Account Management',
    performMoveOut: 'Users can move perform move out',
    moveOutDate: 'Users can modify the effective move-out date during moving out',
    waiveNotificationPenaltyLabel: 'Waive Notification Penalty',
    waiveNotificationPenalty: 'Users can waive the notification penalty amount during move-out',
    overrideResolveChargesLabel: 'Override Resolve Charges',
    overrideResolveCharges: 'Users can override the selection on Resolve Charges',
    userManagement: 'User Management',
    rentAdjustment: 'Rent Adjustment',
    schedule: 'Users can schedule new rent adjustments',
    rentAdjustmentApply: 'Users can apply rent adjustments',
    rentAdjustmentWaive: 'Users can waive existing rent adjustments',
    rentAdjustmentLease: 'Users can modify lease on rent adjustments',
    financials: 'Financials',
    businessGroupManagement: 'Business Group Management',
    reservationSubtitle: 'User can perform reservation',
    reservationWaiveDeposit: 'Users can waive reservation deposit during a reservation',
    chargeAccessScreen: 'Access Screen',
    PaymentAccessScreen: 'Users can access payment screen',
    paymentNeedCredentials: 'Users are required to re enter credentials while taking payment',
    paymentModifyEffectiveDate: 'Users can modify date of payment while taking a payment',
    paymentNegativeAmount: 'Users can enter negative payment amount',
    paymentDelete: 'Users can delete a payment (These payments will not show up on most reports)',
    paymentDeleteLabel: 'Delete Payment',
    paymentReverse: 'Users can reverse a payment',
    paymentReverseLabel: 'Reverse Payment',
    paymentNsfEntry: 'Users can access NSF screen',
    paymentBatchProcessing: 'Batch Processing',
    paymentCreditCards: 'Users can process credit cards signed up for auto pay in batches',
    AchPpd: 'Users can process ACH in Batches',
    payOffBalance: 'Users can click pay off balances on the batch process screen',
    chargeManualCharge: 'Manual Charge',
    chargeDelete: 'Delete Charge',
    chargeReverse: 'Reverse Charge',
    chargeDeletePermission: 'Users can delete a charge on ledger review screen',
    chargeReversePermission: 'Users can reverse a charge on ledger review screen',
    chargeAccess: 'Users can create manual charges',
    chargeNegativeAmount: 'Users can create manual charges with negative amount',
    chargeSecureCategory: 'Users can create manual charges for charge categories marked as secure',
    chargeReverseSecureCategory: 'Users can reverse a charge that is marked as a secured charge category',
    chargeDeleteSecureCategory: 'Users can delete a charge that is marked as a secured charge category',
    retailSale: 'Retail Sale',
    retailSaleSell: 'Users can sell items through Point of sale',
    sellInventory: 'Sell Inventory',
    retailSaleNegativeSale: 'Users can sell negative amounts of items if "Allow negative inventory" is true in configuration',
    negativeInventory: 'Negative Inventory',
    retailSaleEditInventory: 'Users can add / modify items in the Inventory management',
    modifyInventory: 'Modify Inventory',
    retailSaleReplenishment: 'Users have the ability to replenish items into Inventory subsystem',
    retailSaleNegativeReplenishment: 'Users can enter negative replenish amounts into Inventory subsystem',
    retailSaleRestock: 'Users can Restock Items into Inventory',
    retailSaleAudit: 'Users can Audit Inventory',
    retailSaleAuditNegative: 'Users can enter negative amounts into Inventory Audit',
    utilityMeteringData: 'Users can assess charges through the Utility Metering Function',
    utilityMetering: 'Utility Metering',
    reprintReceipts: 'Reprint Receipts',
    reprintReceiptsPermission: 'Users can reprint receipts from report & documentation work centers and from tenant documentation',
    roleCreated: 'User Role Created Successfully',
    roleUpdated: 'Updated Successfully',
    roleUpdateError: 'Error In Updating Roles',
    enterOldPassword: 'Enter Old Password',
    enterNewPassword: 'Enter New Password',
    successPassword: 'User profile changed successfully',
    passwordChanged: 'Password Changed Successfully',
    reNewPassword: 'Re-Enter New Password',
    reEnterNewPassword: 'Re-Enter New Password',
    userRoleActivateConfirmation: 'Are you sure you want to activate User Role?',
    userRoleDeactivateConfirmation: 'Are you sure you want to deactivate User Role?',
    userManagementSubTitle: 'User can create/modify/assign roles to a user within the account',
    userManagementSubTitle2: 'User can create/modify user roles within the account',
    setPermissionAt: 'Set Permission at',

    system: 'System',
    disableUiAccess: 'Disable UI Access',
    systemConfigManagement: 'System Config Management',
    reportManagement: 'Report Management',
    esManagement: 'ES Management',
    indexManagement: 'Index Management',
    dataManagement: 'Data Management',
    accessLetterMigrationConsole: 'Access Letter Migration Console',
    startOfDay: 'Start Of Day',
    accessLiveData: 'Access Live Data',
    modifyRestrictions: 'Modify Restrictions',
    deleteSandbox: 'Delete Sandbox',
    systemConfigManagementLabel: 'If this is turned on users will be having access to create or modify the system configs',
    disableUiAccessLabel: 'Users with this permission turned on won’t be having permission to access the UI',

    // BEV Related
    bev: 'BEV',
    bevSetup: 'BEV SETUP',
    bevView: 'BIRDS EYE VIEW',
    bevViewTitle: 'Bird\'s Eye View',
    bevViewModeLinkText: 'Switch to View Mode',
    bevSetupModeLinkText: 'Switch to Setup Mode',
    bevToolBox: 'Tool Box',
    bevLeftAlignTooltip: 'Left Align',
    bevRightAlignTooltip: 'Right Align',
    bevTopAlignTooltip: 'Top Align',
    bevBottomAlignTooltip: 'Bottom Align',
    bevHorizontalCenterTooltip: 'Horizontal Center',
    bevVerticalCenterTooltip: 'Vertical Center',
    bevRotateTooltip: 'Rotate 90°',
    bevDeleteTooltip: 'Delete',
    bevCreateEdit: 'Create New/Edit',
    bevUnitNumberField: 'Enter Unit Number',
    bevWidthFieldLabel: 'Width',
    bevDepthFieldLabel: 'Depth',
    bevCountRadioLabel: 'Count',
    bevCountFieldPlaceholder: 'Enter Count',
    bevIncrementRadioLabel: 'Increment',
    bevDecrementRadioLabel: 'Decrement',
    bevIncrementFieldLabel: 'Count Increment',
    bevDecrementFieldLabel: 'Count Decrement',
    bevAngleFieldLabel: 'Angle',
    bevAngleFieldPlaceholder: 'Enter Angle',
    bevDirectionButtonsDescription: 'Click on arrow for direction and plus to add Units',
    bevKeyboardShortcutsCardTitle: 'Keyboard Shortcuts',
    bevKeyboardShortcutLabel1: 'Multi-Select:',
    bevKeyboardShortcutDescription1: 'Hold control and select required units',
    bevKeyboardShortcutLabel2: 'Direction:',
    bevKeyboardShortcutDescription2: 'Use arrow keys to change the directions',
    bevKeyboardShortcutLabel3: 'Delete:',
    bevKeyboardShortcutDescription3: 'Select and press delete',
    bevKeyboardShortcutLabel4: 'Scroll:',
    bevKeyboardShortcutDescription4: 'Hold space and move the screen to scroll',
    bevUnitDataSaved: 'Units data saved successfully',
    bevPlaceholderDataSaved: 'Units data saved successfully',
    bevSetupDataSaved: 'Setup saved successfully',
    bevUnitDataPartiallySaved: 'Some units could not be created/updated',
    bevSetupDataPartiallySaved: 'Some units/placeholder could not be created/updated',
    bevUnitDataSaveFailed: 'Failed to Save',
    setupBev: 'Setup Bird\'s Eye View',
    bevColorSetup: 'Color Setup',
    bevColorSetupConfigure: 'Configure Color Setup',
    bevColorSetupBoard1: 'Unit Status',
    bevColorSetupBoard1Item1: 'Vacant',
    bevColorSetupBoard1Item2: 'Rented',
    bevColorSetupBoard1Item3: 'Unavailable',
    bevColorSetupBoard1Item4: 'Reserved',
    bevColorSetupBoard1Item5: 'Gate Locked',
    bevColorSetupBoard2: 'Lock / Overlock Status',
    bevColorSetupBoard2Item1: 'Overlock Pending',
    bevColorSetupBoard2Item2: 'Overlocked',
    bevColorSetupBoard2Item3: 'Unlock Pending',
    bevColorSetupBoard3: 'Maintenance Activity',
    bevColorSetupBoard4: 'Delinquency Status',
    bevSortableBoardDescription: 'Highest priority is at the top',
    enableBEV: 'Enable BEV',
    refreshColor: 'Refresh Color',
    levelManagement: 'Level Management',
    unitsInTheLevel: 'Units in the level',
    levelDeleteTooltip: 'Move units to a different level to delete this level',
    chooseADifferentName: 'Choose a different name',
    confirm: 'Confirm',
    discardChangesButton: 'Discard Changes',
    levelsSaved: 'Levels saved successfully',
    levelsSaveFailed: 'Failed to save levels',
    unitAlreadyExists: 'Unit Already exists',
    changeUnitNumber: 'Change Unit Number',
    moveToCurrentLocation: 'Move To Current Location',
    shouldSetThisAsDefault: 'Set this as default?',
    zoomValueSaveFailed: 'Failed to set current zoom as default',
    zoomValueSaved: 'Current zoom set as default',
    bevRefreshColorErrorMsg: 'Failed to refresh colors',
    colorRefreshInitiatedMsg: 'Color refresh initiated. This may take upto a minute',
    scroll: 'Scroll',
    placeholder: 'Placeholder',
    selectPlaceholder: 'Select Placeholder',
    selectPlaceholderType: 'Select Placeholder Type',
    editPlaceholderName: 'Edit Placeholder Name',
    unitComments: 'Unit comments',
    nextBillingDate: 'Next billing date',
    tenantBalance: 'Tenant balance',

    // Walk Around config related
    walkAround: 'Walk Around',
    sortingOrderType: 'Sorting Order Type',
    defaultSortOrder: 'Default Sort Order',
    selectSortingOrderType: 'Select Sorting Order Type',
    selectDefaultSortOrder: 'Select Default Sort Order',
    reviewUnitSortOrder: 'Review Unit Sort Order',
    customSortOrder: 'Custom Unit Order',
    dragAndDropUnits: 'Drag and drop units in the preferred order',
    saveChanges: 'Save Changes',
    bulkSelection: 'Bulk Selection',
    changeStatusTo: 'Change status to',
    deselectAll: 'Deselect All',
    failedToUpdateStatus: 'Failed to update status',
    overLockStatusSuccess: 'Overlock Status updated successfully',

    // Maintenance Event Config Related
    maintenanceEvent: 'Maintenance Event',
    noMaintenanceEventsFound: 'No Maintenance Event Configurations Found',
    createOn: 'Create On',
    selectCreateOnType: 'Select Create On Type',
    markThisUnitAsUnavailable: 'Mark this unit as unavailable',
    shouldChargeTenant: 'Do you want to charge the tenant',
    selectChargeWhenType: 'Select Charge When Type',
    chargeWhenEventIs: 'Charge when event is',
    generateWorkOrder: 'Generate Work Order',
    generateInvoice: 'Generate Invoice',
    printWorkOrder: 'Print work order on status change',
    printWorkOrderInvoice: 'Print work order invoice on status change',
    chooseColorToDisplayOnBEV: 'Choose color to display on BEV',
    colorConflictErrorMessage: 'Please choose a color different from the ones chosen for \'Rental Availability Status\' in BEV Color Setup Configuration',
    selectWorkOrderNumber: 'Select Work Order Number',

    // Operations - Maintenance Events Related
    maintenanceEvents: 'Maintenance Events',
    maintenanceEventsListEmpty: 'No Maintenance Events Scheduled',
    maintenanceEventsListCreatedOnAndTenant: 'CREATED ON /\nTENANT',
    maintenanceEventsListEndDate: 'EXPECTED COMPLETION\nDATE',
    maintenanceEventsListStatus: 'STATUS',
    maintenanceEventsListComments: 'COMMENTS',
    chargeWhen: 'Charge when',
    tenantInfo: 'Tenant Info',
    noChargeLabel: 'No charge will be created as there’s no tenant associated with this unit',
    scheduleEvent: 'Schedule Event',
    scheduling: 'Scheduling',
    selectEvent: 'Select Event',
    editMaintenanceEvent: 'Edit Maintenance Event',
    editEvent: 'Edit Event',
    markAsPending: 'Mark as pending',
    completeEvent: 'Complete Event',
    completeAndCloseEvent: 'Complete & Close Event',
    completeEventAlertMessage: 'Are you sure you want to mark this event as complete ?',
    closeEvent: 'Close Event',
    closeEventAlertMessage: 'Are you sure you want to close this event ?',
    cancelEvent: 'Cancel Event',
    cancelEventAlertMessage: 'Are you sure you want to cancel this event ?',
    unitWillBeMarkedUnavailable: 'Unit will be marked as unavailable',
    maintenanceEventStartDate: 'Start Date',
    maintenanceEventEndDate: 'Expected completion date',
    eventScheduleWithCharge: 'Event is scheduled with a charge of ',
    eventSchedulingFailed: 'Event scheduling failed',
    eventClosedWithCharge: 'Event is closed with a charge of ',
    unitIsMarkedUnavailable: 'Unit is marked as unavailable !',
    failedToMarkUnitUnavailable: 'Failed to mark unit as unavailable',
    maintenanceEventCreated: 'Event Created Successfully',
    maintenanceEventUpdated: 'Event Updated Successfully',
    maintenanceEventClosePermissionDesc: 'Users can close a maintenance event',
    maintenanceEventCancelPermissionDesc: 'Users can cancel a maintenance event',


    // Vendor Related
    vendorListVendorName: 'VENDOR NAME',
    vendorListContactPerson: 'CONTACT PERSON',
    vendorListAddress: 'ADDRESS',
    vendorListEmail: 'EMAIL',
    vendorListPhone: 'PHONE',
    vendorListFax: 'FAX',
    vendorListActions: 'ACTION',
    vendorCreate: 'Add Vendor',
    vendors: 'Vendors',

    vendorDisplayName: 'Vendor Management',
    vendorListEmpty: 'No Vendors Found',

    vendorName: 'Vendor Name',
    contactName: 'Contact Person',
    phoneOrFax: 'Phone / Fax',

    allAccounts: 'All Accounts',
    accountDisplayName: 'Accounts List',
    accountListEmpty: 'No Accounts Found',
    accountListLoading: 'Accounts Loading...',

    facilityDisplayName: 'Facility List',
    facilityListEmpty: 'No Facilities Found',
    facilityListLoading: 'Facilities Loading...',

    // Tenant Section configuration
    always: 'Always',
    onDemand: 'On Demand',
    neverShow: 'Never Show',
    showAllBelowFieldsInTenant: 'Show all below Fields in Edit/Create Tenant',
    markAllFieldAs: 'Mark All fields as',
    emailAddress: 'Email Address',
    emailEnabled: 'Email Enabled',
    // Reservation Related
    reservationListCode: 'RESERVATION CODES',
    reservationListTenant: 'TENANT',
    reservationListLedger: 'LEDGER',
    reservationListUnitType: 'UNIT TYPE',
    reservationListUnit: ' UNIT',
    reservationListReservationDate: 'RESERVATION DATE',
    reservationListExpectedMoveInDate: 'EXPECTED MOVE IN DATE',
    reservationListActions: 'ACTIONS',
    reservationListEmpty: 'No Reservations Found',
    cancelReservationConfirmation: 'Are you sure you want to cancel Reservation?',
    reservationCancelMessage: 'Reservation cancelled successfully',
    reservationDetails: 'Reservation Details',
    reservationDeposit: 'Reservation Deposit',
    reservationDate: 'Reservation Date',
    reservationExpectedMoveInDate: 'Expected Move in Date',
    pendingReservation: 'Pending Reservation',
    viewReservation: 'View Reservation',

    // inventory details
    inventory: 'Inventory',
    inventoryDisplayName: 'Inventory Management',
    inventoryListEmpty: 'No Inventories Found',
    inventoryListLoading: 'Inventories Loading...',

    inventoryListProductId: 'ID',
    inventoryListProductDescription: 'DESCRIPTION',
    inventoryListVendorId: 'VENDOR',
    inventoryListRetailPrice: 'RETAIL PRICE',
    inventoryListAvailableQuantity: 'AVAILABLE\nQUANTITY',
    inventoryListReorderPoint: 'REORDER POINT',
    inventoryListActions: 'ACTIONS',

    sellAndPay: 'Sell And Pay',
    pointOfSale: 'POINT OF SALE',

    pointOfSaleLower: 'Point Of Sale',
    clearCartItems: 'Clear Cart',
    itemNotFound: 'Item not found',
    clear: 'CLEAR',
    addToInventory: 'ADD TO INVENTORY',
    addItem: 'Do you want to add this item?',
    updateProductQuestion: 'This Product Id already exists. Do you want to update the product?',
    selectTenantToMakePayment: 'Select a tenant to make payments',

    sellNegativeInventoryError: 'You don\'t have permission to sell into negative inventory',
    negativeInventoryConfigMessage: 'Negative Inventory is not configured',

    customerName: 'Customer Name',
    productsList: 'Product List',
    availableQuantityInPos: 'Available Qty',
    dateOfPurchase: 'Date Of Purchase',
    cart: 'Cart',
    clearCartAlert: 'Are you sure you want\n to Clear Cart Items?',
    selectAll: 'Select All',
    unSelectAll: 'Unselect All',
    sellAndPayLater: 'Sell and Pay later',
    inventoryAuditName: 'Inventory Audit',
    inventoryAuditAvailableQuantity: 'Available Quantity',
    inventoryAuditDescription: 'Description',
    inventoryAuditProductId: 'Product ID',
    inventoryAddRemoveQuantity: 'Add/Remove Quantity',
    inventoryUpdatedQuantity: 'Updated Quantity',
    inventoryComment: 'Comments',
    inventoryAuditSubTitle: 'User can edit available quantity in the audit',
    negativeInventorySubtitle: 'User can edit the available quantity to a negative amount',

    inventoryUpdateQuantity: 'Update Quantity',

    update: 'Update',
    inventoryUpdate: 'Inventory Updated Successfully',

    goToVendor: 'GO TO VENDOR MANAGEMENT',
    emptyInventoriesAlert: 'Please Add A Vendor first before you add a product',
    alert: 'Alert',

    inventoryRestockName: 'Restock',
    restockQuantity: 'Restock Quantity',
    editInventory: 'Edit Inventory',
    comesFromQuantity: 'Comes from Preferred Reorder Quantity',
    totalSaving: 'Total Savings',
    totalTax: 'Total Tax',
    sellAndPaid: 'Products in Cart are Sold and Paid For',
    printPurchaseOrder: 'Print Purchase Order',
    taxExemptQuestion: 'Tax Exempt?',
    sellAndPaidLater: 'Products in Cart are Sold',
    restock: 'Restock',
    nothingToRestock: 'Nothing To Restock',
    retailSaleConfigure: 'CONFIGURE RETAIL SALE',
    usePreferredPrice: 'Use preferred price by default',
    allowNegativeInventory: 'Allow negative inventory',
    mandateComments: 'Mandate comments',
    copiesPrint: 'Copies to be printed',
    exempted: 'Exempted',
    vendor: 'Vendor',
    contactPerson: 'Contact Person',

    // inventory form
    upc: 'UPC ID',
    productId: 'Product ID',
    retailPrice: 'Retail Price',
    preferredPrice: 'Preferred Price',
    wholesalePrice: 'Wholesale Cost',
    availableQuantity: 'Available Quantity',
    reorderPoint: 'Reorder Point',
    preferredReorderQuantity: 'Preferred Reorder Quantity',
    showAtKiosk: 'Show at Kiosk',
    subTotal: 'Subtotal',

    addInventory: 'Add Inventory',
    scanBarcode: 'Scan Barcode',
    scanningBarcode: 'Reading Barcode',
    fromBarcodeScanner: 'From Bar Code Reader',
    productDescription: 'Product Description',
    selectVendor: 'Select Vendor',
    sellingPrice: 'Selling price of Product',
    userPrice: 'User set price',
    wholesaleCost: 'Price at wholesale cost',
    productQuantity: 'Product quantity in facility',
    stockLow: 'Quantity when stock is low',
    reorderPlaceholder: 'User set quantity',
    enterProductId: 'Enter Product ID',


    cartProductsAdded: 'Products Added',
    cartProductQuantity: 'Quantity',
    cartPrice: 'Price',
    cartTotal: 'Amount',
    emptyCart: 'Cart is Empty. Please Add To Cart By Clicking on Products',
    remainingQuantity: 'Remaining',
    retailSaleNegativeSell: 'Users can sell into negative inventory',
    retailSaleAddInventoryPOS: 'Users can add inventories at point of sale',

    // Report Work Center
    allReports: 'All Reports',
    reports: 'Reports',
    consolidatedManagementReports: 'Consolidated Management Reports',
    consolidatedReports: 'Consolidated Reports',
    deliverReport: 'Deliver Report',
    deliveringReport: 'Delivering Report',
    selectedReports: 'Selected Reports',
    clearSelection: 'Clear Selection',
    searchCategories: 'Search Categories',
    searchReports: 'Search Reports',
    addCustomCategory: 'Add Custom Category',
    createCategory: 'Create Category',
    updateCategory: 'Update Category',
    assignPrinter: 'Assign Printer',
    reportDescription: 'Report Description',
    noDescription: 'No Description',
    previewReport: 'Preview Report',
    enterReceiptNumber: 'Enter Receipt Number',
    enterInvoiceNumber: 'Enter Invoice Number',
    receiptNotFound: 'Receipt Not Found',
    invoiceNotFound: 'Invoice Not Found',
    reportWorkCenter: 'ReportWorkCenter',
    consolidatedReportWorkCenter: 'consolidatedReportWorkCenter',
    consolidatedReportAccess: 'Can Access Consolidated Management Summary Report',

    // Report WC Status
    fetchingReport: 'Fetching Report...',
    buildingReport: 'Building Report...',
    convertingToPdf: 'Converting to PDF',
    openingFile: 'Opening File',
    thisIsAPermanentAction: 'This is a Permanent Action',
    continueConfirmation: 'Are you sure you want to continue?',
    categoryDeleted: 'Category Deleted Successfully',
    yesDeliverLink: 'YES, DELIVER LINK',
    noExitCaps: 'NO, EXIT',

    filterPresets: 'Filter Presets',
    effectiveDate: 'Effective Date',
    effectiveDateRange: 'Effective Date Range',
    fromToDate: 'From / To Date',
    numberRange: 'Number Range',
    depositLogCapitalized: 'Deposit Log',

    batchScheduling: 'Batch Scheduling',
    startDay: 'Start Day',
    endDay: 'End Day',
    startDate: 'From Date',
    endDate: 'To Date',
    processTime: 'Process Time',
    print: 'Print',
    printTable: 'Print Table',
    copies: 'Copies',

    generateBy: 'Generate By',
    emailAddressListPlaceholder: 'Email Ids entered above will show up here',

    editPermission: 'Edit Permission',
    switchToRoles: 'Switch To Roles',
    switchToUsers: 'Switch To Users',
    roles: 'Roles',
    users: 'Users',
    syrasoftUserRoles: 'Syrasoft User Roles',
    allow: 'Allow',
    restrict: 'Restrict',

    noUsersFound: 'No Users Found',
    noRolesFound: 'No Roles Found',

    requestSubmitted: 'Request Submitted Successfully',
    attemptingDelivery: 'Attempting to deliver report. It could take a few seconds.',
    categoryCreated: 'Category is Successfully Created',
    categoryUpdated: 'Category is Updated Created',
    updatingCategory: 'Updating Category',
    creatingCategory: 'Creating Category',

    // Report Filter and sorts
    sort: 'Sort',
    groupBy: 'Group By',
    filters: 'Filters',
    selectSortOrder: 'Select Sort Order',
    selectedGroupBy: 'Select Group By',

    // Letter Editor
    insertTable: 'Insert Table',
    insertRowAbove: 'Insert Row Above',
    insertRowBelow: 'Insert Row Below',
    insertColumnLeft: 'Insert Column Left',
    insertColumnRight: 'Insert Column Right',
    deleteRow: 'Delete Row',
    deleteColumn: 'Delete Column',
    deleteTable: 'Delete Table',
    tableOperations: 'Table Operations',
    rows: 'Rows',
    columns: 'Columns',
    cancelLabel: 'CANCEL',
    done: 'DONE',
    mergeFieldCategory: 'Merge Field Category',
    insertMergeFields: 'Insert Merge Fields',
    exitWithoutSaving: 'Exit Without Saving',
    bottomRight: 'Bottom - right',
    bottomCenter: 'Bottom - center',
    bottomLeft: 'Bottom - left',
    noPageNumber: 'No Page number',
    addPageNumberAt: 'Add page number at',
    pageNumber: 'Page Number',
    customMergeFields: 'Custom Merge Fields',
    mergeFieldName: 'Merge Field Name',
    egMergeFieldValue: 'eg . : Thank you for being a part of our family.',
    egMergeFieldName: 'eg . : Welcome',
    onlyTenFields: 'Only 10 merge fields can be added',
    customMergeFieldSaved: 'Custom Merge Field Created Successfully',
    customMergeFieldUpdated: 'Custom Merge Field Updated Successfully',
    subCategory: 'Sub Category',

    // Letter Configuration
    letterConfiguration: 'Letter Configuration',
    customerEngagement: 'Customer Engagement',
    viewableAt: 'VIEWABLE AT',
    noLetterFound: 'No Letters Found',
    paperSize: 'PAPER SIZE',
    descriptionLabel: 'DESCRIPTION',
    showThisLetterAt: 'Show this letter at',
    printingPaperSize: 'Printing Paper Size',
    textFriendly: 'Text Friendly',
    enableTextFriendlyVersion: 'Enable text friendly version',
    enableTextFriendly: 'Do you want to enable text friendly version for this letter template?',
    composeLetter: 'Compose Letter',
    composeTextVersionLetter: 'Compose Text Version of Letter',
    editLetter: 'Edit Letter',
    editTextVersionLetter: 'Edit Text Version of Letter',
    emptyTemplateLink: 'Template link can not be empty',

    // Operations:
    operation: 'Operation',
    operationsLabel: 'Operations',
    failures: 'Failures',
    noOperationsFound: 'No operations available',
    defaultOperationText: 'Select an operation to proceed',
    rentAssessmentStarted: 'Rent Assessment is in progress. Started at',
    rentAssessmentRan: 'Rent Assessment last ran on',
    processRent: 'Process Rent',
    searchTenant: 'Search Tenant',
    searchUnit: 'Search Unit',
    allTenants: 'All Tenants',
    singleTenant: 'Single Tenant',
    singleUnit: 'Single Unit',
    errorInRentAssessment: 'Error Processing Rent',
    successfulRentAssessment: 'Rent Assessment was successfully processed',
    inProgressRentAssessment: 'Processing Rent',
    alreadyProcessedRentAssessment: 'Rent already processed for the',
    rentAssessmentType: 'Assessment Type',
    rentAssessmentDate: 'Assessment Date',
    selectRentAssessmentDate: 'Select Assessment Date',
    manualRentAssessment: 'Manual Rent Assessment',
    licenseExpired: 'Your License Expired on',
    hasExpired: 'Has Expired',
    notificationMessage: 'Your access to this facility is temporarily restricted because your subscription for Syrasoft Facilitate has expired. To continue using our service, please renew now. If you have active facilities, you can still access them by selecting the facility from the list above. Contact Support for further assistance.',
    secondMessage: 'To continue using our service, please renew now. If you have active facilities, you can still access them by',
    thirdMessage: 'selecting the facility from the list above. Contact Support for further assistance.',
    salesSupportNumber: 'Sales Support Number :',
    techSupportNumber: 'Tech Support Number :',
    customerSupportEmail: 'Customer Support Email :',
    salesSupport: '1(800) 817-7706',
    techSupport: '1(800) 559-5942',
    supportEmail: 'support@syrasoft.com',
    signOut: 'Sign Out',

    // Configuration
    configurationActivateConfirmation: 'Are you sure you want to activate the configuration?',
    configurationDeactivateConfirmation: 'Are you sure you want to deactivate the configuration?',
    // Print Settings
    printSettings: 'Print Settings',
    selectPrinter: 'Select Printer',
    paperType: 'Paper Type',
    printOn: 'Print On',
    paperSource: 'Paper Source',
    setPrinterForReport: 'Set Printer for Report',
    setPrinter: 'Set Printer',
    selectDefaultPrinter: 'Select Default Printer',
    defaultPrinter: 'Default Printer',
    noteForDefaultPrinter: 'Note: The default printer is the printer that is automatically selected when a user logs in to the application',
    noteForSetupPrinter: 'Note: Please set up a printer in the Syrasoft cloud assist to access this feature.',
    printerWarning: 'Syrasoft cloud assist is not setup for this facility. Please download and install the \n app ',
    here: 'here',

    // View tenant
    editTenant: 'Edit Tenant',
    viewTenant: 'View Tenant',
    ledgerInfo: 'Ledger Info',
    autoPayEnabled: 'Autopay Enabled',
    autoPay: 'Autopay',
    balance: 'Balance',
    lastPaid: 'Last Paid',
    paidTill: 'Paid Till',
    paid: 'Paid',
    nextDueLedger: 'Next Due (Ledger)',
    nextDue: 'Next Due',
    unitDeposit: 'Unit Deposit',
    noUnitsRented: 'No Units Rented',
    quickPay: 'Quick Pay',
    ledgerReview: 'Ledger Review',
    crm: 'CRM',
    gateControls: 'Gate Controls',
    exemptFrom: 'Exempt From',
    gateLockout: 'Gate Lockout',
    overlock: 'Overlock',
    unlock: 'Unlock',
    otherLateEvents: 'Other Late Events',
    taxExemptLabel: 'Tax Exempt',
    websiteAccess: 'Website Access',
    noEmailConfigured: 'There’s no email or phone configured with this tenant. Please add one of the information to send the sign up link.',
    noTenantWebsiteUrl: 'There’s no website url present on the configurations of the facility.',
    exemptNumber: 'Exempt Number',
    noTaxExemptRecord: 'No Tax Exempts Record found',
    noAuthorizedPeople: 'No Additional Authorized People configured',
    additionalAuthorizedPeopleLabel: 'Additional Authorized People',
    unpaidBills: 'Unpaid Bills',
    unpaidBillsForPrimaryLedger: 'Unpaid Bills for Primary Ledger',
    unpaidBillsFor: 'Unpaid Bills for',
    amountCharged: 'Charged',
    amountUnpaid: 'Unpaid',
    noBillsGenerated: 'No Bills Generated',
    totalUnpaid: 'Total Unpaid',
    noReservation: 'No Reservations made. To create one, please click \'+\' icon',
    mode: 'Mode',
    saveConfirmationWarning: 'Your current changes will be saved first',
    actionsLower: 'Actions',
    expectedMoveInDate: 'Expected Move In Date',
    missingRequiredFields: ' Some of the required fields are empty. Please fill them before continuing',
    overlockAndUnlock: 'Overlock And Unlock Units',
    allUnits: 'All Units',
    someUnitsFailedToUpdate: 'Some Units Failed To Update',
    unitsSuccessfully: 'Unit(s) Successfully',
    saveAndContinue: 'Save & Continue',
    dontSave: 'Don\'t Save',
    movingToUnitForm: 'You have unsaved changes in the current form. You\'ll be moving to the edit unit form',
    leaseLetterNotConfigured: 'Lease letter is not configured',
    incompleteTenantDetails: 'Tenant details are incomplete',
    fillTenantDetails: 'Fill in the following fields to complete tenant information.',
    createInvoice: 'Create Invoice',
    documentsIssuedBetween: 'Document Issued Between',

    // Side Bar
    tenants: 'Tenants',
    leads: 'Leads',
    units: 'Units',
    reservations: 'Reservations',
    birdsEyeView: 'Bird\'s Eye View',
    facilities: 'Facilities',
    accounts: 'Accounts',
    schedules: 'Scheduled Tasks',
    configurations: 'Configurations',
    user: 'User',
    noUpdatedData: 'Data has not been updated',
    home: 'Home',

    // TenantAlerts
    tenantAlerts: 'Tenant Alerts',
    createTenantAlert: 'Create Tenant Alert',
    updateTenantAlert: 'Update Tenant Alert',
    noAlerts: 'No alerts configured. To configure them, please click \'+\' icon',
    severityLevel: 'Severity Level',
    fromDate: 'From Date',
    toDate: 'To Date',
    buttonLoadingAlertText: 'Creating alert',
    createAlert: 'Create alert',
    alertDetails: 'Alert details',
    alertType: 'Alert type',
    alertConfigurationPlaceholder: 'Select module',
    alertConfigurationLabel: 'Module',
    tenantAlertsSuccessSnackbarCreation: 'Tenant alert has been successfully created',
    tenantAlertsSuccessSnackbarUpdation: 'Tenant alert has been successfully updated',

    // Contact Log
    contactLog: 'Contact Log',
    createContactLog: 'Create Contact Log',
    noContactLog: 'No contact records registered. To configure them, please click \'+\' icon.',
    createdOn: 'Created On',
    by: 'by',
    addEntry: 'Add Entry',
    editEntry: 'Edit Entry',
    message: 'Message',
    discardMessage: 'DISCARD MESSAGE',
    saveMessage: 'SAVE MESSAGE',
    composeEmail: 'Compose Email',
    composeTextMessage: 'Compose Text Message',

    // Ledger Review
    review: 'Review',
    transactions: 'Transactions',
    transactionList: 'Transaction List',
    transactionNumber: 'Transaction Number',
    createdDate: 'Created Date',
    toConjunction: 'to',
    amountPaid: 'Paid',
    chargeAndComment: 'Charge/Comment',
    reverseMessage: 'Are you sure you want to reverse?',
    reversePromoPlanMessage: 'This reversal does not affect any promo plan applied. You will have to manually reverse the promo plan.',
    deleteMessage: 'Are you sure you want to delete?',
    deletePromoPlanMessage: 'This deletion does not affect any promo plan applied. You will have to manually delete the promo plan.',
    otherActions: 'Other Actions',
    showTransactionNumber: 'Show Transaction Number',
    ledgerReviewAction: 'Actions',
    merchantPortal: 'Merchant Portal',
    searchByTransactionNumber: 'Search by Transaction Number',
    noTransactionsFound: 'No Transactions Found',

    // Manual Form
    manualCharges: 'Manual Charge',
    addCharge: 'Add Charge',
    manualChargeCreated: 'Manual Charge Created Successfully',
    amountMoreThanConfiguredAlertMessagePart1: 'The amount that you’ve entered is more than expected.',
    amountMoreThanConfiguredAlertMessagePart2: 'Are you sure you’ve entered the right amount ?',
    yesCreateCharge: 'Yes, Create Charge',
    noEditAmount: 'No, Edit Amount',
    yesPay: 'Yes, Pay',


    // Move In Summary
    demographicLetter: 'Demographic Letter',
    leaseLetter: 'Lease Letter',
    invoice: 'Invoice',
    leaseAddendumLetter: 'Lease Addendum Letter',
    vehicleAddendumLetter: 'Vehicle Addendum Letter',
    addFromRS: 'Add Items ?',
    movedInto: 'moved into',
    with: 'with',
    successfully: 'successfully!!',
    totalPayableTenant: 'Total Payable by Tenant',
    sell: 'Sell',
    inclusiveMessage: '(Inclusive Of Sale)',
    autoGenerate: 'Auto Generate',

    // Move Out
    moveOutDetails: 'Move Out Details',
    searchUnitOrTenant: 'Search Tenant / Unit',
    moveOutDateLabel: 'Move Out Date',
    tenantAndUnitDetail: 'Tenant And Unit Details',
    unitLabel: 'Unit Label',
    unitDescription: 'Unit Description',
    billing: 'Billing',
    lateNotificationPenaltyAmount: ' Late Notification Penalty Amount',
    commentsForUnit: 'Comments for Unit while vacating',
    reasonForVacating: 'Reason for Vacating',
    moveOutResolveCharges: 'Resolve Charges',
    totalUnpaidAr: 'Total Unpaid AR',
    unpaidAr: 'Unpaid AR',
    expectedNoticePeriod: 'Expected Notice Period',
    noticePeriodReceivedDate: 'Notice Period Received Date',
    fullPeriods: 'Full Period(s)',
    prorateOutDays: 'Day(s)',
    setProrate: 'Set Prorate',
    noBreakUpMessage: 'Breakup won\'t be shown when changed',
    postMoveOut: 'Post Move Out',
    hasMovedOut: 'has been moved out of',
    tenantRelatedActions: 'Tenant Related Actions',
    doNotRent: 'Do Not Rent',
    postMoveOutSuccessfully: 'successfully',
    resolveRefunds: 'How do you want to resolve refunds ?',
    resolveBalance: 'How do you want to resolve balance ?',
    refund: 'Refund',
    refunds: 'Refunds',
    retryRefund: 'Retry Refund',
    cancelMoveOut: 'CANCEL MOVEOUT',
    exitPostMoveOut: 'EXIT POST MOVEOUT',
    moveOutStepZeroOnExitAlertMessage: 'Are you sure you want to cancel moveout ?',
    moveOutStepZeroOnNavigationAlertMessagePart1: 'Are you sure you want to discard all the changes ?',
    moveOutStepZeroOnNavigationAlertMessagePart2: 'This would result in loss of entered data !',
    moveOutStepTwoOnNavigationAlertMessagePart1: 'Are you sure you want to skip post moveout ?',
    moveOutStepTwoOnNavigationAlertMessagePart2: 'Following have to be done manually :',
    paymentRefund: 'Payment/ Refunds',
    deliveringDocuments: 'Delivering documents',
    changeTheTenantStatus: 'Change the tenant status',
    moveOutNextStepWithoutPaymentSnackbarMessage: 'Ledger Balance Exists ! Change balance resolution to move to next step',

    // POS RESPONSIVE
    products: 'Products',
    postMoveInPos: 'POST MOVE-IN RETAIL SALE',
    confirmChanges: 'Please confirm changes in the order',
    confirmOrder: 'Confirm Order',

    // payment
    payAheadFor: 'Pay ahead for',
    billAhead: 'Bill Ahead',
    receiptDeliverBy: 'Deliver Receipts By',
    cardProcessor: 'Card Processor',
    flaggedTenant: 'Don\'t accept check from this tenant !',
    ignore: 'IGNORE',
    changePayment: 'CHANGE PAYMENT',
    checkNumber: 'Check Number',
    checkPlaceHolder: '012345678912',
    moneyOrderNumber: 'Money Order Number',
    successPayment: 'Payment was Successful',
    successRefund: 'Refund was Successful',
    unitFetched: 'Units Fetched Successfully',
    tenantFetched: 'Tenant Fetched Successfully',
    ledgerUpdated: 'Ledger Updated Successfully',
    clickHereForMoreDetails: 'Click here for more details',
    enterCardDetails: 'Enter card details',
    useCardOnFile: 'Use card on file',
    swipeCard: 'Swipe card',
    payUsing: 'Pay using',
    signUpForAutoPay: 'Sign up for auto pay',
    signUpLink: 'Deliver Sign up Link By',
    signUpLinkDelivered: 'Tenant Sign Up Link sent Successfully',
    sendSignUpLink: 'Send Sign up Link',
    saveThisForFuturePayments: 'Save this for future payments',
    clickOn: 'Click on',
    andSwipeTheCard: 'and swipe the card',
    negativeAmount: 'Amount cannot be positive',
    negativeAmountLabel: 'Negative Amount',
    nonZeroPositiveAmount: 'Amount cannot be 0 or negative',
    enterValidCardNumber: 'Enter valid card number',
    takingMoreTime: 'It\'s taking more time than expected. Do you want to restart',
    refundInProgress: 'Refund in progress',
    paymentInProgress: 'Payment in progress',
    waitingForPaymentConfirmation: 'Waiting for payment confirmation',
    waitingForRefundConfirmation: 'Waiting for refund confirmation',
    sec: 'sec',
    cancelPayment: 'Cancel Payment',
    cancelRefund: 'Cancel Refund',
    noWait: 'No, Wait',
    paymentIsCancelled: 'Payment is cancelled',
    refunding: 'Refunding',
    paymentFailed: 'Payment Failed',
    reason: 'Reason',
    rejectionCode: 'Rejection code',
    tryDifferentMethod: 'TRY DIFFERENT METHOD',
    exitCaps: 'EXIT',
    resetAmount: 'RESET AMOUNT',
    prePayNotAllowed: 'Prepay not allowed',
    viaSyrapay: 'via Syrapay',
    partialPaymentNotAllowedForDelinquentTenant: 'Partial payment is not allowed for a delinquent tenant',

    // Delinquency Configuration
    delinquentEvent: 'Delinquent Event',
    delinquency: 'Delinquency',
    delinquent: 'Delinquent',
    eventType: 'Event Type',
    calculationStyle: 'Calculation Style',

    // Late Events
    viewDelinquencyWc: 'View Delinquency WC',
    viewDelinquencyWcSubtitle: 'User can view the delinquency WC',
    assessLateCharges: 'Assessing Late Charges',
    assessLateChargesSubtitle: 'User can assess late events and/ or print related documents',
    waiveLateCharges: 'Waive Late Charges',
    waiveLateChargesSubtitle: 'User can waive late events from manual late events screen',

    lateEventType: 'Late Event Type',
    noOfDaysLate: 'No. of Days Late',
    graceAmount: 'Grace Amount',
    trigger: 'Trigger',
    tenantPortal: 'Tenant Portal',
    overlockOutdoorUnits: 'Overlock Outdoor Units',
    colorOnBev: 'Choose color to display on BEV',
    graceAmountDescription: 'Late Event won\'t occur if balance is less than or equal to grace amount',
    tenantPortalViewDescription: 'Can sign in and view basic information and balance',
    tenantPortalView: 'View Only',
    tenantPortalRestrictLogin: 'Restrict Login',
    resetOnGraceAmount: 'Reset on Grace Amount',

    waiveForAdditionalUnits: 'Waive this event for additional units?',
    selectLetter: 'Select Letter',
    sendToAlternateContact: 'Send to Alternate Contact',
    sendCurrentChargesDueDocument: 'Attach due document in addition to late letter',
    higherOfFlatAndPercent: 'Choose higher of % amount and flat amount',
    performBilling: 'Perform Billing',
    applyUntil: 'Apply Until',
    percentage: 'Percentage',
    configureTiers: 'CONFIGURE TIERS',
    ceilingPointOfTier: 'Ceiling point of the tier',
    lateEvent: 'Late Events',
    lateFeePerUnitType: 'LATE FEE PER UNIT TYPE',
    active: 'Active',
    anErrorOccurred: 'An error occurred.',
    percentPerUnitType: '% Per Unit Type',

    // Card Processor Configuration
    enterCardProcessorName: 'Enter Name of Processor',
    selectProcessor: 'Select Processor',
    acceptorId: 'Acceptor ID',
    accountId: 'Account ID',
    enterAcceptorId: 'Enter Acceptor ID',
    enterAccountId: 'Enter Account ID',
    accountToken: 'Account Token',
    enterAccountToken: 'Enter Account Token',
    terminalId: 'Terminal ID',
    enterTerminalId: 'Enter Terminal ID',
    showOn: 'Show on',
    deviceInformation: 'Device Information',
    activationCode: 'Activation Code',
    enterActivationCode: 'Enter the code that came with your device',
    laneID: 'Lane ID',
    deleteConfirmation: 'Are you sure you want to delete it?',
    cardProcessorCreated: 'Card Processor Created Successfully!',
    willConnectDevice: 'Device connection will take some time!',
    exit: 'Exit',
    waitingForDeviceToReboot: 'Waiting for the device to Reboot',
    saveFirst: 'Save current changes before adding new one',
    generateSecureToken: 'Generate Secure Token',
    enablingSecureTokenOption: 'Enabling this option provides utmost security during payments. Additional charges may apply',
    merchant: 'Merchant',
    enterMerchantId: 'Enter Merchant ID',
    contactTechSupportForMerchantId: 'Please contact tech support if you don’t have merchant ID',
    openMerchantPortal: 'Open merchant portal',
    noMerchantPortalPermission: 'User does not have permissions to open merchant portal',
    addDevice: 'Add Device',
    deviceId: 'Device ID',
    deviceName: 'Device Name',
    enterDeviceId: 'Enter Device ID',
    selectDeviceName: 'Select Device Name',

    // Financial General Configuration
    checkAmountPrompt: 'Prompt users to check amount',
    amountGreaterThan: 'Amount greater than',
    prepayCategory: 'Prepay Category',
    prepayMessage: 'The unallocated payment or negative charge is pushed to prepay charge category.\n This can be refunded.',
    assessNsfCharges: 'Do you want to assess charges for nsf?',
    generateLetterNsf: 'Do you want to generate letter for nsf?',
    configureGeneral: 'Configure General',
    quickbooks: 'Quickbooks',
    configureQuickbooks: 'Configure QuickBooks',

    // Tax Code Configuration
    ratePercentage: 'RATE (%)',
    chargeCategoryLabel: 'CHARGE CATEGORY',
    percent: '%',

    // Payment Method Configuration
    paymentMethodListHeader: 'PAYMENT METHOD',
    paymentType: 'PAYMENT TYPE',
    surchargeDiscount: 'SURCHARGE/DISCOUNT',
    paymentMethodList: 'PAYMENT METHOD CONFIGURATION',
    paymentTypeForm: 'Payment Type',
    enableModifier: 'Enable Surcharge/Discount',
    enableSurcharge: 'Enable Surcharge',
    enableDiscount: 'Enable Discount',

    // Charge Category Configuration
    taxesAttached: 'TAXES ATTACHED',
    accountType: 'Account Type',
    exportOptions: 'Export Options',
    secureCategoryLabel: 'Secure Category',
    isThisSecureCategory: 'Is this a Secure Category ?',
    associatedTaxes: 'Associated taxes',
    matchTenantTaxExemptStatus: 'Match tenant\'s tax exempt status',
    noFieldsCanBeModified: 'No Fields can be modified without the permission',
    noTaxesAreConfigured: 'No taxes are configured',

    // Tax Code Exempt Configuration
    exemptList: 'Exempt List',
    taxCodeExemptCategory: 'Tax Code Exempt Category',
    exemptedTaxes: 'Exempted Taxes',
    atLeastOneTaxCode: 'Add at least one tax code',

    // Rental general Configuration
    nonRentalChargeCategoryAllowance: 'Do you want to allow non-rental charge-category for unit? ',
    finalMonthProrateToDepartureDate: 'Assess rent till the expected move out date for the final month',
    default: 'Default',
    standardPeriod: 'Standard Period',
    tenantFacingWebsites: 'Tenant Facing Websites',
    thirdPartyVendors: 'Third Party Vendors',
    selectAllowedPeriod: 'Select allowed periods for',
    alwaysAssess: 'Always assess',
    daysEarly: 'days early',
    daysProrateCalculation: 'Days For Prorate Calculation',
    consider: 'Consider',
    roundingType: 'Rounding Type',
    roundingTypeForProrate: 'Rounding Type for Prorate',
    syrasoftFacilitate: 'Syrasoft Facilitate',
    rentalConfigRemoveWarningHeading: 'Changes made to the periods supported.',
    rentalConfigRemoveWarning: 'We will be removing the unsupported period rates if already configured by any unit type. Are you sure about the changes?',

    // Rental Configuration
    configureReservation: 'Configure Reservation',
    reserveUpto: 'User/Tenant can reserve upto',
    reviewReservations: 'Review reservations that have past expected move in dates',
    reservationCharge: 'Reservation Charge',
    isTheReservationDeposit: 'Is the Reservation Deposit:',
    howToChargeReservationDeposit: 'How do you want to charge Reservation Deposit?',
    wouldCancellationBe: 'Would Cancellation be:',
    deactivateTenantOrLedger: 'De-activate Tenant and/or Ledger',
    reservationDepositInfo: 'Charged on Reservation with the system defined charge category. This is refundable.',
    lockReservationRatesInfo: 'The following will be locked at the time of reservation',
    configureMoveOut: 'Configure Move out',
    noticeBeforeMoveOut: 'Notice Before Move Out',
    noticeDays: 'Notice Days:',
    balanceOrRefundResolution: 'Balance/ Refund Resolution',
    resolveChargesBy: 'Resolve Charges By',
    balanceDue: 'Balance Due',
    creditDue: 'Credit Due',
    deactivateTenantAfterMoveOut: 'Do you want to deactivate tenant on moveout ?',
    chargeOnMoveOut: 'Charged on Move out with the charge category mapped to this field.This is not refundable.',
    websites: 'Websites',
    kiosk: 'Kiosk',

    // RTNS Snackbar
    refresh: 'Refresh',
    override: 'OVERRIDE',
    keepCurrentChanges: 'Keep Current Changes',
    wsUpdateMessage: 'Some Content of this resource has been modified by another client, What would you like to do?',
    wsCreateMessage: 'A new record has been created, please refresh to see the changes',
    rtnsBlockedSave: 'Some other client modified the critical information, please click on the refresh button',

    // move in configuration
    moveInConfigure: 'Configure Move In',
    anniversary: 'Anniversary',
    prorateFirst: 'Prorate first period',
    billNextPeriod: 'Bill the next period after',
    prorateSecond: 'Prorate second period',
    selectLetterTemplate: 'Select Letter Template',
    chargeOnMoveIn: 'Charged on Move in with the charge category mapped to this field. This is not refundable.',
    tenantDepositCategory: 'Tenant Deposit Category',
    rentalDepositCategory: 'Rental Deposit Category',

    // pay till
    payTill: 'Pay Till',
    amountToBePaid: 'Amount to be paid',
    billAheadError: 'There is an error from our side in creating all the charges, Please contact tech support',
    generating: 'Generating',
    generateBills: 'Generate Bill',
    successfulBillGeneration: 'Bills were successfully generated',
    noBills: 'No Bills To Be Generated',

    // Payment Configuration
    paymentConfigurationLabel: 'Payment General Configuration',
    validatePaymentsOver: 'Show warning if payments are over',
    allowPrepay: 'Allow Prepay',
    billFutureDates: 'Generate bill for future dates',
    receipts: 'Receipts',
    facilityCopy: 'Facility Copy',
    tenantCopy: 'Tenant Copy',
    showOnReceipt: 'Show on Receipt',
    enterHstNumber: 'Enter HST number',
    enterPstNumber: 'Enter PST number',
    enterGstNumber: 'Enter GST number',
    deliverBy: 'Deliver By',
    tenantFacing: 'Tenant Facing Receipts',
    userFacing: 'User Facing Receipts',
    depositLogLabel: 'Account number to be shown on the deposit log',
    autoPaySwitchLabel: 'Do you want to allow autopay ?',
    dontSendReceiptsDuringAutoPay: 'Don\'t send receipts during autopay',
    allowReceiptGeneration: 'Allow Generation of Receipt',
    acceptPPFromDelinquentTenants: 'Accept partial payment from delinquent tenants',
    taxIntegrationWithReceipt: 'Tax integration with receipt',
    showGst: 'Show GST',
    showHst: 'Show HST',
    showPst: 'Show PST',
    allowGenerationOfReceipts: 'Allow generation of receipts to utilise this feature',


    // Manual Late Event
    applied: 'applied',
    events: 'Events',
    apply: 'Apply',
    clearAll: 'Clear All',
    noManualLateEvent: 'No Late Events Found',
    waive: 'Waive',
    unwaive: 'Unwaive',
    lateSince: 'Late Since',
    eventDescription: 'Event Description',
    lateFee: 'Late Fee',
    action: 'Action',
    noFiltersFound: 'No Filters Found',
    processEvents: 'Process Events',
    eventsToBeProcessed: 'Events to be processed',
    eventsToBeWaived: 'Events to be waived',
    eventsProcessed: 'Events Processed',
    eventsFailed: 'Events Failed',
    eventsWaived: 'Events Waived',
    processingEvents: 'Processing Events',
    errorProcessingEvents: 'Error Processing Events',

    // Filters And Sorts
    sortBy: 'Sort By',
    advancedFilter: 'Advanced Filter',
    atleastOneUnit: 'Show Rented Tenants',
    moveInBetween: 'Expected Move In Date Between',
    appointmentBetween: 'Appointment Date Between',
    clearDate: 'Clear Dates',
    autopayEnabledWithoutToken: 'Without Saved Card',

    // Unit type
    categoryLabel: 'CATEGORY',
    setupFeeLabel: 'SETUP FEE',
    unitTypeHeader: 'Unit Type',
    monthLabel: 'Month',
    moveInCharges: 'Move In Charges',
    depositLabel: 'Deposit',
    configurePeriod: 'Configure Period',
    rentSliderNote: 'NOTE:  Enabling rent slider will help facility to change rates of the unit automatically based on the number of',
    rentedUnits: 'rented Units',
    lowerLimit: 'Lower Limit',
    higherLimit: 'Higher Limit',
    setLimitBasedOn: 'Set Limit Based On',
    limitInfo: 'Defined the occupation stats that triggers the rental rates to change.',
    overbookByInfo: 'The limit upto which reservations can be overbooked.',
    premiumRateInfo: 'The premium rental rates are added to the actual rate when the occupancy stats goes above the higher limit.',
    discountRateInfo: 'The discount rental rates are subtracted from the actual rate when the occupancy stats goes below the lower limit.',
    cannotIncreaseDiscount: 'Discount cannot be more than',
    periodRate: 'Period Rate',

    // configuration inheritance
    systemConfigs: 'SYSTEM CONFIGS',
    normalConfigs: 'NORMAL CONFIGS',
    systemFacility: 'System facility',
    systemBusinessGroup: 'System Business Group',
    revertChanges: 'Revert Changes',

    // Tenant Documentation
    deliveredDocuments: 'Delivered Documents',
    delivering: 'Delivering',
    reDelivering: 'Re-Delivering',
    deliver: 'Deliver',
    issuedDate: 'Issued Date',
    delivered: 'Documents are re-delivered Successfully',
    noDocuments: 'No Documents Found',
    generateDocuments: 'Generate Documents',
    style: 'Style',
    tillDate: 'Till Date',
    reDeliver: 'Re-Deliver',
    deliveredLabel: 'Delivered',

    // User Role RTNS
    wsUserRoleCreateMessage: 'There are updates to User Roles list',
    wsUserRoleUpdateMessage: 'There are updates to this role',

    // Delinquency List
    delinquencyListEmpty: 'No Delinquency Found',
    delinquencyListNumberOfDaysLate: 'NO OF DAYS LATE',
    delinquencyListLastContacted: 'LAST CONTACTED',
    delinquencyListDaysAgo: '(DAYS AGO)',
    delinquencyListLastContactedVia: 'CONTACTED VIA',
    delinquencyStatus: 'STATUS',
    balanceOwed: 'Balance Owed',
    viewStatusHistory: 'View status history',
    statusHistory: 'Status history',
    eventStatus: 'Event status',
    noOfDaysLateDesc: 'No. of Days Late (Descending)',
    noOfDaysLateAsc: 'No. of Days Late (Ascending)',
    tenantNameAsc: 'Tenant Name (Ascending)',
    tenantNameDesc: 'Tenant Name (Descending)',
    notProcessed: 'Not Processed',
    viewHistory: 'View history',
    currentStatus: 'Current status',
    daysAgo: 'days ago',
    delinquencyContactLogErrorMessage: 'Failed to retrieve last contacted details',

    // Delinquency Filter
    enterStartDay: 'Enter Start Day',
    enterEndDay: 'Enter End Day',
    minimumAmount: 'Minimum Amount',
    maximumAmount: 'Maximum Amount',
    enterNumbers: 'Enter Number',
    lastContacted: 'Last Contacted',
    clearAmount: 'Clear Amount',
    clearDays: 'Clear Days',
    contactedVia: 'Contacted Via',
    valueMustBeLessThan: 'Value must be less than',
    valueMustBeMoreThan: 'Value must be more than',
    lastContactedDetails: 'Last contacted details',

    // BEV RTNS
    levelAdded: 'A new level has been added',
    levelDeleted: 'A level has been deleted',
    levelNameChanged: 'A level\'s name has been changed',
    unitAdded: 'New Unit have been added',
    unitLayoutChanged: 'There was some changes made to the unit layouts',
    unitStatusChanged: 'Some of the unit\'s status have been updated',
    reservationMade: 'A reservation has been made',
    ledgerChanged: 'Ledger updated',

    // Configuration RTNS
    wsConfigurationUpdateMessage: 'There are updates to this config !',
    wsConfigurationCreateMessage: 'There are updates to this list !',

    // Payment RTNS
    wsPaymentUpdateMessage: 'There are updates to the ledger balance.',
    wsTenantInfoUpdateMessage: 'There are updates to the tenant information',
    wsPaymentMethodUpdateMessage: 'There are updates to the payment method.',
    wsPromoPlanUpdateMessage: 'There are updated to promo plan configuration',

    // Tenant/Ledger RTNS
    wsViewTenantUpdateMessage: 'There are updates in the tenant information',
    wsBalanceUpdatingMessage: 'Updating Ledger Balance',

    // Reservation RTNS
    wsReservationRentedMessage: 'This unit got rented. Do you want still continue reservation',

    // Move-In RTNS
    wsMoveInRentedMessage: 'Unit is rented',
    changeUnit: 'Change Unit',
    wsConfigUpdateMessage: 'There has been some changes to the config',
    wsUnitAvailabilityMessage: 'Unit is unavailable. Cannot move in to this unit',
    wsTenantUpdatedMessage: 'Tenant information updated',
    wsUnitDeactivatedMessage: 'Unit has been deactivated !',

    // Move-Out RTNS
    wsRentalInfoUpdateMessage: 'Rental Information is updated',
    wsBillingInfoUpdateMessage: 'There were changes to billing information',
    wsUnitMovedOutMessage: 'This unit is already moved out ',

    // Reload Text
    updateProrate: 'Update Prorate',
    recalculateTotal: 'Recalculate Total',
    updateNextPayableAmount: 'Update Next Amount Payable',

    result: 'Result',
    results: 'Results',
    found: 'Found',

    // Lease Configuration
    leaseGeneral: 'Lease General',
    nextLeaseNumber: 'Next Lease Number',
    leaseDocument: 'Lease Document',
    renewalPeriod: 'Renewal Period',
    months: 'Months',
    deliveryOption: 'Delivery Option',
    addendum: 'Addendum',
    leaseRenewal: 'Lease Renewal',
    delivery: 'Delivery',
    document: 'Document',

    // Facility General Config
    facilityId: 'Facility ID',
    facilityName: 'Name',
    managerName: 'Manager\'s Name',
    abbreviation: 'Abbreviation for Facility',
    enableMessaging: 'Enable Messaging Service number',
    areaCodeThreeDigits: 'Area Code should be at most 3 digits',
    areaCode: 'Area Code',
    timezone: 'Timezone',
    otherPhone: 'Other Phone',
    facilityIdentification: 'Identify Facility As',
    enterEmail: 'Enter Email Address',
    tenantDefaultAddress: 'Tenant\'s Default Address',
    enterName: 'Enter Name Here',
    enterAddressLine1: 'Enter Address Line 1 Here',
    enterAddressLine2: 'Enter Address Line 2 Here',
    enterCity: 'Enter City Here',
    enterState: 'Enter State Here',
    enterZip: 'Enter Zip Here',
    enterPhoneNumber: 'Enter Phone Number Here',
    enterFaxNumber: 'Enter Fax Number Here',
    enterOtherPhone: 'Enter Other Phone Number Here',
    enterManagerName: 'Enter Manager\'s Name Here',
    selectTimezone: 'Select TimeZone Here',
    enterAbbreviation: 'Enter Abbreviation Here',
    enterAreaCode: 'Enter Area Code Here',
    enterEmailHere: 'Enter Email Address Here',
    registerNumber: 'Register Number',
    verifyEmail: 'Verify Email',
    preEmailVerification: 'Please verify the email to utilise email \nservices',
    emailVerification: 'Email Verification',
    emailVerificationDelivered: 'Verification email has been \ndelivered',
    emailVerificationResend: 'Resend Verification Email',
    emailSent: 'A verification email has been sent to the mentioned email address. Please verify in order to start the email service.',
    pendingWarning: 'Email verification pending for this email',
    emailVerificationExpired: 'Verification link has expired. Please \nretry verification',
    reRegisterNumber: 'Re-Register Number',
    cityName: 'City Name',
    facilityNameOption: 'Facility Name',
    sureWillCheck: 'Sure, will check',
    clickHere: 'Click Here ',
    toResend: 'to resend',
    emailPrefixTooltip: 'Email will be sent using the prefix entered below with @syrasoft.com as the domain name',
    facilityEmail: 'Facility Email',
    companyVertical: 'Company Vertical',
    enterCompanyWebsite: 'Enter the company website',
    einSlashTaxId: 'EIN/Tax ID',
    enterEinSlashTaxId: 'Enter EIN/Tax ID',
    einIssuingCountry: 'EIN Issuing Country',
    legalEntityType: 'Legal Entity Type',
    enterLegalEntityType: 'Enter the legal entity type',
    textRegistrationFormTitle: 'Please fill in the required information to complete the registration process',
    textRegisterDisabledMessage: 'Facility phone and email are required for number registration',
    textNumberNotYetVerified: 'Number is not yet verified from the vendor. It might take few more days.',
    textNumberVerified: 'Number is now verified for messaging.',

    // Pending Tasks Related
    pendingTasks: 'Pending Tasks',

    // To Do List Related
    todoList: 'To Do List',
    hurray: 'Hurray !',
    printFailures: 'Print Failures',
    printFailuresSubtitle: 'Captures all document which failed to print.',
    pendingInvoiceDelivery: 'Pending Invoice Delivery',
    pendingInvoiceDeliverySubtitle: 'Invoice that were generated but not delivered',
    nothingPendingForCurrentDay: 'Nothing pending for the current day',
    pendingOverlockUnlock: 'Pending Overlock/ Unlock',
    pendingOverlockUnlockTaskSubtitle: 'Captures all the units with pending statuses of overlock / unlock',
    restockInventory: 'Restock Inventory',
    restockInventoryTaskSubtitle: 'Captures all the inventory items which need to be restocked',
    inventoryAuditTaskSubtitle: 'Captures all the pending inventory audits',
    applyLateEvents: 'Apply Late Events',
    applyLateEventsTaskSubtitle: 'Captures all the tenants who’ve been late to pay the rent',
    pastDueReservation: 'Past Due Reservations',
    pastDueReservationTaskSubtitle: 'Captures all past reservations that are not converted to a move in',
    noPendingOverlockUnlockUnits: 'No Pending Overlock/ Unlock Units',
    automaticCreditCardDeclines: 'Automatic Credit Card Declines',
    noAutomaticCreditCardDeclines: 'No Automatic Credit Card Declines',
    numberOfTimesReprocessed: 'No. of times re-processed',
    emailVerificationPending: 'Email Verification Pending',
    emailVerificationPendingHeader: 'Using the product without verifying your email may lead to potential issues and limitations in accessing certain functionalities.',
    emailVerificationPendingBody: 'How to Verify Your Email:',
    emailVerificationStepOne: 'Check your inbox for an email from us with the verification link.',
    emailVerificationStepTwo: 'Click on the provided link to confirm your email address.',
    emailVerificationStepThree: 'If you haven\'t received the verification email, please check your spam folder or request a new verification email by clicking on',
    emailVerificationStepFour: 'If you don’t want to utilise the email service remove Email.',
    resendVerificationEmail: 'Resend Verification Email',
    creditCardsAboutToExpire: 'Credit cards about to expire',
    invalidGateAccessCode: 'Invalid Gate Access Code',
    ledgersWithInvalidAccessCode: 'Ledgers with Invalid Gate Access Code',
    unitsWithInvalidAccessCode: 'Units with Invalid Gate Access Code',
    noLedgersFound: 'No Ledgers Found',
    noUnitsFound: 'No Units Found',
    oldAccessCode: 'OLD ACCESS CODE',
    autoGenerateCode: 'Auto Generate Code',
    rtnsFacilityConfigMessage: 'Gate configuration has been updated. Please click on refresh.',

    // Reminders Related
    reminder: 'Reminder',
    reminders: 'Reminders',
    createReminder: 'Create Reminder',
    editReminder: 'Edit Reminder',
    reminderDate: 'Reminder Date',
    recurringStatus: 'Recurring status',
    rCode: 'Rcode',
    addTitle: 'Add Title',
    reminderCreated: 'Reminder Created Successfully',
    reminderUpdated: 'Reminder Updated Successfully',
    reminderDeleted: 'Reminder Deleted Successfully',
    reminderEmptyDayViewText: 'You don\'t have reminders set for today.',
    reminderEmptyDayViewCreateText: 'To get started, simply use the \'Create Reminder\' button at the top.',
    reminderEndDateInfoText: 'Date till when reminder will be active',

    // External Activity Related
    externalActivity: 'External Activity',
    comingSoon: 'Coming Soon!',

    unitsProcessedSuccessfully: 'Units Processed Successfully',
    someUnitsProcessingFailed: 'Some Units Could Not Be Processed',
    processing: 'Processing',

    // Invoicing
    invoicing: 'Invoicing',
    enableAutoInvoicing: 'Enable Auto Invoicing',
    nextInvoiceNumber: 'Next Invoice Number',
    daysBeforeRentAssessment: 'No of days ahead of rent assessment',
    invoiceMailing: 'Invoice Mailing',
    feeForMailing: 'Fee for Mailing',
    partialPayment: 'Partial Payment',
    chargesDueReport: 'charges due report ',
    wantToGenerate: 'Do you want to generate ',
    forPartialPayment: 'for the partial payment?',
    reportStyle: 'Report Style',
    sortOrder: 'Sort Order',
    report: 'Report',
    min0: 'Value must be more than 0',
    max30: 'Value must be less than 30',
    sendInvoice: 'Send Invoice',
    invoiceForZeroBalance: 'invoice for zero balance?',
    noOfDaysAheadOfRentAssessment: 'No of days ahead of rent assessment ',
    invoiceToDate: 'Invoice To Date',
    invoiceTo: 'Invoice To',
    selectInvoiceToDate: 'Select Invoice To Date',
    sendInvoiceBefore: 'Send Invoice Before',
    daysOfRentAssessment: 'days of rent assessment',
    invoiceAlreadyScheduled: 'Invoice is already scheduled for delivery.',
    wantToCancelScheduled: 'Do you want to cancel the scheduled one?',
    wantToCancelScheduledAndSendNew: 'Do you want to cancel the scheduled one and send the new one?',
    sendNewInvoice: 'SEND NEW INVOICE',
    doNotCancelSchedule: 'DON\'T CANCEL SCHEDULE',
    cancelScheduledInvoice: 'CANCEL SCHEDULED INVOICE',
    okay: 'Okay',
    greaterThanZero: 'This should be greater than zero',
    sendNotice: 'Send Notice',
    invoiceAlreadySent: 'Invoice has already been sent to the tenant. You cannot make this change now.',
    invoiceDeliveryType: 'Invoice Delivery Type',

    // NSF
    nsfSuccess: 'NSF Fees applied successfully',
    isNsfRequired: 'Do you want to apply NSF fees for this AR?',

    // Gate Config
    gates: 'Gates',
    label: 'Label',
    selection: 'Selection',
    performInitialLoad: 'Perform Initial Load',
    downloadUnitList: 'Download Unit List',
    downloadUnitListMessage: `Download the CSV containing unit list and email it to shawn.neily@janusintl.com to 
    complete the onboarding process. Also mention UNIQUE_SITE_ID = v11 facility id in the email`,
    archiveGateUpdateFiles: 'Archive Gate Update Files',
    product: 'Product',
    digiGate: 'Digi Gate',
    PTIGate: 'PTI Gate',
    PTIFalconGate: 'PTI Falcon Gate',
    openTechGate: 'Open Tech Gate',
    doorKingGate: 'Door King Gate',
    nokeGate: 'Noke Gate',
    spiderDoor: 'Spider Door',
    kpc: 'KPC',
    storGuard: 'StorGuard',
    gateSpecifications: 'Gate specifications',
    minCodeLength: 'Minimum Code Length',
    maxCodeLength: 'Maximum Code Length',
    validRange: 'Valid Range',
    allowCodeReuse: 'Allow Code Reuse',
    siteKey: 'Site Key',
    siteCode: 'Site Code',
    operUsername: 'Operator Username',
    operPassword: 'Operator Password',
    apiKey: 'API Key',
    apiSecret: 'API Secret',
    siteId: 'Site Id',
    note: 'Note',
    gateCodeReuse: '1. Gate codes should only be reused with a FOB device or similar process.',
    tenantShouldNotHaveAccessCode: `After move-out previous tenants should not have access to the 
    facility using their old access code.`,
    gateCodeClear: '2. We\'ll always clear access code as part of move out',
    zoneSettings: 'Zone Settings',
    openTechCloudCredentials: 'Open Tech Cloud Credentials',
    ptiCloudCredentials: 'PTI Cloud Credentials',
    spiderDoorCredentials: 'Spider Door Credentials',
    doorKingCredentials: 'Door King Credentials',
    defaultTimeZone: 'Default Time Zone',
    nokeGateCredentials: 'Noke Gate Credentials',
    defaultKeypadZone: 'Default Keypad Zone',
    keypadZoneRange: 'Keypad Zone Range',
    notValidRange: 'Not valid range',
    invalidMinRangeLength: 'Invalid min range length',
    invalidMaxRangeLength: 'Invalid max range length',
    initiated: 'Initiated',

    clearNewLedger: 'Clear newly created Ledger?',
    switchToAnotherLedger: 'Switching to another ledger, do you want to clear the newly created ledger?',
    discardLedger: 'DISCARD LEDGER',
    keepLedger: 'KEEP LEDGER',
    notInRange: 'Not in range',
    duplicateAccessCode: 'Duplicate Access Code',
    gateAccessCode: 'Gate Access Code',
    outOfRange: 'Out of Range',
    invalidCodeLength: 'Invalid Code Length',
    invalidCodeType: 'Invalid Code Type',
    gate: 'Gate',
    gateLockedOut: 'Gate Locked Out',
    gateLocked: 'Gate Locked',
    gateUnlocked: 'Gate Unlocked',
    gateCodeActive: 'Gate Code Active',
    shouldProvideAccess: 'Do you want to give gate access to this tenant ?',
    lockAccess: 'Do you want to gate lockout this tenant ?',
    maximumLimitReached: 'Maximum limit reached',
    invalidRange: 'Invalid Range',

    recurringCharges: 'Recurring Charges',
    recurringChargeDescription: 'Enter the description for the charge',
    enterDate: 'Enter Date',
    chargeType: 'Charge Type',
    addRecurringCharge: 'Add Recurring Charge',
    recurringChargesUpdated: 'Recurring Charges are updated for this unit',
    noRecurringCharges: 'There are no recurring charges for this unit',
    exceeds100Percent: 'Cannot exceed 100%',
    percentageOfUnitRate: 'Percentage of Unit Rate',

    // ACH configuration
    ach: 'Ach',
    achConfiguration: 'ACH Configuration',
    selectAchProvider: 'Select ACH provider',
    achProvider: 'ACH provider',
    bankName: 'Bank Name',
    enterBankName: 'Enter name of the bank for the Facility',
    bankAccountNumber: 'Bank Account Number',
    enterOnlyNumbers: 'Enter only numbers',
    bankRoutingNumber: 'Bank Routing Number',
    immediateDestinationId: 'Immediate Destination ID',
    immediateDestinationName: 'Immediate Destination Name',
    enterImmediateDestinationName: 'Enter Immediate Destination Name',
    immediateOriginId: 'Immediate Origin Id',
    immediateOriginName: 'Immediate Origin Name',
    enterImmediateOriginName: 'Enter Immediate Origin Name',
    useRouting: 'Use Routing for header',
    downloadFilePath: 'Download File Path',
    egFilePath: 'Eg: C:/Users/Downloads/',
    routingNumberPlaceHolder: '012345678',
    useAchOnFile: 'Use ACH On File',

    // conversations
    conversations: 'Conversations',
    textMessages: 'Text Messages',
    searchOnCustomerNameNumber: 'search for customer name/ number',
    searchOnCustomerNameEmail: 'search for customer name/ email',
    compose: 'Compose',
    display: 'Display',
    dateRange: 'Date Range',
    unreadMessages: 'Unread Messages',
    readMessages: 'Read Messages',
    yesterday: 'Yesterday',
    replyHere: 'Reply to the text here',
    composeNewMessage: 'Compose New Message',
    lessThan1024: 'Text entered should be between 3 and 1024 characters',
    lessThan160: 'Text entered should be between 3 and 160 characters',
    retry: 'Retry',
    viewThread: 'View Thread',
    reply: 'Reply',
    subject: 'Subject',
    send: 'Send',
    sending: 'Sending',
    noOfSubject: 'No. of subjects',
    recentSubject: 'Recent subject',
    attachment: 'Attachment',
    sentTo: 'Sent to',
    successfullySent: 'Message sent successfully.',
    unreadTextOrEmail: 'Unread Email / Text Message',

    // Transfer Config
    transfer: 'Transfer',
    chargeFeeOnTransfer: 'Do you want to charge fee on transfer?',
    transferFee: 'Transfer Fee',
    transferDocument: 'Transfer Document',
    additionalCopies: 'Additional Copies',
    unitTransfer: 'Unit Transfer',

    // Ach Operation
    achOperations: 'ACH Operations',
    currentBatch: 'VIEW CURRENT BATCH',
    allBatch: 'VIEW ALL BATCHES',
    submitBatch: 'Submit Batch',
    transactionId: 'TRANSACTION ID',
    achEffectiveDate: 'EFFECTIVE DATE',
    batchSubmitted: 'Batch has been submitted successfully\nDo you want to download it?',
    yesDownload: 'YES, Download',
    notNow: 'NOT NOW',
    batchNumber: 'BATCH NUMBER',
    batchCreatedDate: 'BATCH CREATED DATE',
    downloadFile: 'Download file',
    batchTransactions: 'Batch Transactions',
    batchBetween: 'Batch Created Date Between',
    occupancyHistory: 'Occupancy History',
    useTenantAddress: 'USE TENANT ADDRESS',

    // Batch Payment
    batchPayment: 'Batch Payment',
    creditCardBatch: 'Credit Card Batch',
    creditCardBatchListEmpty: 'No Credit card batches found.',
    achBatchListEmpty: 'No ACH batches found.',
    selectMode: 'Select mode',
    batchCreatedBetween: 'Batch created between',
    otherBatches: 'Cash/Check/Money order Batch',
    otherBatchListEmpty: 'No batch payments found.',
    achBatch: 'ACH Batch',

    openLedgerReview: 'Do you want to sell the items in the cart before moving\nto the ledger review ?',
    noStayHere: 'No, Stay here',
    yesSell: 'YES, Sell',
    chargeCategoryCaps: 'CHARGE CATEGORY',
    dateCaps: 'DATE',
    amountCaps: 'AMOUNT',

    // Account Management
    showAllAccounts: 'Show All Accounts',
    deleteAccount: 'Delete Account',
    searchBy: 'Search By',
    working: 'You are working with',
    liveData: 'LIVE DATA',
    beCautious: 'Be cautious!',
    deleteFacility: 'Delete Facility',
    editAccount: 'Edit Account',
    createFacility: 'Create Facility',

    // Unit Transfer
    unitSelection: 'Unit Selection',
    moveOutOf: 'Move Out Of',
    moveInTo: 'Move In To',
    transferDate: 'Transfer Date',
    additionalRent: 'Additional Rent',
    totalTransferCost: 'Total Transfer Cost',
    proratedRent: 'Prorated Rent',
    netChange: 'Net Change',
    newUnit: 'New Unit',
    transferredFrom: 'transferred from',
    postUnitTransfer: 'POST UNIT TRANSFER',
    unitRentAssessmentPending: 'This unit has pending rent assessment',
    noOfDaysLeft: 'No. of days left',
    ratePerDay: 'Rate / Day',
    billsUpdated: 'There are updates to the bills',
    updating: 'Updating',

    // Business Group Management Related
    backToOverview: 'Back to overview',
    accountName: 'Account Name',
    businessGroupCreateError: 'Business Group Create Failed',
    businessGroupCreateSuccess: 'Business Group Created Successfully',
    businessGroupUpdateError: 'Business Group Update Failed',
    businessGroupUpdateSuccess: 'Business Group Updated Successfully',
    businessGroupPermissions: 'Users can create, edit business groups and move facilities between business groups',

    // Tenant Facing Websites
    tenantWebsites: 'Tenant Websites',
    origin: 'Origin',
    enabled: 'Is Enabled',
    deliverSignUpLink: 'Deliver Sign Up Link',
    deliverTenantSignUpLink: 'Deliver Tenant Sign Up Link',
    sendActionToAllEnabledTenants: 'This action will send the sign-up link to all {} enabled tenants. ',
    pleaseEnableTextOrEmail: 'Please enable email or text to utilize this feature',
    signUpLinksDeliveredSuccessfully: 'Tenant sign up link delivered successfully.',
    paymentMethodOptions: 'Payment Method Options',
    autoPayManagement: 'Auto Pay Management',
    allowCreditCardPayment: 'Allow credit card payment',
    allowAchPayment: 'Allow ACH payment',
    allowSignUpForAutoPay: 'Allow sign up for autopay',
    allowTenantsToOptOutForAutoPay: 'Allow tenants to opt out for autopay',
    balanceManagement: 'Balance Management',
    allowPartialPayment: 'Allow partial payment',
    allowPrePayment: 'Allow pre-payment',
    enterWebsiteUrl: 'Enter website URL',
    generalSettings: 'General Settings',
    unitTypes: 'Unit Types',
    currentRate: 'Current Rate',
    currentRateDescription: 'User can modify the current rate of a unit',
    webRate: 'Web Rate',
    unitTypeDisplayLabel: 'Unit Type Display Label',
    enterLabel: 'Enter Label',
    enterWebRate: 'Enter Web Rate',
    selectGroup: 'Select Group',
    webRentalPeriod: 'Web Rental Period',
    refundable: 'Refundable',
    emailNotConfigured: 'Email not configured for Tenant',
    noteForTFWAccountAlreadyExists: 'Tenant already has an account',
    viewRates: 'View Rates',
    productionWebsiteUrl: 'Production Website URL',
    stagingWebsiteUrl: 'Staging Website URL',
    creditCardPaymentNotConfigured: 'Credit card payment is not configured for this facility',
    achNotConfigured: 'Please configure ACH settings to use this',
    autoPayNotEnabledForFacility: 'Autopay is not enabled for this facility',
    prepaymentNotEnabledForFacility: 'Prepayment is not enabled for this facility',
    emailForTenantSignUp: 'Email for Tenant sign up',
    emailBody: 'Email body',
    customizeEmailTemplate: 'Customize email template',
    customizeEmail: 'Customize email?',

    // Internal App Configuration
    internalApp: 'Syrasoft Cloud Assist',
    standaloneAppDownloadLink: 'Standalone App Download Link',
    gateServiceDownload: 'Gate Service Download',
    shouldInstallGateService: 'Do you want to download and install gate service provided by Connect?',
    noThanks: 'No, Thanks',
    download: 'Download',
    printer: 'Printer',
    reinstall: 'Re-Install',
    syrasoftCloudAssist: 'Syrasoft Cloud Assist',
    printerSetupDownload: 'Printer Setup Download',
    gateSetupDownload: 'Gate Setup Download',
    serviceAlreadySetup: 'service is already setup',
    syrasoftCloudAssistDownload: 'Syrasoft Cloud Assist Download',
    shouldReinstallService: 'Do you want to delete the existing app and re-install again?',
    gateServiceNote: 'Note: Please open this configuration where gate software is setup and then download this file',
    internalAppMainDescription: 'Enjoy effortless communication with your printers and gates using the Syrasoft Cloud Assist App!With this app, you can access your printers from anywhere, making printing a breeze.\nIf you have multiple networks in your facility, you can install the app on multiple systems for easy access to all your hardware.',
    internalImportantHeading: 'Important',
    internalImportantList1: 'For optimal performance, ensure the system(s) where you\'re installing the Syrasoft Cloud Assist App are always up and running.',
    internalImportantList2: 'The app is currently only supported on Windows systems',
    internalPleaseReadPara: 'Please read through the following information carefully',
    internalPrinterSetupHeading: 'Printer Set-up',
    internalPrinterSetupDescription: `Streamline your “print from anywhere” experience with the Syrasoft Cloud Assist App.
    The app allows you to easily set up the printers you want to access through Syrasoft Connect software. 
    Simply install the app and get started with your printer setup.`,
    internalGateSetupHeading: 'Gate Set-up',
    internalGateSetupBody: `If you have a gate that's compatible with Syrasoft Connect software, simply ,
    download the Syrasoft Cloud Assist App on the system where your gate software is set up.\n
    After installation, you'll be able to easily configure your gate and enjoy seamless integration with the Syrasoft Connect software.`,
    appDownloadLabel: 'Download the app',

    // Tooltip Info
    quickPayTooltip: 'Open quick pay to accept payments',
    ledgerReviewTooltip: 'Open ledger review to view AR',
    transferTooltip: 'Transfer tenant to another unit',
    overlockOrUnlockTooltip: 'Overlock or Unlock a unit depending on payment',
    editTenantTooltip: 'Edit tenant details',
    moveOutTooltip: 'Move tenant out of a unit',
    moveInTooltip: 'Move tenant into a unit',
    pointOfSaleTooltip: 'Open Point of Sale to sell to tenant',
    documentationTooltip: 'Open Documentation for this tenant',
    unlockUnitTooltip: 'Unlock Unit',
    lockUnitTooltip: 'Lock Unit',


    // Rent Adjustment
    adjustments: 'Adjustments',
    autoRentAdjustment: 'Auto Rent Adjustment',
    rentAdjustmentListEmpty: 'No Rent Adjustments Scheduled',
    stopRentAdjustment: 'Stop Adjustment',
    viewRentAdjustment: 'View Adjustment',
    stoppingAdjustment: 'Stopping Adjustment',
    stopConfirmationMessage: 'Are you sure you want to stop the adjustment?',
    stopConfirmButtonText: 'Yes, stop the adjustment',
    stopDeclineButtonText: 'No, I want to keep it',
    failedToStopRentAdjustment: 'Failed to stop rent adjustment',
    rentAdjustmentStoppedSuccessfully: 'Rent adjustment stopped successfully',
    tenantNotification: 'Tenant Notification',
    doYouWantToNotifyTheTenant: 'Do you want to notify the tenant?',
    sendNotificationToAlternateContact: 'Send the notification to alternate contact as well',
    deliveryOptionForTenant: 'Delivery Option for Tenant',
    scheduleDelivery: 'Schedule Delivery',
    daysBeforeTheAdjustmentTakesPlace: 'Days before the adjustment takes place',
    whenDoYouWantToScheduleDelivery: 'When do you want to schedule the delivery',
    selectDeliveryOptionForTenant: 'Select a delivery option for the Tenant',
    invalidDaysToDeliverBefore: 'Invalid number of days to deliver before',
    selectionCriteria: 'Selection Criteria',
    schedulingRentAdjustment: 'Scheduling Rent Adjustment',
    byUnits: 'By Units',
    byTenants: 'By Tenants',
    rentalPeriod: 'Rental Period',
    selectRentalPeriod: 'Select Rental Period',
    basedOnLastAdjustment: 'Tenants who have not been adjusted before',
    basedOnOccupancyOver: 'Tenants who have rented over',
    adjustmentRate: 'Adjustment Rate',
    effectiveDateForRentAdjustment: 'Effective Date For Rent Adjustment',
    rounding: 'Rounding',
    unitTypeRateSet: 'Unit Type Rate Set',
    unitsAffected: 'Units Affected',
    standardRate: 'Standard Rate',
    netChangeInIncome: 'Net Change In Income',
    rentAdjustmentsScheduled: 'Rent adjustments scheduled',
    rentAdjustmentScheduledSuccessfully: 'Rent adjustment scheduled successfully',
    resolveWarnings: 'Resolve Warnings',
    dontResolveWarnings: 'Don\'t Resolve Warnings',
    resolveWarningsConfirmMessage: 'This action cannot be undone. Are you sure you want to continue?',
    rentAdjustmentIsAlreadyScheduled: 'Rent Adjustment is already scheduled',
    increaseInRateExceeds: 'Increase in rate exceeds',
    percentOfCurRate: '% of current rate',
    invoicedToDateIsAfterRentAdjustmentDate: 'Invoiced to date is after adjustment date',
    newRateExceedsStdRate: 'New Rate exceeds standard rate',
    unresolvedUnits: 'Unresolved Units',
    resolutionOptions: 'Resolution Options',
    resolveAffected: 'Resolve Affected',
    resolveAllBy: 'Resolve All By',
    resolveBy: 'Resolve By',
    nextInvoicedDate: 'Next Invoiced Date',
    adjustmentDate: 'Adjustment Date',
    adjustmentAmount: 'Adjustment Amount',
    nextEffectiveRate: 'Next Effective Rate',
    unitsSelected: 'Units Selected',
    unitsToBeAffected: 'Units to be affected',
    tenantDetails: 'Tenant Details',
    effectiveRate: 'Effective Rate',
    nextRecurringCharge: 'Next Recurring Charge',
    nextAdjustedRate: 'Next Adjusted Rate',
    lastIncreaseDate: 'Last Increase Date',
    nextIncreaseDate: 'Next Increase Date',
    childId: 'Child Id',
    parentId: 'Parent Id',
    timeWindow: 'Time window',
    scheduled: 'Scheduled',
    tenantAndUnitInformation: 'Tenant and Unit Information',
    schedulingInformation: 'Scheduling Information',
    lastAdjustmentDate: 'Last Adjustment Date',
    rentAdjustmentAlreadyScheduled: 'There is already a pending adjustment. Do you want to schedule a new adjustment?',
    exitRentAdjustment: 'Exit Rent Adjustment',


    // Retail Sale - Returns
    returns: 'Returns',
    refundToTenantBalance: 'Refund to Tenant Balance',
    returnAndRefund: 'Return and Refund',
    purchaseOrder: 'Purchase order',
    receiptNumber: 'Receipt number',
    purchaseDate: 'Purchase Date',
    howDoYouWantToProceedWithRefund: 'How do you want to proceed with refund?',
    selectATenantToMakeRefunds: 'Select a tenant to make refunds',
    selectDateForPurchaseOrderTableData: 'Please, enter a date to populate the table',
    noPurchasesMadeForSelectedDate: 'No Purchases were made on the selected date',
    pleaseEnterPurchaseOrderNumberForTableData: 'Please, enter a purchase order number to populate the table',
    purchaseOrderSelectionTableEmptyMsgForCustomer: 'No Purchases were made by the selected tenant',
    purchaseOrderSelectionTableMsgForCustomerNotSelected: 'Please select a customer to populate the table',
    orderDetailsTableEmptyMsg: 'Returns table is empty. Please, select one of the options above to populate the table',
    failedToActivate: 'Failed to activate',
    activatedSuccessfully: 'Activated successfully',
    quantityPurchased: 'Quantity Purchased',
    purchasePrice: 'Purchase Price',
    returnQuantity: 'Return Quantity',
    refundAmount: 'Refund Amount',
    tenantIsDeactivated: 'Tenant is deactivated',
    ledgerIsDeactivated: 'Ledger is deactivated',
    vendorDeactivated: 'Vendor deactivated',
    inventoryDeactivated: 'Inventory deactivated',
    invalidQuantity: 'Invalid quantity',
    failedReturns: 'Returns - Failed Refunds',
    returnedOn: 'Returned On',
    refundCancelSuccessful: 'Refund cancelled successfully',
    refundCancelFailed: 'Refund cancellation Failed',
    returnInventory: 'Users can process returns',
    returnInventoryLabel: 'Return Inventory',

    // Refund to tenant balance
    returnAndRefundToTenantBalanceSuccess: 'Refund has been successfully added to the tenant balance',
    returnAndRefundToTenantBalanceFailed: 'Refund to the tenant balance failed',
    // Return and refund
    returnOnlySuccess: 'Items have been returned to the inventory successfully',
    returnAndRefundSuccess: 'Refund completed successfully',
    refundFailed: 'Failed to perform refund. Please, try again.',
    refundFailedTitle: 'Refund Failed',

    // insurance
    insurance: 'Insurance',
    monthlyPremium: 'Monthly Premium',
    coverageAmount: 'Coverage Amount',
    proratePremium: 'Prorate In',
    skipFirstPeriod: 'Skip insurance for first period?',
    shouldProrate: 'Do you want to prorate',
    the: 'The',
    first: 'First',
    second: 'Second',
    policyNumber: 'Policy ID',
    planName: 'Plan Name',
    insuranceSaved: 'Insurance Saved Successfully',
    seasonalDateFormat: 'MM-DD',
    insuranceCancelled: 'Insurance Cancelled Successfully',

    // late Events
    lateEventConfigId: 'Late Event Config Id',
    lateEvents: 'Late Events',
    event: 'Event',
    lastApplied: 'Last applied',
    nextApplicable: 'Next Applicable',
    dayLate: 'Days Late',
    noLateEventConfigured: 'No late events configured',

    // Phone Numbers
    invalidAreaCode: 'Invalid Area Code',
    invalidPhoneNumber: 'Invalid Phone Number',
    invalidCountry: 'Invalid Country Code',
    totalNumberCheck: 'Total number of digits should be',

    // documentation Field
    reprintBatch: 'Reprint Batch',
    printDocuments: 'Print Documents',
    selectBatch: 'Select Batch',
    filterList: 'Filter List',
    selectFilter: 'Select Filter',
    letterTemplate: 'Letter Template',
    letterSelection: 'Letter Selection',
    approvedCcPayments: 'Approved CC Payments',
    declinedCcPayments: 'Declined CC Payments',
    rateAdjustmentDate: 'Rate Adjustment Date',
    ccAboutToExpire: 'CC About to expire',
    oneTenant: 'One Tenant',
    reservationPending: 'Reservation Pending',
    deletedTenants: 'Deleted Tenants',
    tenantsWithUnits: 'Tenants with Units',
    tenantsWithoutUnits: 'Tenants without Units',
    emailEnabledTenants: 'Email Enabled Tenants',
    filterOptions: 'Filter Options',
    paymentVia: 'Payment Via',
    minimum: 'Minimum',
    maximum: 'Maximum',
    optionDaysLate: 'Days Late',
    invoiceBatch: 'Invoice Batch',
    batch: 'Batch',
    batchInformation: 'Batch Information',
    documentType: 'Document Type',
    subDocumentType: 'Sub Document Type',
    ledgerName: 'Ledger Name',
    documentName: 'Document Name',
    preferredDelivery: 'Preferred Delivery',
    printOnly: 'Print Only',
    emailOnly: 'Email Only',
    showAll: 'Show All',
    emailAndPrint: 'Email and Print',
    documentsAreSentForReprint: 'Documents Are Sent For Reprint',
    documentsAreSentForGeneration: 'Documents Are Sent For Generation',
    purchaseOrderSDPlaceholder: 'eg;1890123456',
    customerNameSDPlaceholder: 'Search Name',
    customiseEmail: 'Customise Email',
    customiseTextMessage: 'Customise Text Message',
    emailAlertSubtitle: '(When you customise your email message, your recipients will receive both the message and the selected letter.)',
    addMessage: 'Add Message',
    viewMessage: 'View Message',
    emailWillBeSentToEmailEnabled: 'NOTE: Email will be sent to all email enabled tenants.',
    mailingLabel: 'Mailing Label',
    documentGeneratedSuccess: 'Document generated successfully',
    textAlertSubtitle: '(When you customise your Text message, your recipients will receive both the message and their respective document.)',


    // Insurance Configuration
    configureInsurance: 'Configure Insurance',
    allowInsurance: 'Allow Insurance options from software',
    enableSecondMonthProrate: 'Enable second month prorate',
    insuranceProvider: 'Insurance Provider',
    selectProvider: 'Select a provider',
    insuranceSetup: 'Insurance Setup',
    insuranceProviderNumber: 'Insurance Provider Number',
    insuranceAgencyCode: 'Insurance Agency Code',
    insuranceAgencyCodePlaceholder: 'Enter Agency Code',
    insuranceProviderNumberPlaceholder: 'Enter Provider Number',
    requirePolicyId: 'Do you require policy id?',
    printAddendum: 'Do you want to print the addendum?',
    printAddendumNoPolicy: 'Do you want to print the addendum for no policy?',
    ratePlan: 'Rate Plan',
    selectPlan: 'Select a plan',
    scheduleReport: 'Schedule Report',
    sendReport: 'Send Report',
    selectReportRecurrence: 'Select a report recurrence',
    insuranceProviderEmail: 'Insurance Provider Email',
    insuranceProviderEmailPlaceholder: 'Enter Provider Email',
    setupAdvanceOptions: 'Setup Advance Options',
    coverageEndDate: 'Coverage End Date',
    coverageStartDate: 'Coverage Start Date',
    enterStartDate: 'Enter Start Date',
    enterEndDate: 'Enter End Date',
    enterCoverageAmount: 'Enter Coverage Amount',
    enterMonthlyPremium: 'Enter Monthly Premium',
    advanceOptions: 'Advance Options',
    selectPlansAdvanceOptions: 'Select Plans for advance setup',
    advanceOptionsSuccessful: 'Advance options saved successfully',
    advanceOptionsFailed: 'Advance options save failed',
    paymentLoginLabel: 'Enter user credentials to proceed with payment',
    refundLoginLabel: 'Enter user credentials to proceed with refund',
    invalidFutureDate: 'Future date not allowed.',

    // Permissions
    syrapayMerchantPortal: 'Syrapay Merchant Portal',
    transactionSearch: 'Transaction Search',
    transactionSearchSubtitle: 'User should be able to search for transaction on merchant portal',

    reverseOpenTransactions: 'Reverse Open Transactions',
    reverseOpenTransactionsSubtitle: 'User should be able to reverse open transactions',

    reverseSettledTransactions: 'Reverse Settled Transactions',
    reverseSettledTransactionsSubtitle: 'User should be able to reverse settled transactions',

    reporting: 'Reporting',
    reportingSubtitle: 'User should be able to access reporting on the merchant portal',

    virtualTerminal: 'Virtual Terminal',
    virtualTerminalSubtitle: 'User should be able to access virtual terminal on the merchant portal',

    terminalManagement: 'Terminal Management',
    terminalManagementSubtitle: 'User should be able to access terminal management on the merchant portal',

    pciManagement: 'PCI Management',
    pciManagementSubtitle: 'User should be able to access PCI Management on the merchant portal',

    // Duplicate Tenant
    nMatchesFound: n => `${n} match${n > 1 ? 'es' : ''} found!`,
    createDifferentTenant: 'CREATE DIFFERENT TENANT',
    editThisTenant: 'EDIT CURRENT TENANT',

    validUrlError: 'Please enter a valid URL',
    agreement: 'Agreement',
    termsAndConditions: 'Click here to accept Terms and Conditions',
    proceed: 'PROCEED',
    tenantChangesExternalActivity: 'External Activity - Tenant Changes',
    currentEmail: 'Current Email',
    newEmail: 'New Email',
    currentPhone: 'Current Phone No.',
    newPhone: 'New Phone No.',
    tenantChangesUpdatedSuccess: 'Tenant Changes updated successfully.',
    addressChanges: 'Address Changes',
    emailChanges: 'Email Changes',
    phoneChanges: 'Phone Changes',
    alternateContactChanges: 'Alternate Contact',
    markAsRead: 'Mark as Read',
    approve: 'Approve',
    decline: 'Decline',
    noAddressChangesRequested: 'No Address changes requested.',
    noAlternateContactChangesRequested: 'No alternate contact changes requested.',
    noExternalActivity: 'No external activity to track',
    tenantChangesTitle: 'tenant changes',
    previousAddress: 'Previous Address',
    updatedAddress: 'Updated Address',
    previousAlternateContact: 'Previous Alternate Contact',
    updatedAlternateContact: 'Updated Alternate Contact',
    newValue: 'newValue',
    oldValue: 'oldValue',

    // Config deactivate alert message
    deactivateNotAllowed: 'Deactivating this configuration is not allowed',
    chargeCategoryDeactivateWithDeps: 'We are unable to deactivate this charge category because it is being used by other configurations',
    chargeCategoryDeactivateWithoutDeps: 'If deactivated, this charge category can’t be used in future.\nAre you sure you want to deactivate this charge category?',
    paymentMethodDeactivateWithDeps: 'This payment method is being used as default payment method by some tenants.\nPlease remove this payment method as default method before deactivating this configuration',
    paymentMethodDeactivateWithoutDeps: "If deactivated, this payment method can't be used in future.\nAre you sure you want to deactivate the payment method?",
    taxCodeDeactivateWithDeps: "This tax code is already in use. Please remove the tax code from the charge category that's in use before deactivating this tax code",
    taxCodeDeactivateWithoutDeps: "If deactivated, this tax code can't be used in future.\nAre you sure you want to deactivate this tax code?",
    promoPlanDeactivate: "If deactivated, this promo plan can't be used in future.\nAll the existing ones will continue until they're completed. Are you sure you want to deactivate this plan?",
    maintenanceEventDeactivate: "If deactivated, this maintenance event can't be used in future. All the existing ones will continue until they're completed. Are you sure you want to deactivate this maintenance event?",
    generalDeactivateAlertMsgWithDeps: 'We are unable to deactivate this config because it is being used by other configurations',
    unitTypeDeactivateWithDeps: 'We are unable to deactivate this unit type because it contains active units',
    unitTypeDeactivateWithoutDeps: "If deactivated this unit type can't be used in future. \nAre you sure you want to deactivate this unit type?",
    taxExemptCategoryDeactivateWithDeps: 'We are unable to deactivate this tax exempt category because some tenants are still using it.',
    taxExemptCategoryDeactivateWithoutDeps: "If deactivated, this exempt category can't be used in future. \nAre you sure you want to deactivate this exempt category",
    // Config Deactivate Late Event Messages
    allManualTriggerLateEvents: 'There might be events which are yet to be processed.\nDeactivating the event results in removing those ledgers from processing.\nDo you want to deactivate the event?',
    allManualTriggersLateEventExitMsg: 'NO, WILL PROCESS FIRST',
    automaticEventRecurringOrLockoutMsg: 'If deactivated, this event can\'t be used in future.\nThere won’t be any changes for already implemented ones.',
    automaticEventOneTimeMsg: 'If deactivated, this event can\'t be used in future.There won\'t be any changes for those already implemented. Are you sure you want to deactivate this event?',
    automaticEventDollarPerDayMsg: 'If deactivated, this event can\'t be used in future.\n - If the billing was every day, then it would stop from the time you deactivate.\n - If the billing was marked as \'on payment\', then please create the charges and then consider deactivating otherwise the charges wouldn\'t be created.\nAre you sure you want to deactivate?',
    // waitingList
    waitingListOptionalMsg: 'If units are not available, do you want to automatically move the tenants to the waiting list ?',
    moveToWaitingList: 'Move to waiting list',
    informTenantsUnitNotAvailable: 'Inform tenants that unit is not available',
    waitingList: 'Waiting List',
    customerType: 'Customer Type',
    requestedOn: 'Requested On',
    cancelWaitingListConfirmation: 'Are you sure you want to reject this unit request?',
    reject: 'Reject',
    waitingListEmpty: 'No Waiting Found',
    waitingListRejectedMessage: 'Waiting Rejected Successfully',
    noExit: 'No, Exit',
    acceptWaitingReject: 'YES, REJECT REQUEST',
    enquiredOn: 'Enquired On',
    addToWaitList: 'Add To Wait List',
    editWaiting: 'Edit Waiting',
    waiting: 'Waiting',
    addToWaiting: 'Add To Waiting',
    editWaitListEntry: 'Edit Wait List Entry',
    enquiryDetails: 'Enquiry Details',
    enquiryDate: 'Enquiry Date',
    enquiryComments: 'Enquiry Comments',
    waitingCreated: 'Customer has been added to waiting list successfully',
    waitingModified: 'Waiting entry has been modified successfully',
    waitingListAlert: 'Waiting List Alert!',
    unitTypeAvailableMessage: 'A unit of type UNIT_TYPE is available for renting.',
    customersWaitingOnUnitType: 'You have customers waiting on this unit type.',

    // tfw change tracking
    changeTracking: 'Change Tracking',
    showNotificationsForMoveIn: 'Show notifications for Move-in',
    showNotificationsForReservation: 'Show notifications for Reservation',
    showNotificationsForPayment: 'Show notifications for Payment',
    changeTrackingNote: '(Approve last Name, address, email, phone number and alternate contact)',
    paidOn: 'Paid On',
    emailForMoveIn: 'Email for online Move-in',
    emailForReservation: 'Email for online Reservation',
    emailForPayment: 'Email for online Payment',
    emailForTenantChanges: 'Email for Tenant Changes',
    notifyViaEmail: 'Notify via Email',
    commaSeparatedEmailNote: 'Use commas to separate emails',

    // letter utility
    letterUtility: 'Letter Utility',
    letterSpecifications: 'Letter Specifications',
    mergeFields: 'Merge Fields',
    previewLetter: 'Preview Letter',
    deliverLetter: 'Deliver Letter',
    attemptingLetterDelivery: 'Attempting to deliver letter. It could take a few seconds.',
    previewing: 'Previewing',
    generateNow: 'Generate Now',
    generateLease: 'Generate Lease',
    generateNewLease: 'Generate New Lease',
    leaseGenerationSuccess: 'Lease document sent successfully.',
    leaseGenerationFailed: 'Lease generation failed. You can re-generate the lease again from unit or tenant.',
    generateLeaseNote: 'Note: You can always generate the lease later from unit or tenant',
    unitsRented: 'Rented Units',
    selectFailedBatch: 'Select Failed Batch',
    printFailure: 'Print Failure',
    reprintDocumentSuccess: 'Documents sent for reprint',
    printing: 'Printing',
    emptyLetterTemplate: 'Letter template is empty',
    pdfViewerNotSupported: 'PDF viewer not supported in this browser. You can still download the PDF.',
    documentDownloadError: 'Error downloading the letter:',
    failedToFetch: 'Failed to fetch',

    // ws print-failures
    wsUpdatePrintFailures: 'There has been update to this list.',
    contactInformation: 'Contact Information',
    isMoveInTenantDepositChargedLabel: 'Do you charge deposits for tenants when they move in for the first time?',
    isReservationDepositChargedLabel: 'Do you charge reservation deposit?',
    billTenantsEveryMonthLabel: 'Do you bill tenants on first of every month?',
    moveOutChargesLabel: 'What do you want to do with the charges when moving out?',
    depositAmount: 'Deposit Amount',

    isRentTaxable: 'Is your rent taxable?',
    isRetailItemsTaxable: 'Do you charge tax on retail items?',

    // Onboarding
    facilitySetup: 'Facility Setup',
    unitAndTenantAssociation: 'Unit & Tenant Association',
    taxCodeSetupSuccessful: 'Tax Code saved successfully',
    unitTypeSetupSuccessful: 'Unit types saved successfully',
    taxCodeSetupFailed: 'Tax Code save failed',
    facilitySetupFailed: 'Facility Setup failed!',
    facilitySetupSuccessful: 'Facility setup successful.',
    basicInfoUpdated: 'Basic information updated successfully',
    onboardingSetupComplete: 'ONBOARDING SETUP COMPLETE !!!',
    goOverOtherConfigurations: 'Please go over all the configurations wherever its feasible',
    stateTaxDescription: 'State Tax Rent',
    retailTaxDescription: 'State Tax Retail',
    unitTypeDetails: 'Unit Type Details',
    createExistingUnitTypes: 'Create Existing Unit Types',
    deleteUnitDetail: 'Delete Unit',
    createExistingTenant: 'Create Existing Tenant',
    tenantDetailsSetup: 'Tenant Details',
    createUnits: 'Create Units',
    slNo: 'SL NO.',
    unitSize: 'Unit Size',
    unitCategory: 'Unit Category',
    unitRateMo: 'Unit Rate/Mo',
    tenantFirstName: 'FIRST NAME',
    tenantLastName: 'LAST NAME',
    tenantEmail: 'EMAIL',
    tenantPhone: 'PHONE',
    unitCategoryList: 'Unit Category List',

    // Sandbox
    sandbox: 'Sandbox',
    pullToSandbox: 'Pull to Sandbox',
    mySandbox: 'My Sandbox',
    pullNewData: 'Pull New Data',
    selectFacility: 'Select Facility',
    pulledBy: 'Pulled by',
    dateAndTime: 'Date & Time',
    data: 'Data',
    liveDataLabel: 'Live Data',
    sandboxData: 'Sandbox Data',
    pullData: 'Pull Data',
    pullingData: 'Pulling Data',
    sandboxCreatedSuccessfully: 'Sandbox created successfully',
    doNotBringForward: 'Do not Bring Forward',
    tenantMobileNumbers: 'Tenant mobile numbers',
    tenantEmailIds: 'Tenant email ids',
    idProofDetails: 'ID Proof details',
    ccTokens: 'Credit card details. Rather bring forward with test card information',
    preparingToPull: 'Preparing to pull',
    pullFailed: 'Data Puling Failed',
    tablesPulled: 'tables pulled',
    tryAgain: 'Try again',
    fetching: 'Fetching',
    noDoNotPull: "No, Don't pull",
    yesOverride: 'Yes, override',
    sandboxExists: 'There is already a copy of this facility on your sandbox',
    doYouWishToOverride: 'Do you wish to override?',
    fetchingDocuments: 'Generating Documents',

    // Setup Internal console
    configurationSetupIsPending: 'Configuration setup is pending.',
    facilityInformation: 'Facility Information',
    facilityStatus: 'Facility Status',
    facilityNumber: 'Facility Number',
    facilityAbbreviation: 'Facility Abbreviation',
    facilityAddress: 'Facility Address',
    setUpFacilityName: 'Facility Name',
    setUpStatus: 'Setup Status:',
    setupConfigurations: 'Setup Configurations',
    thereAreNoUsersTaggedToThisFacility: 'There are no users tagged to this facility.',
    userSetUp: 'User Setup',
    userList: 'User List',
    accountDetails: 'Account Details:',
    setUpStatusPending: 'Pending',
    setUpStatusCompleted: 'Completed',
    activateFacilityConfirmation: 'Are you sure you want to activate this facility?',
    deactivateFacilityConfirmation: 'Are you sure you want to deactivate this facility?',
    yesDeactivate: 'YES, DEACTIVATE',
    yesActivate: 'YES, ACTIVATE',
    facilityActive: 'Active',
    facilityExpired: 'Expired',
    createNewAccount: 'Create New Account',
    createNewFacility: 'Create New Facility',
    userCreate: 'Create User',
    userEdit: 'Edit User',

    // Deposit Log
    previewNewDepositLog: 'Preview New Deposit Log',
    createNewDepositLog: 'Create New Deposit Log',
    selectPreviouslyGeneratedLogs: 'Select Previously Generated Logs',
    generate: 'Generate',
    previewLog: 'Preview Log',
    createNewDepositLogAlert: 'Please make sure all your financials in place! Are you sure you want to create a new deposit log with current date and time?',
    generateNewLog: 'GENERATE NEW LOG',
    noLogsWereGeneratedPreviously: 'No logs were generated previously',
    reGenerate: 'Regenerate',
    please: 'please',
    noNewData: 'There is no new data to be shown',

    // Quickbooks
    companyName: 'Company Name',
    qbSyncMessage: "Note: Software will automatically sync with QuickBooks before business hours. For synchronizing during work hours, simply click the 'Sync' button on this page",
    lastSynced: 'Last synced',
    editCompany: 'Edit Company',
    qbSyncStarted: 'Transactions onboarding started.',
    connectToQuickbooks: 'Connect to Quickbooks',
    noKeepIt: 'No, Keep It.',
    yesRemove: 'Yes, Remove',
    removeConnectionText: 'Removing connection will stop sending financial transactions to the quickbooks.',
    areYouSureText: 'Are you sure you want to remove the existing connection with the quickbooks?',
    removeConnection: 'Remove Connection',
    removedConnectionSuccess: 'Connection removed successfully.',

    // Tenant Details
    tenantDetailSuccess: 'Tenant Details Saved Successfully',
    tenantDetailError: 'Failed to Save Tenant Details',

    // Migration
    migration: 'Migration',
    migrationListEmpty: 'No Migrations Found',
    migrationStage: 'Migration Stage',

    // Migration-Form
    createMigration: 'Create New Migration',
    version: 'Version',
    dumpDate: 'Dump Date',
    customComments: 'Custom Comments',

    // Migration-Server
    migrationServerStatus: 'Migration Server Status',
    migrationServer: 'Migration Server',
    accountSelection: 'Account Selection',

    // Migration Actions
    viewMigrationStatus: 'View Migration Status',
    startMigration: 'Start Migration',
    deleteMigratedData: 'Delete Migrated Data',
    preMigrate: 'Pre-Migrate',
    editMigration: 'Edit Migration',
    migrationDeleteConfirmation: 'Are you sure want to delete the migration request ?',
    migrationConfirmation: 'Are you sure you want to migrate Account ?',
    deleteYesButton: 'YES, DELETE',
    deleteNoButton: 'NO, KEEP IT',
    migrateYesButton: 'YES, MIGRATE',
    migrateNoButton: 'NO, EXIT',

    searchFacility: 'Search Facility',

    // Migration details Actions
    format: 'Format',
    validate: 'Validate',
    migrate: 'Migrate',
    verify: 'Verify',
    viewDownload: 'View / Download',
    repair: 'Repair',

    // Account and Facility List pages
    searchAccountNoOrName: 'Search Account No / Name',
    searchFacilityNoOrName: 'Search Facility No / Name',

    // Dashboard
    unitOccupancy: 'Unit Occupancy',
    revenue: 'Revenue',
    occupancyTrends: 'Occupancy Trends',
    averageRentalDuration: 'Average Rental Duration',
    revenueComparison: 'Revenue Comparison',
    viewList: 'View List',
    noOfMonths: 'Number of Months',
    noOfUnits: 'Number of units',
    errorLoadingInfo: 'Error Loading Information.',
    tryAgainPlease: 'Please wait a moment and try refreshing.',
    activeReservations: 'Active Reservations',
    totalUnit: 'Total Unit',
    unitsLateEvent: 'Units having late events',
    unitsMaintenance: 'Units under maintenance',
    totalLeadConverted: 'Total Lead Converted',
    website: 'Website',
    referral: 'Referral',
    walkIn: 'Walk-In',
    deposits: 'Deposits',
    totalRentCollected: 'Total Rent Collected',
    totalTaxCollected: 'Total Tax Collected',
    lateCharges: 'Late Charges',
    services: 'Services',
    others: 'Others',
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
    activeTenant: 'Active Tenant',
    delinquentTenant: 'Delinquent Tenant',
    autopayEnabledTenant: 'Autopay Enabled Tenant',
    peopleWaiting: 'People in Waiting List',
    currentRevenue: 'Current Revenue',
    expectedRevenue: 'Expected Revenue',

    // Vehicle Config - Tenant Sections
    vehicle: 'Vehicle',
    vehicleInformation: 'Vehicle Information',
    make: 'Make',
    model: 'Model',
    year: 'Year',
    licensePlate: 'License Plate',
    registrationNumber: 'Registration Number',
    registrationExpiryDate: 'Registration Expiry Date',
    registerExpiryDate: 'Register Expiry Date',
    enterRegisterDate: 'Enter Register Date',
    vin: 'VIN',

    // Vehicles Configuration - Vehicles
    vehicles: 'Vehicles',
    notifyTenantInsurance: 'Do you want to notify the tenant if their insurance is about to expire?',
    notifyTenantRegistration: 'Do you want to notify the tenant if their registration is about to expire?',
    insuranceExpiration: 'Insurance about to expire',
    registrationExpiration: 'Registration about to expire',
    notifyBefore: 'Notify before',
    priorToExpiry: 'Prior to expiry date',
    addendumLetter: 'Addendum Letter',
    generateAddendumLetter: 'Do you want to to generate a vehicles addendum letter?',
    generateAddendumOnAddOrEdit: 'Generate addendum letter when adding/ editing a vehicle',
    generateAddendumOnUnitTransfer: 'Generate addendum letter On Unit Transfer',

    showMore: 'Show More',
    showLess: 'Show Less',

    noRefundAmount: 'Refund is not applicable for this purchase.\nPlease proceed with return option.',
    return: 'Return',

    // Vehicles WC
    unitAssociation: 'Unit Association',
    associated: 'Associated',
    notAssociated: 'Not Associated',
    editVehicle: 'Edit Vehicle',
    vehiclesListEmpty: 'No Vehicles Found',
    openVehiclesAssociated: 'Open vehicle(s) associated to this tenant',

    // Vehicle component
    openTenantVehicle: 'Open vehicle associated to this tenant',
    addVehicle: 'Add Vehicle',
    noVehiclesAdded: 'No vehicles have been added to this tenant yet',
    titleHolder: 'Title Holder',
    titleNumber: 'Title Number',
    lienHolder: 'Lien Holder',
    photo: 'Photo',
    associateUnit: 'Associate Unit',
    vehicleId: 'Vehicle ID',
    vehicleType: 'Vehicle Type',
    enterVehicleType: 'Enter Vehicle Type',
    vehicleMake: 'Vehicle Make',
    enterBrandName: 'Enter Brand Name',
    vehicleModel: 'Vehicle Model',
    enterModelName: 'Enter Model Name',
    vehicleLaunchYear: 'Vehicle Launch Year',
    enterlaunchYear: 'Enter Launch Year',
    enterLicensePlateNumber: 'Enter License Plate Number',
    engineNumber: 'Engine Number',
    mileage: 'Mileage',
    vehicleLength: 'Vehicle Length',
    vehicleHeight: 'Vehicle Height',
    vehicleWidth: 'Vehicle Width',
    vehicleColor: 'Vehicle Color',
    carrier: 'Carrier',
    enterCarrierName: 'Enter Carrier Name',
    enterPolicyNumber: 'Enter Policy Number',
    coverageLevel: 'Coverage Level',
    vehicleValue: 'Vehicle Value',
    vehiclePolicyNumber: 'Policy Number',
    close: 'Close',

    tenantInfoSaved: 'Tenant info Saved Successfully',

    // vehicle Integration- Unit transfer
    removeVehicle: 'Remove Vehicle',
    transferToNewUnit: 'Transfer to New Unit',
    weHaveVehicleAssociatedWith: 'We have a vehicle associated with',
    doYouWantToTransferVehicleTo: 'Do you want to transfer vehicle to',
    orRemoveFrom: 'or remove from',

    deactivateVehiclesAssociated: 'Deactivate vehicles associated with the unit',

    export: 'Export',
    exportFailed: 'Export Failed, Please Try Again',
    selectBatchType: 'Select Batch Type',

    // credit card batch payment
    creditCard: 'Credit Card',
    batchId: 'BATCH ID',
    totalLedgersProcessed: 'LEDGERS PROCESSED',
    batchPaymentMode: 'MODE',
    viewBatchDetails: 'VIEW DETAILS',
    successfulTransactions: 'SUCCESSFUL TRANSACTIONS',
    failedTransactions: 'FAILED TRANSACTIONS',
    balanceThatTime: 'BALANCE AT THAT TIME ($)',
    creditCardType: 'CREDIT CARD TYPE',
    declineCode: 'DECLINE CODE',
    creditCardBatchPayment: 'Credit card batch payment',
    achBatchPayment: 'ACH batch payment',
    createCCBatch: 'Create CC Batch',
    paymentPreference: 'Payment Preference',
    surchargeOrDiscount: 'Surcharge/Discount ($)',
    receipt: 'Receipt',
    ccDeclinedLetter: 'CC Declined Letter',
    processBatchPayment: 'Proces Batch Payment',
    batchProcessingStarted: 'Payment processing started successfully',
    paymentMode: 'Payment Mode',

    // System letter templates
    importSampleLetters: 'Import Sample Letters',
    sampleLetters: 'Sample Letters',
    sampleLetter: 'Sample Letter',
    import: 'Import',
    importing: 'Importing',
    sampleLettersAddedSuccessfully: 'Sample letters added successfully',
    sampleLettersImportedSuccessfully: 'Sample letters imported successfully',
    searchLetterTemplate: 'Search Letter Template',
    mergeFieldFetchFailed: 'Merge field fetch failed',

    fetchingLetter: 'Fetching Letter...',
    sampleLetterAddedSuccessfully: 'Sample letter added successfully',

    enterUpcId: 'Enter UPC ID',
    preview: 'Preview',
    vehicleDeactivateConfirmation: 'Are you sure you want to deactivate vehicle?',
    vehicleActivateConfirmation: 'Are you sure you want to activate vehicle?',
    nextArrow: '->',

    // Access Point Contact
    accessPointContactHeader: 'Help',
    accessPointContactHotlineHeader: 'Call our support hotline at:',
    accessPointContactCustomerSupportEmailHeader: 'Customer Support Email:',
    accessPointContactTeamViewerHeader: 'Request remote assistance through TeamViewer:',
    accessPointContactPhoneNumberLabel: 'Sales Phone Number: ',
    accessPointContactTechSupportLabel: 'Tech Support Phone Number: ',
    accessPointContactCustomerSupportLabel: 'Send an email to: ',
    accessPointContactTeamViewerLabel1: 'Download TeamViewer ',
    accessPointContactTeamViewerLabel2: ', and share your TeamViewer ID and password when prompted ',

    // Financial Export
    financialExport: 'Financial Export',
    classDescription: 'Class Description',
    accountingMethod: 'Accounting Method',
    transactionGrouping: 'Transaction Grouping',
    incomeCategorization: 'Income Categorization',
    transactionConfiguration: 'Transaction Configuration',
    alternateDescriptions: 'Alternate Descriptions',
    accountReceivables: 'Account Receivables',
    undepositedFunds: 'Undeposited Funds',
    exportMethod: 'Export Method',
    downloadingFile: 'Downloading File',
    exportNote: 'Note: Quickbooks Desktop was last exported on ',
    bulkUpdateUnitType: 'Bulk Update Unit Type',
    bulkUpdate: 'Bulk Update',
    leaseStyleUpdatedSuccessfully: 'Lease style updated successfully.',

    // sidebar nav
    overview: 'Overview',
    otherFeatures: 'Other Features',
    documents: 'Documents',
    communications: 'Communications',
    deliquentTenant: 'Deliquent Tenant',
    list: 'List',
    reprintDocument: 'Reprint Document',
    consolidated: 'Consolidated',

    // Printer
    changePrinter: 'Change Printer',
    printerDetails: 'Printer Details',
    printerLabel: 'Select Printer',
    printerSelectionNoteUnavailable: 'Note: Selected printer is unavailable select a different one to proceed',
    printerSelectionNoteAvailable: 'Note: Selected printer is available.',

    changeTenant: 'Change Tenant',
    markOptionalField: '(Mark show optional fields checkbox inorder to see the fields in this section.)',

    overrideCCWarning: 'You are overriding a System Level Charge Category, Updated values will be applied everywhere this Charge Category is consumed',

    removeEntry: 'Remove Entry',
    dateOfFailure: 'Date of Failure',
    reasonForFailure: 'Reason For Failure',
    paymentAttempts: 'Payment Attempts',
    attempt: 'Attempt',
    filter: 'Filter',
    declinedCardRemovedSuccessfully: 'Declined Card(/s) removed successfully!',
    unableToRemoveDeclinedCardEntry: 'Unable to remove declined card entry!',
    removeDeclinedCardEntry: 'Remove Declined Card Entry',
    removeCardEntryFromList: `Are you sure you want to remove the following card entry 
    from the list of failed automatic credit card declines?`,
    viewDeclinedLetter: 'View Declined Letter',
    letterWasnotGenerated: "Letter wasn't generated!",
    noDeclinedCreditCards: 'There are no declined credit cards.',
    acceptedCsvFormat: 'Accepted formats .csv and file size should be lesser than {} mb',
    importTenants: 'Import Tenants',
    importUnits: 'Import Units',
    unitDetailSuccess: 'Units Details Saved Successfully',
    unitDetailError: 'Failed to Save Tenant Details',

    enterEmailHerePrefix: 'Enter Email Prefix Here',
    enterEmailPrefix: 'Customize email prefix',
    importFailedHeaderValidation: 'Headers are not valid, Please pass valid headers',
    manuallyImport: 'Manually add',

    configurationDeactivatePermission: 'Users can deactivate configs',
    configurationDeactivatePermissionLabel: 'If this is turned on users will be able to view the deactivate buttons in the configurations wherever applicable.',
    createOrEditConfigs: 'Create Or Edit General Configurations',
    createOrEditConfigsLabel: 'Users should be able to modify facility general/ financial general/ quick books/ tax exempt category/ insurance/ maintenance events/ retail sale related configurations.',
    financialsConfigLevel: 'Users can create/ modify card processor, payment, invoice and allocation order related configurations.',
    financialGrouping: 'Financial Grouping',
    financialGroupingLabel: 'Users can create/ modify charge category, payment method, tax code related configurations.',
    unitTypeConfigLabel: 'Users can create/modify unit type and bulk updated related configurations',
    tfwConfigPerm: 'Tenant facing website configs',
    tfwConfigPermLabel: 'Users can create/ modify tfw related configurations.',
    custRelatedPerm: 'Customer related information',
    custRelatedPermLabel: 'Users can update the tenant required fields and customer deposits',
    occupancyPermLabel: 'Users can update rental general, move in, move out, reservation, transfer and promo plan related configurations',
    delinquencyAndGates: 'Delinquency & Gates',
    delinquencyAndGatesLabel: 'Users can create/ modify delinquency and gate related configurations',
    letterPermLabel: 'Users can add/update letter modifications',
    letterConfigPerm: 'Letter',
    delinquencyTracking: 'Delinquency Tracking',
    selectFacilityToProceed: 'Please select a facility to proceed',
    chargedDate: 'Charged Date',
    arID: 'AR ID',
    delinquencyEvents: 'Delinquency Events',
    facilityType: 'Facility Type',
    selectGateVendors: 'Select gate vendors',
    nonAnniversary: 'Non-Anniversary',
    searchFor: 'Search For',
    delinquencyOverview: 'Delinquency Overview',
    noDaysLate: 'No. Days Late',
    lateBillSince: 'Late Bill Since',
    eventApplied: 'Event Applied',
    appliedDate: 'Applied Date',
    automatic: 'Automatic',
    syncEvents: 'Sync Events',
    schedulerCreate: 'Create Scheduler',
    configuredEvents: 'Configured Events',
    exemptedFrom: 'Exempted From',
    selectExemptedFrom: 'Select Exempted From',
    tenantStatus: 'Tenant Status',
    tenantInactiveReservationCancellation: 'Mark tenant active to cancel',
    next30Days: 'next 30 days',
    searchTenantForScheduling: 'Select tenant for scheduling',
    schedulerCreateInfo: 'Scheduler will be created only if the charges are created on the unit',
    lateBillSinceDate: 'Late Bill Since Date',
    eventTrigger: 'Event / Trigger',
    daysLeftAndGraceAmount: 'No. Days Left / Grace Amount',
    schedulersCreationFailed: 'Scheduler creation failed',
    schedulersCreationSuccess: 'Schedulers created successfully',
    scheduleMissingSchedulers: 'Schedule',
    editSchedule: 'Edit Schedule',
    deleteScheduledEvent: 'Are you sure you want to delete the scheduled event?',
    schedulerUpdatedSuccessfully: 'Scheduler updated successfully',
    schedulerDeletedSuccessfully: 'Scheduler deleted successfully',
    syncingLateEvents: 'Syncing late events... This may take few minutes to refresh.',

    editEffectiveDate: 'Edit Effective Date',
    nachaUpdatedSuccessfully: 'Effective Date edited successfully',

    willNotify: "This process will take some time, we'll notify once done",
    // tenant file upload
    uploadFiles: 'Upload Files',
    viewFiles: 'View Files',
    uploadOrViewFiles: 'Upload/View Files',
    acceptedPdfFormat: 'Accepted format is .pdf only and file must be lesser than {} mb',
    fileName: 'FILE NAME',
    uploadedDate: 'UPLOADED DATE',
    fileSize: 'FILE SIZE',
    deletePhotoMessage: 'Are you sure you want to delete the file?',
    yesDelete: 'YES, DELETE',
    addDocuments: 'Add documents',
    exceededFileLimit: 'Exceeded file limit. A maximum of 10 files can be uploaded for a tenant.',
    exceededFileSize: 'File size too large. Please upload file(s) of size smaller than {} mb',
    mb: 'Mb',
    emailAudit: 'Email Audit',
    backToConversations: 'Back to conversations',
    emptyEmailAudit: 'No Emails Found',
    timeStamp: 'Time Stamp',
    hasAttachment: 'Has Attachment',
    journal: 'Journal',
    modifiedEffectiveDate: 'Modified Effective Date',
    searchConfiguration: 'Search Configuration',
    config: 'Config',
    modifiedBy: 'Modified By',
    newValueJournal: 'New Value',
    oldValueJournal: 'Old Value',
    journalListEmpty: 'No Journal Found',
    searchDescription: 'Search Description',
    delinquencyId: 'Delinquency ID',
    unitOverlock: 'Unit Overlock',
    selectUserRole: 'Select User Role',
    selectUser: 'Select User',
    roleUpdatedText: 'Role Updated',
    additionalEmails: 'Additional Emails',
    additionalEmailConfiguredMsg: 'Additional emails would be sent only if enable email is configured',
    additionalEmailCommunicationMsg: 'Except lease every other communication emails would be sent to all the configured emails',
    additionalEmailDuplicateCheckMsg: 'Duplicates check will be skipped for the additional emails',
    primaryEmailInAdditionalEmailErrorMsg: 'Primary email address can\'t be used as an additional email',
    cc: 'Cc',
    viewEmail: 'View Email',

    // Configuration Tabs
    billingAndAllocation: 'Billing & Allocation',
    thirdPartySupport: '3rd Party Support',
    billingSettings: 'Billing settings',
    selectRentAdjustmentId: 'select Rent Adjustment Id',

    // API key related
    showApiKey: 'Show API Key',
    hideApiKey: 'Hide API Key',
    regenerateApiKey: 'Regenerate API Key',
    apiKeyRegeneratedSuccessfully: 'API Key regenerated successfully',

    violations: 'Violations',
    violationConfiguration: 'Violation Configuration',
    noViolationFound: 'No Violation Found',
    processViolations: 'Process violations',
    violationCreated: 'Violation Created',
    configureViolations: 'Please Configure Violations',
    selectViolations: 'Select the relevant violations',

    // Bulk deposits update
    bulkDepositUpdate: 'Bulk Deposit Update',
    currentDeposit: 'Current Deposit',
    updatedDeposit: 'Updated Deposit',
    enterNewDeposit: 'Enter New Deposit',
    depositsUpdated: 'Deposits have been updated',
    searchForUnit: 'Search for unit',
    searchForTenant: 'Search for tenant',

    // otherbatchpaymentmethods
    otherBatchPaymentFormTitle: 'Batch Payment',
    amountPaying: 'Amount Paying($)',
    payInFull: 'Pay in full for selected tenants',

    // vehicle
    registrationExpiresBetween: 'Registration Expires between',
    insuranceExpiresBetween: 'Insurance Expires between',
    eligibleDocumentsToBeDelivered: 'Eligible Documents to be Delivered',
    noDocumentsToDeliver: 'No documents to deliver',
    deliverDocuments: 'Deliver documents',
    deliverRegistrationExpiryLetter: 'Deliver Registration Expiry Letter',
    deliverInsuranceExpiryLetter: 'Deliver Insurance Expiry Letter',
    insuranceExpiryDate: 'Insurance Expiry Date',
};

export default localisable;
