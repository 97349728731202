import FiltersAndSorts from 'Commons/components/business/filtersAndSorts/FiltersAndSorts';
import Typography from 'Generic/typography/components/Typography';
import SmartListActionItemsPanel
from 'Commons/components/business/smartlistActionItemsPanel/components/SmartListActionItemsPanel';
import { Grid, Loader } from 'Commons/components/generic/componentlibrary/components/Components';
import Tooltip from 'Commons/components/generic/tooltip/components/Tooltip';
import { TABLE_STATE, TIMEOUT, WS_EVENT_TYPE } from 'Commons/config/constants/Constants';
import STATUS from 'Commons/config/constants/StoreKeyStatus';
import { convertCamelCaseToWords, deepMerge, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import Button from 'Generic/button/components/Button';
import { VARIANT as SNACKBAR_VARIANT } from 'Generic/snackbar/config/Constants';
import Table from 'Generic/table';
import { isLiveData } from 'Commons/helpers/utils/Utils';
import RequestTypes from '../../../../config/constants/RequestTypes';
import { screenMaxWidth } from '../../../../config/constants/ScreenWidths';
import localisable from '../../../../config/strings/localisable';
import SideBarContext from '../../../../contexts/SideBarContext';
import { cleanseTheFilters, constructFilterRequest } from '../../../../helpers/utils/SmartListDataHelper';
import Snackbar from '../../../generic/snackbar/components/Snackbar';
import TablePaper from '../../Papers/components/TablePaper';
import AppliedFilterContainer from '../../appliedFilterContainer/component/AppliedFilterContainer';
import BaseComponent from '../../basecomponent/components/BaseComponent';
import { getStoreKeyToClear } from '../../basecomponent/config/Utils';
import allSortConfig from '../../filtersAndSorts/config/SortConfig';
import QuickFilters from '../../quickfilters/components/QuickFilters';
import getConfig from '../config/ActionConfig';
import {
    ACTION_ITEMS_PANEL_HEIGHT,
    CAN_I_SHOW_CONFIG,
    FILTERS_AND_SORTS_HEIGHT,
    LAPTOP_PAGE_TOP_MARGIN,
    MAIN_CONTENT_PADDING,
    MOBILE_MAIN_CONTENT_PADDING,
    MOBILE_PAGE_TOP_MARGIN,
    PAGE_TOP_MARGIN,
    SEARCH_TYPE,
    SIDEBAR_CLOSE_EXTRA_SPACE,
    SIDEBAR_OPEN_EXTRA_SPACE,
    STATUS_TO_UPDATE_ON,
    TABLET_MAIN_CONTENT_PADDING,
    TABLE_HEADER_CONTENT_MARGIN,
} from '../config/Constants';
import { SmartListDefaultProps, SmartListProps } from '../config/SmartListProps';
import { getAppliedAutoSuggest } from '../utils/utils';

class SmartList extends BaseComponent {
    constructor(props) {
        super(props);
        this.listConfig = {};
        const {
            filterProps: { sectionsConfig: { filtersActive = {} } = {} } = {}, name,
            defaultSort: { sort } = {}, defaultSort, store, source,
            autoSuggestKeys = [], autoSuggestKey = 'autoSuggest',
        } = this.getListConfig();
        const {
            fetchLazy, [store]: {
                filter: [allFilters = {}] = [],
                sort: sortInApiCall,
            } = {},
        } = this.props;
        const {
            appliedAutoSuggest,
            appliedFilters,
        } = getAppliedAutoSuggest(autoSuggestKeys, autoSuggestKey, allFilters);
        this.state = {
            showFilterOverlay: false,
            filtersActive,
            receivedWSMessage: false,
            wsMessage: {},
            appliedFilters,
        };
        this.status = STATUS.UNLOADED;
        this.sortConfig = sortInApiCall || sort;
        this.initialSort = sortInApiCall ? this.getInitialSort(sortInApiCall, source) : defaultSort;
        this.filtersKey = 0;
        if (appliedAutoSuggest) {
            this.searchValue = appliedAutoSuggest;
            this.initialSearchValue = appliedAutoSuggest;
        }
        this.setCurDevice();
        const { withProvider } = window;
        this.SmartFilterOverlay = withProvider(`${name}Filter`); // Ensure your Filter is available in Loaders.js
        if (fetchLazy) {
            this.fetchList(fetchLazy);
        }
        this.resetFilters = React.createRef();
        this.refetchOnUnload = true;
    }

    handleWsMessage = (message = {}) => {
        this.setState({ wsMessage: message });
        const { resourceName } = message;
        const { listConfig: { store } = {}, props: { clearStoreKeys, storesToNotClearSilently = [] } = {} } = this;
        if (resourceName !== store) {
            const storeKey = getStoreKeyToClear(message, storesToNotClearSilently);
            clearStoreKeys([storeKey]);
        }
        this.onWebSocketMessage(message);
    }

    onWebSocketMessage = (message = {}) => {
        const { resourceName, eventType } = message || {};
        const {
            store,
            rtnsSnackbarProps: { shouldSetRTNSSnackbarFunc, createMessage, updateMessage } = {},
        } = this.listConfig;
        if ((shouldSetRTNSSnackbarFunc && shouldSetRTNSSnackbarFunc(resourceName, message)) || resourceName === store) {
            this.setSnackbarProps(true,
                eventType === WS_EVENT_TYPE.CREATED
                    ? createMessage || localisable.wsCreateMessage
                    : updateMessage || localisable.wsUpdateMessage,
                SNACKBAR_VARIANT.rtns.value);
            this.setState({ receivedWSMessage: true }, () => { this.state.receivedWSMessage = false; });
        }
    }

    onRefresh = () => {
        const { props: { clearStoreKeys = () => { } } = {}, listConfig: { store } = {} } = this;
        clearStoreKeys([store]);
    }

    componentDidMount() {
        const { rtnsSnackbarProps: { setOnWebSocketMessage, setOnRefresh } = {} } = this.listConfig;
        if (setOnWebSocketMessage) {
            setOnWebSocketMessage(this.onWebSocketMessage);
        }
        if (setOnRefresh) {
            setOnRefresh(this.onRefresh);
        }
    }

    componentWillUnmount() {
        const { clearStoreKeyOnUnmount = false } = this.props;
        if (clearStoreKeyOnUnmount) this.clearStoreKey();
    }

    getInitialSort = (sortInApiCall, source) => {
        let initialSortConfig = {};
        const currentSortConfig = allSortConfig[source] || [];
        // Get label,value list for sort dropdown
        currentSortConfig.forEach((config = {}) => {
            const { sort } = config;
            if (JSON.stringify(sort) === JSON.stringify(sortInApiCall)) {
                initialSortConfig = config;
            }
        });
        return initialSortConfig;
    }

    onApplyFilter = (filters) => {
        this.clearStoreKey();
        // TODO: It will override the normal filters when called from quick filters, fix it
        this.setState({ appliedFilters: filters }, () => {
            this.fetchData(SEARCH_TYPE.SEARCH, RequestTypes.REFRESH, this.resetRefetchOnUnload);
        });
    }

    fetchList(fetchLazy) {
        const {
            apiConfig, resourceName, subProcessor, isDataReversed, includeRestricted,
            preProcessInitialFilters, applyInitialFilter = true,
        } = this.listConfig;
        const { sortConfig } = this;
        const filters = this.getFilters(true);
        if (preProcessInitialFilters) preProcessInitialFilters(filters);
        if (isObjWithKeys(filters)) {
            // If initial filters applied
            fetchLazy('read', {
                api: {
                    ...deepMerge({
                        dynamic: {
                            body: {
                                ...(applyInitialFilter && { filter: [filters] }),
                                ...(sortConfig ? { sort: sortConfig } : {}),
                                ...(includeRestricted && { __includeRestricted: includeRestricted }),
                            },
                        },
                    }, (apiConfig || {})),
                },
                subProcessor,
                isDataReversed,
                ...(resourceName && { resourceName }),
            });
        } else {
            // If no filters applied
            fetchLazy('read', {
                api: { ...apiConfig /* NOTE: Temporary for Financial List page */ },
                subProcessor,
                isDataReversed,
                ...(resourceName && { resourceName }),
            });
        }
    }

    setCurDevice() {
        const { deviceInfo: { isDesktop = false, isMobile = false, isPhablet = false, isTablet = false } } = window;
        const curDeviceConfig = {
            isDesktop,
            isMobile,
            isPhablet,
            isTablet,
        };
        // eslint-disable-next-line consistent-return
        Object.keys(curDeviceConfig).forEach((key) => {
            if (curDeviceConfig[key]) {
                this.curDevice = key;
            }
        });
    }

    clearFiltersFromApi = () => {
        const { defaultSort: { sort } = {}, defaultSort } = this.getListConfig();
        this.searchValue = '';
        this.initialSearchValue = '';
        this.sortConfig = sort;
        this.initialSort = defaultSort;
        this.state.appliedFilters = {};
    }

    componentDidUpdate() {
        const { store } = this.getListConfig();
        const { fetchLazy, [store]: { status = STATUS.UNLOADED } = {}, getAutosuggest } = this.props;
        if (status === STATUS.UNLOADED && this.refetchOnUnload) {
            if (fetchLazy) {
                this.clearFiltersFromApi();
                this.filtersKey = !this.filtersKey;
                this.fetchList(fetchLazy);
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ appliedFilters: {} });
            }
        }
        if (getAutosuggest) {
            getAutosuggest(this.onAutosuggest);
        }
    }

    renderError() {
        const { name = '' } = this.listConfig;
        return (
            <div>
                <p>
                    {`${name} List Error`}
                </p>
            </div>
        );
    }

    renderLoading() {
        const { name = '' } = this.listConfig;
        return (
            <div>
                <p>
                    {`${name} List Loading...`}
                </p>
            </div>
        );
    }

    /**
     * @name canIShow
     * @description Determines whether a component can be shown on a device
     */
    canIShow = (componentName = '') => {
        const curComponentConfig = CAN_I_SHOW_CONFIG[componentName];
        if (curComponentConfig) {
            return curComponentConfig[this.curDevice];
        }
        return false;
    }

    getFilters = (useInitialFilters = false) => {
        const { defaultFilters = {}, initialFilters = {}, cleansFilter = true } = this.listConfig;
        const { appliedFilters = {} } = this.state;
        const filters = {
            ...defaultFilters,
            ...appliedFilters,
            ...useInitialFilters && initialFilters,
        };
        return cleansFilter ? cleanseTheFilters(filters) : filters;
    }

    getFieldName = (name) => {
        const delimiter = '.';
        return name.includes(delimiter) ? name.split(delimiter) : name;
    };

    setIn = (filter, autoSuggestKey) => {
        let autoSuggest = filter;
        const fieldNames = this.getFieldName(autoSuggestKey);
        let lastField = autoSuggestKey;

        if (Array.isArray(fieldNames)) {
            lastField = fieldNames.pop();
            fieldNames.forEach((fieldName) => {
                autoSuggest[fieldName] = {};
                autoSuggest = autoSuggest[fieldName];
            });
        }
        autoSuggest[lastField] = this.searchValue;
    };

    /**
     * @name fetchData
     * @param searchType Source as it is pagination or search or autosuggest
     * @param requestType check for request type as init or refresh or pagination
    */
    fetchData = (searchType, requestType, callback) => {
        const {
            subProcessor, resourceName, isDataReversed, autoSuggestKey = 'autoSuggest',
            store, searchUrl, includeRestricted, useAccountContext = false, view,
        } = this.listConfig;
        const {
            onAction,
            [store]: {
                data: {
                    data = [],
                    actualRows = 0, // Use this to make next pagination call if table rows are different than actual data
                } = {},
            } = {},
            postProcessOnAction,
        } = this.props;
        const { sortConfig } = this;
        const filters = this.getFilters();
        let options = {};
        const filter = {};
        this.setIn(filter, autoSuggestKey);
        const resultantFilter = {
            ...Object.keys(filter).length > 0 && filter,
            ...Object.keys(filters).length > 0 && filters,
        };
        switch (searchType) {
            case SEARCH_TYPE.AUTOSUGGEST: {
                options = {
                    store,
                    sortConfig,
                    ...(Object.keys(resultantFilter).length > 0 && { filters: [resultantFilter] }),
                    requestType,
                    endPoint: searchUrl,
                };
                break;
            }
            case SEARCH_TYPE.PAGINATION: {
                options = {
                    store,
                    sortConfig,
                    ...(Object.keys(filters).length && { filters: [filters] }),
                    start: actualRows || data.length,
                    requestType,
                };
                if (Object.keys(filter).length > 0 && this.searchValue && this.searchValue.length > 0) {
                    options.endPoint = searchUrl;
                    options.filters = [resultantFilter];
                }
                break;
            }
            default: {
                options = {
                    store,
                    sortConfig,
                    ...(Object.keys(filters).length && { filters: [filters] }),
                    requestType,
                };
                if (Object.keys(filter).length > 0 && this.searchValue && this.searchValue.length > 0) {
                    options.endPoint = searchUrl;
                    options.filters = [resultantFilter];
                }
                break;
            }
        }
        if (includeRestricted) {
            // eslint-disable-next-line no-underscore-dangle
            options.__includeRestricted = includeRestricted;
        }
        if (callback) options.callback = callback;
        if (view) options.view = view;
        const onActionConfig = getConfig(options, useAccountContext);
        const config = postProcessOnAction(onActionConfig) || onActionConfig;
        const updatedConfig = [];
        config.forEach((item) => {
            updatedConfig.push({ ...item, subProcessor, isDataReversed, ...(resourceName && { resourceName }) });
        });
        onAction({ config: [...updatedConfig] });
    }

    fetchMoreRows = (onFetchMoreRows) => {
        const { store } = this.listConfig;
        const { [store]: { data: { totalCount, data = [] } = {} } = {} } = this.props;
        if (data.length < totalCount) {
            this.fetchData(SEARCH_TYPE.PAGINATION, RequestTypes.PAGINATE, onFetchMoreRows);
        }
    }

    shouldTableUpdate = () => {
        const { store } = this.listConfig;
        const { [store]: { status = STATUS.UNLOADED } = {} } = this.props;
        const shouldTableUpdate = this.listConfigChanged || (((status !== undefined) && (status !== null))
            ? ((this.listStoreStatus !== status) && (STATUS_TO_UPDATE_ON.includes(status))) : false);
        this.listStoreStatus = status;
        return shouldTableUpdate;
    }

    emptyBodyRenderer = () => {
        const { emptyBodyText = localisable.noDataFound } = this.listConfig;
        return emptyBodyText;
    };

    loadingBodyRenderer = () => {
        const { loadingBodyText = <Loader /> } = this.listConfig;
        return loadingBodyText;
    };

    errorRenderer = () => <Typography color="error" variant="subtitle1">{localisable.somethingWentWrong}</Typography>

    emptyOrLoadingBodyRenderer = () => {
        const { store } = this.listConfig;
        const { [store]: { status } = {} } = this.props;
        const { loadingBodyRenderer, emptyBodyRenderer } = this;
        if (status && (status === STATUS.ERROR || status === STATUS.INVALID)) {
            return this.errorRenderer;
        }
        return status && (status !== STATUS.INVALID && status < STATUS.LOADED)
            ? loadingBodyRenderer : emptyBodyRenderer;
    }

    createListForm = () => {
        const { history } = this.props;
        const { formatterProps: { openForm } = {}, createUrl } = this.listConfig;
        if (openForm) {
            openForm();
        } else {
            history.push(createUrl);
        }
    }

    setFilterOverlay = (status = false) => {
        this.setState({ showFilterOverlay: status });
    }

    getTablePaperHeaderRef = (ref) => {
        this.tablePaperHeaderRef = ref;
        this.forceUpdate();
    }

    calculateTableWidthAndHeight = (isSidebarOpen) => {
        const { tablePaperHeaderRef: { current: tablePaperHeaderRef } = {} } = this;
        const tablePaperHeaderHeight = tablePaperHeaderRef ? tablePaperHeaderRef.clientHeight : 0;
        const { innerWidth, innerHeight, deviceInfo: { isDesktop } } = window;
        let topMargin = MOBILE_PAGE_TOP_MARGIN;
        let mainContentPadding = MOBILE_MAIN_CONTENT_PADDING;
        if (innerWidth > screenMaxWidth.phablet) {
            topMargin = LAPTOP_PAGE_TOP_MARGIN;
            mainContentPadding = TABLET_MAIN_CONTENT_PADDING;
        }
        if (innerWidth > screenMaxWidth.laptop) {
            topMargin = PAGE_TOP_MARGIN;
            mainContentPadding = MAIN_CONTENT_PADDING;
        }

        const {
            actionItemsPanelProps: {
                showBackToDashBoardActionItem = false,
                actionItemsConfig: actionItemsPanelConfig = [],
            } = {}, footerHeight = 0, tableProps: { showListPageRibbon = false, isConfigurationList = false } = {},
        } = this.listConfig;
        const showActionItemsPanel = showBackToDashBoardActionItem || !!actionItemsPanelConfig.length;

        const originalTableHeight = innerHeight - topMargin - tablePaperHeaderHeight - TABLE_HEADER_CONTENT_MARGIN
            - FILTERS_AND_SORTS_HEIGHT - mainContentPadding
            - (showActionItemsPanel ? ACTION_ITEMS_PANEL_HEIGHT : 0) - footerHeight;
        let tableHeight = originalTableHeight;
        if (showListPageRibbon && !isConfigurationList) {
            tableHeight = originalTableHeight - 36;
        }
        const tableWidth = !isDesktop
            ? screenMaxWidth.desktop
            : (innerWidth - (isSidebarOpen
                ? SIDEBAR_OPEN_EXTRA_SPACE : SIDEBAR_CLOSE_EXTRA_SPACE) - (mainContentPadding * 2));
        const tableHeaderWidth = (innerWidth - (isSidebarOpen ? SIDEBAR_OPEN_EXTRA_SPACE : SIDEBAR_CLOSE_EXTRA_SPACE));

        return {
            tableHeight,
            tableWidth,
            tableHeaderWidth,
        };
    }

    getTablePaperTitle = (typographyVariant = 'h5', toUpperCase = true, titleFontFamily, spacing, disableAdd) => {
        const { name, createUrl, store, createName = null, formatterProps: { openForm } = {} } = this.listConfig;
        const typographyProps = { ...(titleFontFamily && { fontFamily: titleFontFamily }) };
        const { deviceInfo: { isDesktop } } = window;
        return (
            <Grid container spacing={spacing} alignItems="center">
                <Grid item>
                    <Typography
                        variant={typographyVariant}
                        {...typographyProps}
                    >
                        {toUpperCase ? name.toUpperCase() : name}
                    </Typography>
                </Grid>
                {
                    (createUrl || openForm) && (
                        <Grid container item style={{ width: 'auto' }}>
                            {/* eslint-disable-next-line react/no-this-in-sfc */}
                            <Tooltip
                                title={`${localisable.create} ${convertCamelCaseToWords(createName || store)}`}
                                disablePortal={!isDesktop}
                            >
                                <Button
                                    variant="icon"
                                    icon="cp-add"
                                    iconType="custom"
                                    color="primary"
                                    disabled={disableAdd}
                                    onClick={this.createListForm}
                                />
                            </Tooltip>
                        </Grid>
                    )
                }
            </Grid>
        );
    }

    openFilterOverlay = () => {
        this.setState({ showFilterOverlay: true });
    }

    getTablePaperProps = (typographyVariant, toUpperCase, titleFontFamily, spacing) => {
        const { name, filterProps, tablePaperProps: { disableAdd = false } = {} } = this.listConfig;
        return {
            title: name ? this.getTablePaperTitle(typographyVariant, toUpperCase, titleFontFamily, spacing, disableAdd) : '',
            searchPlaceHolder: `Search in ${name} List`,
            onFilterClick: this.openFilterOverlay,
            showFilter: !!filterProps,
        };
    }

    getSectionByFilter = (filter = '', availableFilters = {}) => {
        if (availableFilters[filter]) {
            const { sections: [sectionName] = [] } = availableFilters[filter];
            return sectionName || null;
        }
        return null;
    }

    /**
     * @name onFilterChange Update filters
     * @param updatedFilters Currently active filters
     * @param options.apiCall Should you make API call on change of filter
     */
    onFilterChange = (updatedFilters, { apiCall } = { apiCall: true }) => {
        this.setState({ filtersActive: cleanseTheFilters(updatedFilters) });
        if (apiCall) {
            this.fetchData(SEARCH_TYPE.SEARCH, RequestTypes.REFRESH);
        }
    }

    onFilterClicked = (filter) => {
        const { filterProps: { sectionsConfig: { availableFilters, sections } } } = this.listConfig;
        const section = this.getSectionByFilter(filter, availableFilters);
        if (section) {
            this.sectionClicked = sections[section] || {};
            this.setState({ showFilterOverlay: true });
        }
    }

    resetRefetchOnUnload = () => {
        this.refetchOnUnload = true;
    }

    clearStoreKey = () => {
        const { clearStoreKeys } = this.props;
        const { store } = this.listConfig;
        this.refetchOnUnload = false;
        clearStoreKeys([store]);
    }

    onAutosuggest = (event = {}) => {
        const { target: { value: inputValue } = {} } = event;
        const { onAutoSuggestTextFieldChange } = this.props;
        const { disableDebouncing } = this.getListConfig();
        onAutoSuggestTextFieldChange(inputValue);
        this.searchValue = inputValue;
        if (!disableDebouncing) {
            if (inputValue) {
                if (inputValue !== this.prevInputValue) {
                    if (this.timeout) clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        this.clearStoreKey();
                        this.fetchData(SEARCH_TYPE.AUTOSUGGEST, RequestTypes.REFRESH, this.resetRefetchOnUnload);
                    }, TIMEOUT);
                }
            } else {
                if (this.timeout) clearTimeout(this.timeout);
                this.clearStoreKey();
                this.fetchData(SEARCH_TYPE.SEARCH, RequestTypes.REFRESH, this.resetRefetchOnUnload); // Get the tenant list without any filter
            }
        }
        this.prevInputValue = inputValue;
    }

    onSortChange = (sort) => {
        this.clearStoreKey();
        this.sortConfig = sort;
        this.fetchData(SEARCH_TYPE.SEARCH, RequestTypes.REFRESH, this.resetRefetchOnUnload);
    }

    areFiltersApplied = (filters = {}) => {
        const filteredFilters = Object.keys(filters).filter(filter => !filters[filter].hidden);
        return Object.keys(filteredFilters).length > 0;
    }

    renderFilterOverlay() {
        const { showFilterOverlay, filtersActive: stateFiltersActive } = this.state;
        const { name = '' } = this.listConfig;
        const { SmartFilterOverlay } = this;
        return (
            <SmartFilterOverlay
                setFilterOverlay={this.setFilterOverlay}
                showFilterOverlay={showFilterOverlay}
                createFilterRequest={constructFilterRequest}
                onFilterChange={this.onFilterChange}
                appliedFilters={stateFiltersActive}
                sectionClicked={this.sectionClicked}
                getSectionByFilter={this.getSectionByFilter}
                device={this.curDevice}
                listName={`${name} List`}
                listConfig={this.listConfig}
                {...this.props}
            />
        );
    }

    getListConfig = () => {
        const { listConfig, currentFacility } = this.props;
        const currentListConfig = typeof listConfig === 'function' ? listConfig(currentFacility) : listConfig;
        if (this.listConfig !== currentListConfig) {
            this.listConfig = currentListConfig;
            this.listConfigChanged = true;
        } else {
            this.listConfigChanged = false;
        }
        return this.listConfig;
    }

    getResetFilter = (resetFilters) => {
        this.resetFilters.current = resetFilters;
    }

    handleOnBackToDashboard = () => {
        const { props: { history } = {} } = this;
        history.push('');
    }

    renderComponent() {
        const { permission, currentFacility, match: { url } = {} } = this.props;
        const {
            source,
            store,
            columns,
            tableProps,
            tablePaperProps = {},
            getCustomData,
            filterProps,
            showFinancialTable, // NOTE: Extra prop added just to know its for financial table (temporary)!
            formatterProps,
            filtersAndSortsProps: { prefilledSearchValue, ...filtersAndSortsProps } = {},
            showFilterAndSorts = false,
            showCount = true,
            defaultFilters = {},
            actionItemsPanelProps,
            quickFilters = [],
            quickFilterProps = {},
            actionItemsPanelProps: {
                showBackToDashBoardActionItem = false,
                actionItemsConfig: actionItemsPanelConfig = [],
            } = {},
        } = this.getListConfig();
        const { filtersActive: stateFiltersActive } = this.state;
        const { typographyVariant, toUpperCase, titleFontFamily, spacing = 1, headerRowHeight } = tablePaperProps;
        const { initialSort = {}, initialSearchValue, filtersKey, refetchOnUnload } = this;
        const cleansedFilters = cleanseTheFilters(stateFiltersActive);
        const {
            [store]: {
                data: storeOuterData = {},
                status,
            } = {},
            chargeCategory, paymentMethod, onAction, clearStoreKeys, // NOTE: Only for financial table
        } = this.props;
        const { totalCount, data: storeData = [], relationalData = {} } = storeOuterData || {};
        const { showFilterOverlay } = this.state;
        let tableData = storeData;
        if (getCustomData) {
            tableData = getCustomData(this.props);
        }
        const state = status === STATUS.LOADING ? TABLE_STATE.LOADING : undefined;
        const showActionItemsPanel = showBackToDashBoardActionItem || !!actionItemsPanelConfig.length;
        const defaultTablePaperProps = this.getTablePaperProps(typographyVariant, toUpperCase,
            titleFontFamily, spacing);
        return (
            <SideBarContext.Consumer>
                {(isSidebarOpen) => {
                    const {
                        tableWidth,
                        tableHeight,
                        tableHeaderWidth,
                    } = this.calculateTableWidthAndHeight(isSidebarOpen);
                    return (
                        <>
                            {
                                showFinancialTable
                                    ? (
                                        <Table
                                            state={state}
                                            data={tableData}
                                            columns={columns}
                                            dataGetter={this.fetchMoreRows}
                                            rowCount={totalCount}
                                            emptyOrLoadingBodyRenderer={this.emptyOrLoadingBodyRenderer()}
                                            recalculate={store && this.shouldTableUpdate()}
                                            tableHeight={tableHeight}
                                            tableWidth={tableWidth}
                                            resetScrollPosition={refetchOnUnload}
                                            formatterProps={{ chargeCategory, paymentMethod, onAction, clearStoreKeys }}
                                            {...tableProps}
                                        />
                                    )
                                    : (
                                        <TablePaper
                                            onSearchChange={this.onAutosuggest}
                                            getTablePaperHeaderRef={this.getTablePaperHeaderRef}
                                            rowCount={(tableData || []).length}
                                            {...defaultTablePaperProps}
                                            {...tablePaperProps}
                                        >
                                            {
                                                <>
                                                    {
                                                        showActionItemsPanel && (
                                                            <SmartListActionItemsPanel
                                                                onBackToDashboardClick={this.handleOnBackToDashboard}
                                                                {...actionItemsPanelProps}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        (this.canIShow('appliedFilter'))
                                                        && (this.areFiltersApplied(stateFiltersActive))
                                                        && (
                                                            <AppliedFilterContainer
                                                                data={cleansedFilters}
                                                                onFilterChange={this.onFilterChange}
                                                                onSelectChip={this.onFilterClicked}
                                                                width={tableHeaderWidth}
                                                                setFilterOverlay={this.setFilterOverlay}
                                                                status={status}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        quickFilters.length
                                                            ? (
                                                                <QuickFilters
                                                                    list={quickFilters}
                                                                    applyFilter={this.onApplyFilter}
                                                                    {...quickFilterProps}
                                                                />
                                                            )
                                                            : null
                                                    }
                                                    {
                                                        showFilterAndSorts
                                                        && (
                                                            <FiltersAndSorts
                                                                key={`filters${Number(filtersKey)}`}
                                                                source={source}
                                                                currentFacility={currentFacility}
                                                                getFilters={this.getFilters}
                                                                onApplyFilter={this.onApplyFilter}
                                                                onSearchChange={this.onAutosuggest}
                                                                onSortChange={this.onSortChange}
                                                                getResetFilter={this.getResetFilter}
                                                                defaultFilters={defaultFilters}
                                                                defaultSort={initialSort}
                                                                totalRowsInTable={totalCount}
                                                                tableHeight={tableHeight}
                                                                tableWidth={tableWidth}
                                                                headerRowHeight={headerRowHeight}
                                                                prefilledSearchValue={
                                                                    prefilledSearchValue || initialSearchValue
                                                                }
                                                                isLiveData={isLiveData(url)}
                                                                {...filtersAndSortsProps}
                                                            />
                                                        )
                                                    }
                                                    <Table
                                                        state={state}
                                                        data={tableData}
                                                        columns={columns}
                                                        dataGetter={this.fetchMoreRows}
                                                        rowCount={totalCount}
                                                        emptyOrLoadingBodyRenderer={this.emptyOrLoadingBodyRenderer()}
                                                        recalculate={this.shouldTableUpdate()}
                                                        tableHeight={tableHeight}
                                                        resetScrollPosition={refetchOnUnload}
                                                        // TODO: remove formatter props for permission once permission is a different class
                                                        formatterProps={{
                                                            permission,
                                                            relationalData,
                                                            ...formatterProps,
                                                        }}
                                                        tableWidth={tableWidth}
                                                        showTotalCount={showCount && !showFilterAndSorts}
                                                        {...tableProps}
                                                    />
                                                </>
                                            }
                                        </TablePaper>
                                    )
                            }
                            {showFilterOverlay && filterProps && this.renderFilterOverlay()}
                            {
                                status === STATUS.PAGINATE_ERROR
                                && (
                                    <Snackbar
                                        variant="error"
                                        title={localisable.somethingWentWrong}
                                    />
                                )
                            }
                        </>
                    );
                }
                }
            </SideBarContext.Consumer>
        );
    }
}

SmartList.propTypes = SmartListProps;

SmartList.defaultProps = SmartListDefaultProps;

export default SmartList;
