import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { memo } from 'react';
import { RadioGroup } from 'Generic/radiogroup/components/RadioGroup';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { BATCH_PAYMENT_MODE } from 'Commons/config/constants/Constants';
import { BATCH_PAYMENT_FILTER_TYPE } from '../config/Constants';
import CreateBatchStyle from '../styles/createBatchStyle';

const useStyles = makeStyles(CreateBatchStyle, { name: 'CreateBatchFilter' });


const CreateBatchFilter = ({ mode, formProps: { setFieldValue } = {} }) => {
    const onChange = (_, value) => {
        if (value === BATCH_PAYMENT_FILTER_TYPE.Autopay_Enabled_Tenants.value) {
            setFieldValue('terms.autoPayEnabled', [true]);
            setFieldValue(mode === BATCH_PAYMENT_MODE.CREDIT_CARD ? 'exists.token' : 'exists.achPpd', undefined);
        } else {
            setFieldValue(mode === BATCH_PAYMENT_MODE.CREDIT_CARD ? 'exists.token' : 'exists.achPpd', [true]);
            setFieldValue('terms.autoPayEnabled', undefined);
        }
    };

    const filterRadioList = useConstructor(() => Object.values(BATCH_PAYMENT_FILTER_TYPE).filter(({ value }) => value !== (mode === BATCH_PAYMENT_MODE.ACH
        ? BATCH_PAYMENT_FILTER_TYPE.Saved_Cards_With_Balance_Due.value
        : BATCH_PAYMENT_FILTER_TYPE.Saved_Accounts_With_Balance_Due.value)));

    const classes = useStyles();


    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.paymentPreference}
            </Typography>
            <RadioGroup
                list={filterRadioList}
                dataType="string"
                value={mode === BATCH_PAYMENT_MODE.ACH
                    ? BATCH_PAYMENT_FILTER_TYPE.Saved_Accounts_With_Balance_Due.value
                    : BATCH_PAYMENT_FILTER_TYPE.Saved_Cards_With_Balance_Due.value}
                onChange={onChange}
            />
        </>
    );
};

CreateBatchFilter.propTypes = { formProps: PropTypes.object, mode: PropTypes.string };

export default memo(CreateBatchFilter);
