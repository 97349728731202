import INTERNAL_ROUTES, { INTERNAL_PATHS } from 'Internal/redux/config/RouteNames';

const urls = {
    onboarding: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/onboarding`,
    tenantCreate: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/tenant/create`,
    tenantEdit: (baseUrl, { fid, id } = {}) => `${baseUrl}/${fid}/tenant/${id}/edit`,
    ledgerReview: (baseUrl, { fid, id } = {}) => `${baseUrl}/${fid}/ledger/${id}`,
    inventoryList: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/inventory/list`,
    normalConfig: (baseUrl, { level, id } = {}) => `${baseUrl}/c/${level}/${id}`,
    systemConfig: (baseUrl, { level } = {}) => `${baseUrl}/sc/${level}`,
    userList: baseUrl => `${baseUrl}/user/list`,
    userRole: baseUrl => `${baseUrl}/user-role`,
    userRoleEdit: (baseUrl, { selectedId } = {}) => `${baseUrl}/user-role/${selectedId}/edit`,
    userRoleCreate: baseUrl => `${baseUrl}/user-role/create`,
    syrasoftUserRoles: baseUrl => `${baseUrl}/${INTERNAL_ROUTES.SYRASOFT_USER_ROLES}`,
    syrasoftUserRoleEdit: (baseUrl, { selectedUserType } = {}) => `${baseUrl}/${INTERNAL_ROUTES.SYRASOFT_USER_ROLES}/${selectedUserType}/edit`,
    accountsList: baseUrl => `${baseUrl}/${INTERNAL_ROUTES.ACCOUNT_LIST}`,
    dailySummaryList: baseUrl => `${baseUrl}/${INTERNAL_ROUTES.DAILY_SUMMARY_LIST}`,
    autoPayTaskSummary: (baseUrl, { summaryId }) => `${baseUrl}/${INTERNAL_PATHS.AUTO_PAYMENT}/${summaryId}/summary`,
    rentAssessmentTaskSummary: (baseUrl, { summaryId }) => `${baseUrl}/${INTERNAL_PATHS.RENT_ASSESSMENT}/${summaryId}/summary`,
    autoInvoicingTaskSummary: (baseUrl, { summaryId }) => `${baseUrl}/${INTERNAL_PATHS.AUTO_INVOICING}/${summaryId}/summary`,
    delinquencyTaskSummary: (baseUrl, { summaryId }) => `${baseUrl}/${INTERNAL_PATHS.DELINQUENCY}/${summaryId}/summary`,
    accountManagement: baseUrl => `${baseUrl}/${INTERNAL_ROUTES.ACCOUNT_LIST}`,
    businessGroupManagement: (baseUrl, { accountId }) => `${baseUrl}/account/${accountId}/bg/list`,
    tenantView: (baseUrl, { fid, id } = {}) => `${baseUrl}/${fid}/tenant/${id}/view`,
    bevView: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/bev/view`,
    bevSetup: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/bev/setup`,
    reprint: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/reprint`,
    generateDocuments: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/generate-documents`,
    reservationList: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/reservation/list`,
    waitingList: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/waiting/list`,
    unitEdit: (baseUrl, { fid, id }) => `${baseUrl}/${fid}/unit/${id}/edit`,
    unitCreate: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/unit/create`,
    moveIn: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/movein`,
    moveOut: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/moveout`,
    estimate: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/estimate`,
    reservation: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/reservation`,
    editReservation: (baseUrl, { fid, reservationId } = {}) => `${baseUrl}/${fid}/reservation/${reservationId}/edit`,
    createWaiting: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/waiting`,
    editWaiting: (baseUrl, { fid, waitingId } = {}) => `${baseUrl}/${fid}/waiting/${waitingId}/edit`,
    leadEdit: (baseUrl, { fid, id }) => `${baseUrl}/${fid}/lead/${id}/edit`,
    userCreate: baseUrl => `${baseUrl}/user/create`,
    settings: (baseUrl, { source, id } = {}) => `${baseUrl}/${source}/${id}`,
    mainEdit: (baseUrl, { source, fid, id } = {}) => `${baseUrl}/${fid}/${source}/${id}/edit`,
    settingsEdit: (baseUrl, { source, id } = {}) => `${baseUrl}/${source}/${id}/edit`,
    createNormalConfigForm: (baseUrl, { selectedLevel, selectedEntity, formName } = {}) => `${baseUrl}/c/${selectedLevel}/${selectedEntity}/${formName}`,
    createSystemConfigForm: (baseUrl, { selectedLevel, formName } = {}) => `${baseUrl}/sc/${selectedLevel}/${formName}`,
    editNormalConfigForm: (baseUrl, { selectedLevel, selectedEntity, formName, targetId } = {}) => `${baseUrl}/c/${selectedLevel}/${selectedEntity}/${formName}/${targetId}`,
    editSystemConfigForm: (baseUrl, { selectedLevel, formName, targetId } = {}) => `${baseUrl}/sc/${selectedLevel}/${formName}/${targetId}`,
    normalConfigSubForm: (baseUrl, { selectedLevel, subFormName } = {}) => `${baseUrl}/c/${selectedLevel}/${subFormName}`,
    configSelection: (baseUrl, { fid, configType, id } = {}) => `${baseUrl}/c/facility/${fid}/${configType}/${id}`,
    configTarget: (baseUrl, { level, id, targetConfig } = {}) => `${baseUrl}/c/${level}/${id}/${targetConfig}`,
    configTargetView: (baseUrl, { level, targetConfig } = {}) => `${baseUrl}/c/${level}/${targetConfig}`,
    facilitySelect: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}`,
    configurationSelect: (baseUrl, { fid } = {}) => `${baseUrl}/c/facility/${fid}`,
    facilityFeatures: (baseUrl, { fid, feature } = {}) => `${baseUrl}/${fid}/${feature}`,
    facilityCreateFeature: (baseUrl, { fid, feature } = {}) => `${baseUrl}/${fid}/${feature}/create`,
    facilityListFeature: (baseUrl, { fid, feature }) => `${baseUrl}/${fid}/${feature}/list`,
    facilityPos: (baseUrl, { fid }) => `${baseUrl}/${fid}/pos`,
    maintenanceEventForm: (baseUrl, { fid, operation, form } = {}) => `${baseUrl}/${fid}/${operation}/${form}`,
    maintenanceEventFormEdit: (baseUrl, { fid, operation, form, id } = {}) => `${baseUrl}/${fid}/${operation}/${form}/${id}/edit`,
    reportSelect: (baseUrl, { selectedLevel, selectedEntity } = {}) => `${baseUrl}/report/${selectedLevel}/${selectedEntity}`,
    consolidatedReports: (baseUrl, { selectedLevel, selectedEntityId } = {}) => `${baseUrl}/consolidated-report/${selectedLevel}/${selectedEntityId}`,
    vendorCreate: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/vendor/create`,
    userProfile: (baseUrl, { fid, userId } = {}) => `${baseUrl}${fid ? `/${fid}` : ''}/user-profile/${userId}`,
    autoRentAdjustmentCreate: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/operations/rent-adjustment/auto/create`,
    manualRentAdjustmentCreate: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/operations/rent-adjustment/manual/create`,
    viewRentAdjustment: (baseUrl, { fid, rentAdjustmentId } = {}) => `${baseUrl}/${fid}/operations/rent-adjustment/${rentAdjustmentId}/view`,
    retailSaleReturns: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/returns`,
    conversations: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/conversations`,
    transfer: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/transfer`,
    dashboard: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}`,
    depositLog: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/operations/deposit-log`,
    userEdit: (baseUrl, { accountId, userId } = {}) => `${baseUrl}/account/${accountId}/user/${userId}/edit`,
    createUser: (baseUrl, { accountId } = {}) => `${baseUrl}/account/${accountId}/user/create`,
    configType: (baseUrl, { fid, config } = {}) => `${baseUrl}/c/facility/${fid}/${config}`,
    editUser: (baseUrl, { id } = {}) => `${baseUrl}/user/${id}/edit`,
    setUpUserList: (baseUrl, { accountId } = {}) => `${baseUrl}/account/${accountId}/user/list`,
    chargeCategory: (baseUrl, { fid } = {}) => `${baseUrl}/c/facility/${fid}/charge-category/list`,
    facilityView: (baseUrl, { accountId, fid } = {}) => `${baseUrl}/account/${accountId}/facility/${fid}/view`,
    facilityList: baseUrl => `${baseUrl}/facility/list`,
    accountFacilityEdit: (baseUrl, { accountId, fid }) => `${baseUrl}/account/${accountId}/facility/${fid}/edit`,
    migrationDetails: (baseUrl, { migrationId } = {}) => `${baseUrl}/${migrationId}/migration-details`,
    accountFacilityList: (baseUrl, { accountId } = {}) => `${baseUrl}/account/${accountId}/facility/list`,
    createCreditCardBatch: (baseUrl, { fid, form } = {}) => `${baseUrl}/${fid}/${form}`,
    viewCreditCardBatch: (baseUrl, { fid, id } = {}) => `${baseUrl}/${fid}/cc-batch/${id}/view`,
    viewAchBatch: (baseUrl, { fid, id } = {}) => `${baseUrl}/${fid}/ach-batch/${id}/view`,
    singleUnitRentAdjustment: (baseUrl, { fid, unitId } = {}) => `${baseUrl}/${fid}/unit/${unitId}/rent-adjustment`,
    emailAudit: (baseUrl, { fid } = {}) => `${baseUrl}/${fid}/conversations/email-audit/list`,
    createOtherBatchPayment: (baseUrl, { fid, form } = {}) => `${baseUrl}/${fid}/${form}`,
    createAchBatch: (baseUrl, { fid, form } = {}) => `${baseUrl}/${fid}/${form}`,
};

export default urls;
