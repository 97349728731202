import { Grid, withStyles, RootRef }
from 'Commons/components/generic/componentlibrary/components/Components';
import Link from 'Generic/link/components/Link';
import calculateTextWidth from 'Commons/helpers/utils/TextWidthCalculator';
import Typography from 'Generic/typography/components/Typography';
import CARD_TYPE from 'Commons/components/business/autosuggest/config/Constants';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import HighlightAutoSuggestText from 'Commons/components/business/autosuggest/components/HighlightSelectedText';
import Card from '../../../../generic/card/components/Card';
import Name from '../../../person/name/components/Name';
import tenantLeadCardStyle from '../styles/TenantLeadCardStyle';
import localisable from '../../../../../config/strings/localisable';
import { AUTO_SUGGEST_RESOURCE, CURRENCY_MAP, DASHBOARD_TYPE, SOURCE } from '../../../../../config/constants/Constants';
import {
    LIST_ITEMS_FONT_SIZE,
    LIST_ITEMS_FONT_WEIGHT,
} from '../config/Constants';
import CardChip from '../../../chips/component/CardChip';

class TenantLeadCard extends React.Component {
    constructor(props) {
        super(props);
        this.itemsContainerRef = React.createRef();
        this.state = { itemsContainerWidth: 0 };
    }

    componentDidMount = () => {
        const { itemsContainerRef: { current: itemsContainerRef } = {} } = this;
        if (itemsContainerRef) {
            this.setState({ itemsContainerWidth: itemsContainerRef.clientWidth });
        }
    };

    isResourceTenantLead = resource => resource === AUTO_SUGGEST_RESOURCE.TENANT_LEAD;

    renderItems = (items, source) => {
        const {
            classes, id: tenantOrLeadId,
            allowNavigation, currentFacility: { data: { id: fid } = {} },
            currentAccountId: { data: { id: accountId } = {} } = {}, autoSuggestText,
        } = this.props;
        const Container = allowNavigation ? Link : Grid;
        let { itemsContainerWidth } = this.state;
        return items.map(({ label, id }, index) => {
            const textWidth = calculateTextWidth(`${label}, `, `${LIST_ITEMS_FONT_SIZE}`, `${LIST_ITEMS_FONT_WEIGHT}`);

            if (itemsContainerWidth > 0) {
                itemsContainerWidth -= textWidth;
                const containerProps = allowNavigation ? { LabelWithIconProps: { classes: { label: `${classes.fullWidthMax} ${classes.ellipsis}` } } } : {};
                const currentId = source === SOURCE.tenant.value ? tenantOrLeadId : id;
                return (
                    <Container
                        key={id}
                        to={buildUrl(`${source}Edit`, { fid, id: currentId }, DASHBOARD_TYPE.EXTERNAL, accountId)}
                        className={classes.ellipsis}
                        {...containerProps}
                        style={{ width: itemsContainerWidth > 0 ? textWidth : itemsContainerWidth + textWidth }}
                    >
                        <Typography variant="body2" color="textPrimary" noWrap className={allowNavigation ? classes.label : ''}>
                            <HighlightAutoSuggestText autoSuggestText={autoSuggestText}>
                                {label}
                            </HighlightAutoSuggestText>
                            {(index !== (items.length - 1)) && ', '}
                        </Typography>
                    </Container>
                );
            }
            return null;
        });
    };

    render() {
        const {
            showChip,
            id,
            allowNavigation,
            alternateContact: {
                name: {
                    firstName: alternateContactFirstName,
                    lastName: alternateContactLastName,
                } = {},
            },
            name: { firstName, lastName, businessName, salutation, suffix },
            units, balance, showBalanceWithZero, ledger, ledgers, photo, cardType, alternateContact,
            address: { line1, line2 }, phone, email: { emailAddress: email } = {}, comments,
            primaryContact: { firstName: primaryContactFirstName, lastName: primaryContactLastName },
            classes, addon, disabled, currentFacility: { data: { currency, id: fid } = {} } = {}, currentAccountId: { data: { id: accountId } = {} } = {}, resource, label,
            CardProps, CardChipProps, NameContainerProps: { classes: nameContainerClasses, ...nameContainerProps } = {},
            onSelectCard, onClickAway, autoSuggestText, ...props
        } = this.props;

        const { itemsContainerWidth } = this.state;
        const disabledClassName = disabled ? classes.disabled : '';
        const topMarginClassName = this.isResourceTenantLead(resource) ? classes.topMargin : '';
        const topMarginClassNameForAddress = this.isResourceTenantLead(resource) ? classes.topMarginForAddress : '';
        const name = firstName || lastName || businessName || salutation || suffix;
        const Container = (allowNavigation && id) ? Link : Grid;
        const containerProps = allowNavigation
            ? {
                LabelWithIconProps: {
                    classes: {
                        labelWithIcon: classes.fullWidthMax,
                        label: classes.fullWidthMax,
                    },
                },
            } : { item: true };
        const mode = cardType === SOURCE.tenant.value ? 'View' : 'Edit';

        const commonTypographyPropsForContactAndAddress = {
            variant: 'body2',
            ...(this.isResourceTenantLead(resource) && {
                fontFamily: 'Open sans',
                variant: 'caption',
                component: 'p',
            }),
        };

        const getCardLink = () => buildUrl(cardType === CARD_TYPE.TENANT ? 'tenantView' : 'leadEdit', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);

        const renderCard = extraProps => (
            <Card {...CardProps} {...props} {...extraProps}>
                <Grid container wrap="nowrap">
                    {
                        photo
                            && (
                                <Grid item xs={4} className={classes.tenantImage}>
                                    <img
                                        className={classes.photo}
                                        src={photo}
                                        alt="tenant"
                                    />
                                </Grid>
                            )
                    }
                    <Grid item xs={photo ? 8 : 12}>
                        <Grid container wrap="nowrap" className={`${classes.dataContainer} ${showChip ? classes.textWithChip : ''}`} direction="column">
                            {
                                (name || addon)
                                    && (
                                        <Grid container justify="space-between" wrap="nowrap">
                                            {
                                                name
                                                && (
                                                    <Container
                                                        to={buildUrl(`${cardType}${mode}`, { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId)}
                                                        xs={addon ? 8 : 12}
                                                        classes={allowNavigation ? { link: classes.fullWidthMax } : { ...nameContainerClasses }}
                                                        {...containerProps}
                                                        {...nameContainerProps}
                                                    >
                                                        <Name
                                                            firstName={firstName}
                                                            lastName={lastName}
                                                            businessName={businessName}
                                                            salutation={salutation}
                                                            suffix={suffix}
                                                            autoSuggestText={autoSuggestText}
                                                            className={`${classes.name} ${allowNavigation ? classes.label : ''}`}
                                                        />
                                                    </Container>
                                                )
                                            }
                                        </Grid>
                                    )
                            }

                            {
                                addon
                                    && (
                                        <Grid container className={classes.icon} alignItems="center">
                                            {addon}
                                        </Grid>
                                    )
                            }

                            {
                                (primaryContactFirstName || primaryContactLastName)
                                    && (
                                        <Name
                                            firstName={primaryContactFirstName}
                                            lastName={primaryContactLastName}
                                            autoSuggestText={autoSuggestText}
                                        />
                                    )
                            }

                            {
                                (phone || email)
                                && (
                                    <Grid item>
                                        <Typography
                                            noWrap
                                            className={`${classes.typography} ${disabledClassName} ${topMarginClassName}`}
                                            {...commonTypographyPropsForContactAndAddress}
                                        >
                                            <HighlightAutoSuggestText autoSuggestText={autoSuggestText}>{phone}</HighlightAutoSuggestText>
                                            {!this.isResourceTenantLead(resource) && phone && email && localisable.separator}
                                            {!this.isResourceTenantLead(resource)
                                                && <HighlightAutoSuggestText autoSuggestText={autoSuggestText}>{email}</HighlightAutoSuggestText>
                                            }
                                        </Typography>
                                        {this.isResourceTenantLead(resource) && email && (
                                            <Typography
                                                noWrap
                                                className={`${classes.typography} ${disabledClassName} ${topMarginClassName}`}
                                                {...commonTypographyPropsForContactAndAddress}
                                            >
                                                {email && <HighlightAutoSuggestText autoSuggestText={autoSuggestText}>{email}</HighlightAutoSuggestText>}
                                            </Typography>
                                        )}
                                    </Grid>
                                )
                            }
                            {
                                line1
                                && (
                                    <Grid item>
                                        <Typography
                                            noWrap
                                            className={`${classes.typography} ${disabledClassName} ${topMarginClassNameForAddress}`}
                                            {...commonTypographyPropsForContactAndAddress}
                                        >
                                            <HighlightAutoSuggestText autoSuggestText={autoSuggestText}>{line1}</HighlightAutoSuggestText>
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                line2
                                && (
                                    <Grid item>
                                        <Typography variant="body2" noWrap className={`${classes.typography} ${disabledClassName}`}>
                                            <HighlightAutoSuggestText autoSuggestText={autoSuggestText}>{line2}</HighlightAutoSuggestText>
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                units.length > 0
                                && (
                                    <Grid container alignItems="center" wrap="nowrap">
                                        <Typography
                                            variant="h6"
                                            className={`${classes.card} ${disabledClassName}`}
                                        >
                                            {`${localisable.tenantCardUnits}:`}
                                            &ensp;
                                        </Typography>
                                        <RootRef rootRef={this.itemsContainerRef}>
                                            <Grid
                                                container
                                                className={`${classes.labelsContainer} ${classes.ellipsis} ${disabledClassName}`}
                                            >
                                                {itemsContainerWidth ? this.renderItems(units, SOURCE.unit.value) : null}
                                            </Grid>
                                        </RootRef>
                                    </Grid>
                                )
                            }
                            {
                                (showBalanceWithZero || (balance > 0))
                                && (
                                    <Grid container alignItems="center" wrap="nowrap">
                                        <Typography
                                            variant="h6"
                                            className={`${classes.card} ${disabledClassName}`}
                                        >
                                            {`${localisable.tenantCardBalance}:`}
                                            &ensp;
                                        </Typography>
                                        <Typography variant="body2" noWrap className={disabledClassName}>
                                            {balance === 0 ? '0.00' : `${CURRENCY_MAP[currency]} ${balance}`}
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                ledger
                                && (
                                    <Grid container alignItems="center" wrap="nowrap">
                                        <Typography
                                            variant="h6"
                                            className={`${classes.card} ${disabledClassName}`}
                                        >
                                            {`${localisable.tenantCardLedger}:`}
                                            &ensp;
                                        </Typography>
                                        <Typography variant="body2" noWrap className={disabledClassName}>{ledger}</Typography>
                                    </Grid>
                                )
                            }
                            {
                                ledgers.length > 0
                                && (
                                    <Grid container alignItems="center" wrap="nowrap">
                                        <Typography
                                            variant="h6"
                                            className={`${classes.card} ${disabledClassName}`}
                                        >
                                            {`${localisable.tenantCardLedgers}:`}
                                            &ensp;
                                        </Typography>
                                        <RootRef rootRef={this.itemsContainerRef}>
                                            <Grid
                                                container
                                                className={`${classes.labelsContainer} ${classes.ellipsis} ${disabledClassName}`}
                                            >
                                                {itemsContainerWidth ? this.renderItems(ledgers, SOURCE.tenant.value) : null}
                                            </Grid>
                                        </RootRef>
                                    </Grid>
                                )
                            }

                            {
                                (alternateContactFirstName || alternateContactLastName)
                                && (
                                    <Grid container alignItems="center" wrap="nowrap">
                                        <Grid item>
                                            <Typography variant="h6" noWrap className={classes.card}>
                                                {`${localisable.tenantCardAlternateContact}:`}
                                                &ensp;
                                            </Typography>
                                        </Grid>
                                        <Grid item zeroMinWidth>
                                            <Name
                                                firstName={alternateContactFirstName}
                                                lastName={alternateContactLastName}
                                                autoSuggestText={autoSuggestText}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            }

                            {
                                comments
                                && (
                                    <Grid container alignItems="center" wrap="nowrap">
                                        <Typography variant="h6" className={classes.card}>
                                            {`${localisable.tenantCardComments}:`}
                                            &ensp;
                                        </Typography>
                                        <Typography variant="body2" noWrap>{comments}</Typography>
                                    </Grid>
                                )
                            }

                            {showChip && (
                                <CardChip
                                    className={classes.cardChip}
                                    type={cardType}
                                    {...CardChipProps}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        );

        return (
            onSelectCard
                ? renderCard()
                : (
                    <Link
                        to={getCardLink()}
                        classes={{ link: classes.fullWidth }}
                        LabelWithIconProps={{ classes: { label: classes.fullWidth } }}
                    >
                        {renderCard({ onClick: () => onClickAway() })}
                    </Link>
                )
        );
    }
}

TenantLeadCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        salutation: PropTypes.string,
        suffix: PropTypes.string,
        preferredName: PropTypes.string,
        businessName: PropTypes.string,
    }),
    alternateContact: PropTypes.shape({
        name: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
    }),
    primaryContact: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    address: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
    }),
    email: PropTypes.shape({ emailAddress: PropTypes.string }),
    comments: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node,
        id: PropTypes.string,
    })),
    phone: PropTypes.string,
    balance: PropTypes.number,
    addon: PropTypes.object,
    photo: PropTypes.string,
    disabled: PropTypes.bool,
    currentFacility: PropTypes.object,
    currentAccountId: PropTypes.object,
    ledger: PropTypes.string,
    CardProps: PropTypes.object,
    CardChipProps: PropTypes.object,
    cardType: PropTypes.oneOf(['lead', 'tenant', 'unit']),
    classes: PropTypes.object.isRequired,
    showChip: PropTypes.bool,
    showBalanceWithZero: PropTypes.bool,
    allowNavigation: PropTypes.any,
    label: PropTypes.string,
    ledgers: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.node,
            id: PropTypes.string,
        })),
        PropTypes.object,
    ]),
    resource: PropTypes.number,
    NameContainerProps: PropTypes.object,
    onSelectCard: PropTypes.func,
    onClickAway: PropTypes.func,
    autoSuggestText: PropTypes.string,
};

TenantLeadCard.defaultProps = {
    disabled: false,
    cardType: 'tenant',
    balance: 0,
    name: {},
    address: {},
    email: {},
    units: [],
    ledgers: [],
    currentFacility: {},
    primaryContact: {},
    alternateContact: {},
    showChip: true,
    allowNavigation: false,
    showBalanceWithZero: false,
    autoSuggestText: '',
};

export default withStyles(tenantLeadCardStyle)(TenantLeadCard);
