/* eslint-disable no-case-declarations */
import { TextField } from 'Commons/components/generic/textfield/components/TextField';
import Icon from '../../../generic/icon/components/Icon';
import { List, ListItem, ListItemText, withStyles, Grid, Typography }
from '../../../generic/componentlibrary/components/Components';
import FilterTypesStyle from '../styles/FilterTypesStyle';
import DefaultComponent from './FilterComponent';
import * as SectionLoaders from './SectionLoaders';
import { FilterTypes as FilterEnums, MinSections } from './configs/FilterConfigs';
import FILTER_STAGES from './configs/FilterStages';

class FilterTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isFiltered: false };
        this.sections = { ...props.sections };
        this.sectionsList = this.sections ? Object.keys(this.sections) : [];
    }

    componentDidMount = () => {
        if (this.sectionsList) {
            this.setState({ sectionsList: this.sectionsList });
        }
    }

    isCurrentSectionActive = (section) => {
        const { curSection } = this.props;
        if (curSection) {
            return curSection.label === section.label;
        }
        return false;
    }

    filterSections = (key) => {
        if (key.length > 0) {
            const { sections } = this.props;
            const filteredSections = {};
            Object.keys(sections).forEach((section) => {
                const searchResult = this.sections[section].label.match(new RegExp(key, 'gi'));
                if (searchResult && searchResult.length > 0) {
                    filteredSections[section] = this.sections[section];
                }
            });
            this.sectionsList = Object.keys(filteredSections);
            this.setState({ isFiltered: true });
        } else {
            const { sections } = this.props;
            this.sectionsList = sections ? Object.keys(sections) : [];
            this.setState({ isFiltered: false });
        }
        this.setState({ sectionsList: this.sectionsList });
    }

    showAppliedFilters = () => {
        const { changeStage } = this.props;
        changeStage(FILTER_STAGES.APPLIED_FILTERS);
    }

    renderSectionHeader() {
        const { device, classes } = this.props;
        switch (device) {
            case 'isMobile':
            case 'isPhablet':
            case 'isTablet':
                return (
                    <ListItem
                        divider
                        classes={{ divider: classes.divider }}
                    >
                        <Grid item xs={8} sm={8} className={classes.mobileSectionHeader}>Filter List</Grid>
                        <Grid item xs={4} sm={4} className={classes.mobileSectionFilters} onClick={this.showAppliedFilters}>Applied Filters</Grid>
                    </ListItem>
                );
            default:
                return (<Typography variant="body2" className={classes.sectionHeader}>Filter List</Typography>);
        }
    }

    renderSearch() {
        const { classes } = this.props;
        return (
            <div className={classes.searchFieldContainer}>
                <TextField
                    name="filterSearch"
                    placeholder="Search"
                    InputProps={{ className: classes.searchField }}
                    addon={{
                        end: <Icon
                            icon="cp-search"
                            type="custom"
                        />,
                    }}
                    onChange={(event) => {
                        this.filterSections(event.target.value);
                    }}
                    fullWidth
                />
            </div>
        );
    }

    renderList() {
        const {
            props: { classes, device },
            sections,
            sectionsList,
            state: { sectionsList: stateSectionsList, isFiltered },
        } = this;
        const sectionsRef = stateSectionsList || sectionsList;
        return (
            <List component="div" className={classes.list}>
                {
                        <>
                            {this.renderSectionHeader()}
                            { (device === 'isDesktop') && ((sectionsRef.length > MinSections[device] && !isFiltered) || isFiltered) && this.renderSearch()}
                            {sectionsRef.length > 0 && sectionsRef.map(section => (
                                this.renderListItem(sections[section])
                            ))}
                            {sectionsRef.length === 0 && (
                                <ListItem
                                    className={classes.listItems}
                                >
                                    <ListItemText>No Filters Found</ListItemText>
                                </ListItem>
                            )}
                        </>

                }
            </List>
        );
    }

    renderListForDevice() {
        const { device, stage, classes } = this.props;
        switch (device) {
            case 'isMobile':
            case 'isPhablet':
            case 'isTablet':
                if (stage === FILTER_STAGES.FILTER_TYPES_LIST) {
                    return (
                        <Grid item container xs={12} sm={12} lg={3}>
                            {this.renderList()}
                        </Grid>
                    );
                }
                return (<></>);
            default:
                return (
                    <Grid item container xs={12} sm={12} lg={3} className={classes.sectionsContainer}>
                        {this.renderList()}
                    </Grid>
                );
        }
    }

    renderSectionForDevice() {
        const { device, stage } = this.props;
        switch (device) {
            case 'isMobile':
            case 'isPhablet':
            case 'isTablet':
                if (stage === FILTER_STAGES.FILTER_SECTION) {
                    return this.renderSectionComponent();
                }
                return (<></>);
            default:
                return this.renderSectionComponent();
        }
    }

    renderListItem(section) {
        const { classes, device, onSectionClicked } = this.props;
        const { label } = section;
        return (
            <ListItem
                className={classes.listItems}
                key={label}
                onClick={() => {
                    onSectionClicked(section);
                }}
            >
                <ListItemText className={this.isCurrentSectionActive(section) ? classes.activeLabel : ''}>{label}</ListItemText>
                { device !== 'isDesktop' && (this.isCurrentSectionActive(section) ? <Icon>chevron_right</Icon> : '')}
            </ListItem>
        );
    }

    renderSectionComponent() {
        const { DATE_RANGE, NUMBER_RANGE, CUSTOM } = FilterEnums;
        const { curSection } = this.props;
        const { formProps, classes, appliedFilters, availableFilters, device, onBack } = this.props;
        let CustomComponent = DefaultComponent;
        if (Object.keys(curSection).length > 0) {
            const { type, componentProps: { component, ...restOfComponentProps } } = curSection;
            switch (type) {
                case DATE_RANGE:
                    CustomComponent = SectionLoaders[component] || SectionLoaders.DateRange;
                    break;
                case NUMBER_RANGE:
                    CustomComponent = SectionLoaders[component] || SectionLoaders.NumberRange;
                    break;
                case CUSTOM:
                    CustomComponent = SectionLoaders[component] || DefaultComponent;
                    break;
                default:
                    break;
            }
            return (
                <Grid item container xs={12} sm={12} lg={9} className={classes.sectionComponent}>
                    <CustomComponent
                        availableFilters={availableFilters}
                        appliedFilters={appliedFilters}
                        section={curSection}
                        {...restOfComponentProps}
                        formProps={formProps}
                        device={device}
                        onBack={onBack}
                        className={classes.section}
                    />
                </Grid>
            );
        }
        return (
            <Grid item container xs={12} sm={12} lg={9}>
                <DefaultComponent />
            </Grid>
        );
    }

    render() {
        const { classes } = this.props;
        // this.initSections(sections);
        return (
            <Grid container className={classes.filterTypesContainer}>
                {
                    this.renderListForDevice()
                }
                {
                    this.renderSectionForDevice()
                }
            </Grid>
        );
    }
}

FilterTypes.propTypes = {
    classes: PropTypes.any,
    sections: PropTypes.any.isRequired, // The Sections to display
    formProps: PropTypes.any.isRequired, // The formik props from overlay
    appliedFilters: PropTypes.any, // The initial applied filters if any
    device: PropTypes.any, // The device being rendered on
    stage: PropTypes.any, // The current stage
    changeStage: PropTypes.func, // Change State
    onBack: PropTypes.func, // Handle back icon press in mobile
    availableFilters: PropTypes.any.isRequired, // The list of all filters available for work center
    curSection: PropTypes.any.isRequired, // The current section that is set by parent container
    onSectionClicked: PropTypes.any.isRequired, // When section is changed
};

export default withStyles(FilterTypesStyle)(FilterTypes);
