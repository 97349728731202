import { Grid, makeStyles }
from 'Commons/components/generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { useRef } from 'react';
import Card from '../../../../generic/card/components/Card';
import occupancyCardStyles from '../styles/OccupanyCardStyles';
import localisable from '../../../../../config/strings/localisable';
import CardChip from '../../../chips/component/CardChip';
import {
    isEstimateExpired, getCardType, getCardLabel,
    getUnitTypeDescription, getUnitDescription,
} from '../config/Utils';


const useStyles = makeStyles(occupancyCardStyles, { name: 'OccupancyCard' });


const OccupancyCard = (props) => {
    const {
        reservationCode = '', estimateCode = '',
        customer: {
            id: customerId, name: {
                firstName: customerFirstName = '',
                lastName: customerLastName = '',
            } = {},
        } = {},
        relationalData: { unit: unitList = [], unitType: unitTypeList = [] } = {},
        unitId, unitTypeId, cardType, expirationDate = '', reservationDate = '', CardProps, ...otherProps
    } = props;

    const classes = useStyles();

    const { current: isExpired } = useRef(isEstimateExpired(expirationDate));
    const { current: variant } = useRef(getCardType(estimateCode, isExpired));
    const { current: label } = useRef(getCardLabel(estimateCode, isExpired));

    return (
        <Card {...CardProps} className={classes.card} {...otherProps}>
            <Grid container wrap="nowrap">
                <Grid container>
                    {
                        reservationCode
                        && (
                            <Grid>
                                <Typography variant="body1" className={classes.typography} fontFamily="Open Sans">
                                    {`${localisable.reservation}: ${reservationCode}`}
                                </Typography>

                            </Grid>
                        )
                    }
                    {
                        estimateCode
                        && (
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.typography} fontFamily="Open Sans">
                                    {`${localisable.estimate}: ${estimateCode}`}
                                </Typography>
                            </Grid>
                        )
                    }
                    {
                        unitId
                        && (
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    {getUnitDescription(unitId, unitList)}
                                </Typography>
                            </Grid>
                        )
                    }
                    {
                        unitTypeId
                        && (
                            <Grid item xs={12}>
                                <Typography variant="body2" noWrap>
                                    {getUnitTypeDescription(unitTypeId, unitTypeList)}
                                </Typography>
                            </Grid>
                        )
                    }
                    {
                        customerId
                        && (
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    {`${customerFirstName} ${customerLastName}`}
                                </Typography>
                                {
                                    expirationDate
                                    && (
                                        <Typography variant="body2">
                                            {`${localisable.estimateCreatedOn} ${expirationDate}`}
                                        </Typography>
                                    )
                                }
                                {
                                    reservationDate
                                    && (
                                        <Typography variant="body2">
                                            {`${localisable.unitReservedOn} ${reservationDate}`}
                                        </Typography>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
                <CardChip
                    className={classes.cardChip}
                    type={variant}
                    label={label}
                    labelClassName={classes.chipLabel}
                />
            </Grid>
        </Card>
    );
};

OccupancyCard.propTypes = {
    reservationCode: PropTypes.string,
    estimateCode: PropTypes.string,
    tenant: PropTypes.object,
    customer: PropTypes.object,
    cardType: PropTypes.string,
    expirationDate: PropTypes.string,
    reservationDate: PropTypes.string,
    relationalData: PropTypes.object,
    unitId: PropTypes.string,
    unitTypeId: PropTypes.string,
    CardProps: PropTypes.object,
};

export default OccupancyCard;
