import { memo, useContext, useMemo } from 'react';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { FormSmartSearchableDropdown } from 'Generic/dropdown/components/SearchableDropdown';
import { FormTextField } from 'Generic/textfield/components/TextField';
import { FormRadioGroup } from 'Generic/radiogroup/components/RadioGroup';
import { SM_6, XL_9 } from 'Commons/config/constants/GridSpacingConstants';
import { IS_MAX_LENGTH_50, IS_NON_ZERO_POSITIVE_NUMERIC } from 'Commons/config/constants/Validators';
import { getOptionProps, labelFormatter, optionsFormatter } from 'Commons/helpers/utils/SDFormatter';
import localisable from 'Commons/config/strings/localisable';
import bevLeftSidebarStyles
from 'Commons/components/business/bev/components/setup/bevLeftSideBar/styles/BevLeftSidebarStyles';
import { isTwoDecimalPlaces } from 'Commons/helpers/utils/validator/Validator';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import PermissionContext from 'Commons/contexts/PermissionContext';
import hasPermission from 'Commons/helpers/utils/PermissionChecker';
import { UNIT_PERMISSION_KEYS } from 'External/containers/unit/form/config/UnitPermissionKeys';
import { COUNT_RADIO_LIST, COUNT_TYPE } from '../../../../config/Constants';
import { getUnitTypeApiConfig } from '../../../../config/ApiRequests';
import BevFormField from './BevFormFieldWrapper';

const useStyles = makeStyles(bevLeftSidebarStyles, { name: 'UnitFormComponent' });

const UnitFormComponent = ({
    setupModeCanvasRef,
    formProps: { values, setFieldValue } = {},
    currentFacility: { data: { id: facilityId } = {} },
    unitTypeList,
    requiredFieldsConfig,
    requiredFieldsConfig: {
        label: isLabelRequired = false,
        width: isWidthRequired = false,
        depth: isDepthRequired = false,
        unitType: isUnitTypeRequired = false,
    },
    labelToIdMap,
    addToUnitTypeMap,
    modifyLabelToIdMap,
    setUnitLabelBeforeDuplicateError,
    setShowDuplicateUnitLabelAlertDialog,
}) => {
    const classes = useStyles();

    const handleFieldChange = ({ target: { name: property, value: newLabel } }) => {
        if (property === 'label') {
            const { label: prevLabel, id: idOfSelectedUnit } = values;
            const idOfAlreadyExistingUnit = labelToIdMap[newLabel];
            const labelAlreadyExists = idOfAlreadyExistingUnit && idOfAlreadyExistingUnit !== idOfSelectedUnit;
            if (labelAlreadyExists) {
                // formProps values will still have the old value(prevLabel). We store that here
                setUnitLabelBeforeDuplicateError(idOfSelectedUnit, prevLabel);
                setShowDuplicateUnitLabelAlertDialog(true);
            } else {
                modifyLabelToIdMap(newLabel, idOfSelectedUnit);
            }
        }
        setupModeCanvasRef.modifyEntityData(property, newLabel);
    };

    const handleUnitTypeChange = (e, value, data = {}) => {
        const { data: { dimension: { width = '', depth = '' } = {} } = {} } = data;
        if (value) addToUnitTypeMap(data);
        setFieldValue('bev.width', width, false);
        setFieldValue('bev.depth', depth, false);
        setupModeCanvasRef.modifyEntityData('unitType', value);
        setupModeCanvasRef.modifyEntityData('bev.width', width);
        setupModeCanvasRef.modifyEntityData('bev.depth', depth);
    };

    const unitTypeApiConfig = useMemo(() => getUnitTypeApiConfig(facilityId), [facilityId]);

    const requiredFieldsConfigLength = Object.keys(requiredFieldsConfig).length;

    const [{ id: unitTypeId } = {}] = unitTypeList;
    const { tempId: isNewlyAddedUnit, id: unitId } = values;
    const permission = useContext(PermissionContext);

    const { validatorsForWidthDepthAndAngleFields, unitTypePermissions } = useConstructor(() => ({
        validatorsForWidthDepthAndAngleFields: isTwoDecimalPlaces,
        permission,
        unitTypePermissions: hasPermission(permission, UNIT_PERMISSION_KEYS.UNIT_TYPE),
    }));

    const isReadOnly = () => !unitTypePermissions && unitId && !isNewlyAddedUnit;

    return (
        <Grid container className={classes.sideBarTopMargin}>
            <BevFormField
                key={unitTypeId}
                component={FormSmartSearchableDropdown}
                label={localisable.unitType}
                placeholder={localisable.selectUnitType}
                name="unitType"
                required={isUnitTypeRequired}
                GridProps={XL_9}
                keyToMatch="id"
                inputValueFormatter={labelFormatter}
                getOptionProps={getOptionProps}
                autoSuggestKey="matchPhrasePrefix.value.description"
                optionFormatter={optionsFormatter}
                apiConfig={unitTypeApiConfig}
                onChange={handleUnitTypeChange}
                options={unitTypeList}
                readOnly={isReadOnly()}
            />
            <BevFormField
                key={`${requiredFieldsConfigLength}-${localisable.editUnitNumber}`}
                component={FormTextField}
                label={localisable.bevUnitNumberField}
                name="label"
                placeholder={localisable.editUnitNumber}
                validate={{
                    ...IS_MAX_LENGTH_50,
                    isUnitLabelDuplicate: { value: { selectedUnitId: values.id, labelToIdMap } },
                }}
                required={isLabelRequired}
                GridProps={XL_9}
                onChange={handleFieldChange}
                useField
            />
            <BevFormField
                key={`${requiredFieldsConfigLength}-${localisable.enterWidth}`}
                component={FormTextField}
                label={localisable.bevWidthFieldLabel}
                name="bev.width"
                type="number"
                placeholder={localisable.enterWidth}
                required={isWidthRequired}
                GridProps={SM_6}
                onChange={handleFieldChange}
                validate={validatorsForWidthDepthAndAngleFields}
                readOnly={isReadOnly()}
                inputProps={isReadOnly() && { className: classes.inputField }}
            />
            <BevFormField
                key={`${requiredFieldsConfigLength}-${localisable.enterDepth}`}
                component={FormTextField}
                label={localisable.bevDepthFieldLabel}
                name="bev.depth"
                type="number"
                placeholder={localisable.enterDepth}
                required={isDepthRequired}
                GridProps={SM_6}
                onChange={handleFieldChange}
                validate={validatorsForWidthDepthAndAngleFields}
                readOnly={isReadOnly()}
                inputProps={isReadOnly() && { className: classes.inputField }}
            />
            <BevFormField
                component={FormRadioGroup}
                row
                label={localisable.bevCountRadioLabel}
                name="countType"
                dataType="number"
                className={classes.radioGroupMarginRight}
                list={COUNT_RADIO_LIST}
            />
            <BevFormField
                component={FormTextField}
                label={
                    (values.countType === COUNT_TYPE.INCREMENT)
                        ? localisable.bevIncrementFieldLabel : localisable.bevDecrementFieldLabel
                }
                name="count"
                useField
                placeholder={localisable.bevCountFieldPlaceholder}
                type="number"
                componentClasses={{ number: classes.number }}
                GridProps={SM_6}
                validate={IS_NON_ZERO_POSITIVE_NUMERIC}
            />
            <BevFormField
                component={FormTextField}
                label={localisable.bevAngleFieldLabel}
                name="bev.angle"
                placeholder={localisable.bevAngleFieldPlaceholder}
                type="number"
                componentClasses={{ number: classes.number }}
                GridProps={SM_6}
                onChange={handleFieldChange}
                validate={validatorsForWidthDepthAndAngleFields}
            />
        </Grid>
    );
};

UnitFormComponent.propTypes = {
    currentFacility: PropTypes.object,
    setupModeCanvasRef: PropTypes.object,
    formProps: PropTypes.object,
    unitTypeList: PropTypes.array,
    requiredFieldsConfig: PropTypes.object.isRequired,
    labelToIdMap: PropTypes.object.isRequired,
    modifyLabelToIdMap: PropTypes.func.isRequired,
    addToUnitTypeMap: PropTypes.func.isRequired,
    setUnitLabelBeforeDuplicateError: PropTypes.func.isRequired,
    setShowDuplicateUnitLabelAlertDialog: PropTypes.func.isRequired,
};

UnitFormComponent.defaultProps = {
    setupModeCanvasRef: {},
    currentFacility: {},
    formProps: {},
    unitTypeList: {},
};

export default memo(UnitFormComponent);
