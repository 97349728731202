import { ACTIONS } from 'External/containers/rentalConfiguration/config/RentalFormConfigs';
import enums from './Enums';
import { createEnumMap, mapEnum } from '../../helpers/utils/Formatter';

const formatter = key => (key.replace(/([A-Z])/g, match => `_${match.toUpperCase()}`)).toUpperCase();

const CURRENCY_MAP = {
    Usd: '$',
    Inr: '₹',
};
const PRIMARY_PHONE = mapEnum(enums.primary);
const TIMEOUT = 500;
const SESSION_TIMEOUT = 1740000; // 29 minutes
const VIEW = mapEnum(enums.view);
const STATUS = mapEnum(enums.status);
const RENT_ASSESSMENT_MODE = mapEnum(enums.rentAssessmentMode);
const SOURCE = mapEnum(enums.source);
const INTERNAL_SOURCE = mapEnum(enums.internalSource);
const CONFIG_RESOURCE = createEnumMap(enums.configResource, formatter);
const SEARCH_DOCUMENT_SOURCE = createEnumMap(['Pending_Invoice_Delivery']);
const AUTO_SUGGEST_RESOURCE = {
    UNIT: 0,
    TENANT_LEAD: 1,
    TENANT: 2,
    LEAD: 3,
    OCCUPANCY: 4,
    MOVE_OUT: 5,
    GLOBAL: 6,
};
const YES_NO_LIST = [
    {
        label: 'Yes',
        value: 'true',
    },
    {
        label: 'No',
        value: 'false',
    },
];
const SUFFIX = mapEnum(enums.nameSuffix);
const SALUTATIONS = mapEnum(enums.nameSalutation);
const TAXES = createEnumMap(enums.taxes, key => key.replace(/_/, ' '));
const NO_OF_COLUMNS_FORM = 2;
const BROWSERS_LIST = createEnumMap(enums.browsers);
const HEADER_HEIGHT = 64;
const CLIENT_HEIGHT = 500;
const CLIENT_WIDTH = 500;
const VERTICAL_PADDING = 230;
const HORIZONTAL_PADDING = 0;
const VERTICAL_PADDING_FOR_TABLET_UP = 148;
const HORIZONTAL_PADDING_FOR_TABLET_UP = 8;
const VERTICAL_PADDING_FOR_LAPTOP_UP = 188;
const HORIZONTAL_PADDING_FOR_LAPTOP_UP = 40;
const SAFARI_ADDRESS_BAR_HEIGHT = 120;
const DATE_FORMAT = 'MM-DD-YYYY';
const DATETIME_FORMAT = 'MM-DD-YYYY HH:mm:ss.SSS';
const DB_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';
const TWELVE_HOUR_DATETIME_FORMAT = 'hh:mm A';
const PRETTY_DATE_FORMAT = 'MMMM DD, YYYY';
const FULL_DATE_FORMAT = 'MM/DD/YYYY, hh:mm A';
const FULL_DATE_TIME_FORMAT = 'MM/DD/YYYY, hh:mm:ss A';
const FULL_DATE_HYPHEN_FORMAT = 'MM-DD-YYYY, hh:mm A';
const FULL_YEAR_FORMAT = 'YYYY';
const SHORT_YEAR_FORMAT = 'YY';
const EXPORT_DATE_FORMAT = 'MM-DD-YYYY [at] hh:mm:ss A';
const DETAILED_DATETIME_FORMAT = 'MM-DD-YYYY hh:mm:ss.SSS A';
const ACTIVATE = 'activate';
const DEACTIVATE = 'deactivate';
const EMPTY_FIELD_TEXT = '--';
const EMPTY_DATE_TEXT = '--/--';
const FEATURE = mapEnum(enums.feature);
const ACTION_TYPE = createEnumMap(enums.actionType);
const DASHBOARD_TYPE = createEnumMap(enums.dashboardType);
const ENCRYPTED_ID_LENGTH = 68;
const FORM_MODE = createEnumMap(enums.formMode);
const SLIDE_DIRECTION = createEnumMap(enums.slideDirection);
const TRANSITION_VARIANT = createEnumMap(enums.transitionVariant);
const INSURANCE_SOURCE = createEnumMap(enums.insuranceSource);
const TABLE_ROW_HEIGHT_VARIANT = {
    ONE_LINER: 52,
    TWO_LINER: 64,
    THREE_LINER: 72,
    FOUR_LINER: 80,
    HEADER_ONE_LINER: 38,
    HEADER_ONE_LINER_RESPONSIVE: 35,
    HEADER_TWO_LINER: 59,
    HEADER_TWO_LINER_RESPONSIVE: 53,
};
const RESERVATION_STATUS = createEnumMap(enums.reservationStatus, key => key.replace(/-/g, ''));
const DELINQUENCY_STATUS = mapEnum(enums.delinquencyStatus);
const DELINQUENCY_SCHEDULED_STATUS = mapEnum(enums.delinquencySchedulerStatus);
const TABLE_STATE = createEnumMap(enums.state);
const ENTITY_LEVEL = mapEnum(enums.entityLevel);
const LOCKOUT_TYPE = mapEnum(enums.lockoutType);

const DELETE_OVERLAY_ACTION = createEnumMap(['add', 'ok', 'undo', 'delete']);
const DELETE_OVERLAY_DELETION_STATUS = createEnumMap(['new', 'existing']);

const USER_TYPE = mapEnum(enums.userType);

const LETTER_EDITOR_SOURCE = createEnumMap(enums.letterEditorSource);

const WS_EVENT_TYPE = createEnumMap(enums.wsEventType);
const WS_EVENT_SOURCE = createEnumMap(enums.wsEventSource);

const EMPTY_FUNC = () => { };

const EMPTY_STRING = '';

const EMPTY_OBJECT = {};

const EMPTY_LIST = [];

const SEARCH_DATE_FORMAT = 'YYYY-MM-DD';
const SEARCH_MAX_SIZE = 10000;
const COMPARISON_OPERATION = createEnumMap(enums.comparisonOperation);

const UNIT_AGGREGATION = createEnumMap(enums.unitAggregation);
const TIME_WINDOW = createEnumMap(enums.timeWindow);

const MILITARY_TYPE = mapEnum(enums.militaryType);
const MILITARY_BRANCH = mapEnum(enums.militaryBranch);

const NONE_OPTION = { label: 'None', value: null };
const REPORT_TYPE = createEnumMap(enums.reportType);
const TRANSACTION_TYPE = mapEnum(enums.transactionType);

const rentAssessmentStatus = ['In_Progress', 'Completed', 'Killed', 'Scheduled'];
const RENT_ASSESSMENT_STATUS = createEnumMap(rentAssessmentStatus);

const ALL_ROUNDING_TYPES = mapEnum(enums.roundingType);

const ONE_MINUTE = 60000; // in ms

const FIVE_SECONDS = 5000; // in ms

const FIVE_MINUTES = 300000; // in ms

const NUMBER_OF_DAYS_IN_WEEK = 7;

const MAX_WEEKS_IN_MONTH = 6;

const TOKEN_TYPE = createEnumMap(enums.tokenType);

const DOCUMENT_TYPE = createEnumMap(enums.documentType);
const TENANT_CHANGE_TRACKING_STATUS = createEnumMap(enums.tenantChangeTrackingStatus, key => key.replace(/-/g, ''));
const TENANT_CHANGE_TYPE = createEnumMap(enums.tenantChangeType, key => key.replace(/-/g, ''));
const OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS = createEnumMap(enums.otherExternalActivityTrackingStatus, key => key.replace(/-/g, ''));


const CARD_PROCESSOR_TRANSACTION_STATUS = createEnumMap(enums.cardProcessorTransactionStatus,
    key => key.replace(/-/g, ''));
const setupStatus = ['Complete', 'Incomplete'];
const SETUP_STATUS = createEnumMap(setupStatus);
const RENT_TAXABLE_YES_OR_NO_LIST = [
    {
        label: 'Yes, it is taxable',
        value: 'true',
    },
    {
        label: 'No, we don\'t charge tax on rent',
        value: 'false',
    },
];

const OCCUPANCY_CHARGE_OPTION_LIST = [
    {
        label: 'Yes, we do',
        value: 'true',
    },
    {
        label: 'Nope, we don\'t charge',
        value: 'false',
    },
];

const MOVE_IN_BILL_EVERY_MONTH_OPTIONS_LIST = [
    {
        label: 'Yes, we bill on first of the month',
        value: 'true',
    },
    {
        label: 'No, we will bill based on move in date',
        value: 'false',
    },
];

const MOVE_OUT_CHARGES_OPTIONS_LIST = [
    {
        label: 'Prorate Out',
        value: ACTIONS.Prorate_Out.value,
    },
    {
        label: 'Do nothing',
        value: ACTIONS.Do_Nothing.value,
    },
];

const RETAIL_ITEMS_TAXABLE_YES_OR_NO_LIST = [
    {
        label: 'Yes, it is taxable',
        value: 'true',
    },
    {
        label: 'No, we don\'t charge tax on retail sale',
        value: 'false',
    },
];

const SMALL_TEXTFIELD_WIDTH = 200;
const DROPDOWN_MAX_HEIGHT = 300;

const DEMO_FACILITY_ID_LIST = [1, 2, 11];
const OS_LIST = createEnumMap(enums.osList);
const MAC_PLATFORMS = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
const WINDOWS_PLATFORMS = ['Win32', 'Win64', 'Windows', 'WinCE'];
const IOS_PLATFORMS = ['iPhone', 'iPad', 'iPod'];
const BATCH_PAYMENT_MODE = createEnumMap(enums.batchPaymentMode);
const BATCH_PAYMENT_TASKS = mapEnum(enums.batchPaymentTasks);
const BATCH_DETAIL_TYPES = mapEnum(enums.summaryStatus);
const CHARGE_STYLE_CONFIG = createEnumMap(enums.chargeStyleConfig);
const FACILITY_ACTION_TYPE = createEnumMap(enums.facilityActionType);
const DELINQUENCY_ACTION_TYPE = createEnumMap(enums.delinquencyActionType);
const CONFIGURATION_STORE_TYPE = createEnumMap(enums.configurationStoreType);
const FACILITY_LICENSE_STATUS = mapEnum(enums.facilityLicenseStatus);
const DOCUMENT_STATUS = mapEnum(enums.documentStatus);
const CONFIGURATION_TYPE = mapEnum(enums.configurationType);

const MODE = [
    { icon: 'cp-email-outlined', value: 'Email' },
    { icon: 'cp-phone', value: 'Phone' },
    { icon: 'cp-text-outlined', value: 'Text' },
];

const CALENDAR_VIEW_TYPE = mapEnum(enums.calendarViewType);

export {
    CURRENCY_MAP, PRIMARY_PHONE, TIMEOUT, VIEW, STATUS, YES_NO_LIST, NO_OF_COLUMNS_FORM, BROWSERS_LIST,
    HEADER_HEIGHT, CLIENT_HEIGHT, CLIENT_WIDTH, VERTICAL_PADDING, HORIZONTAL_PADDING, VERTICAL_PADDING_FOR_TABLET_UP,
    HORIZONTAL_PADDING_FOR_TABLET_UP, VERTICAL_PADDING_FOR_LAPTOP_UP, HORIZONTAL_PADDING_FOR_LAPTOP_UP, SAFARI_ADDRESS_BAR_HEIGHT,
    ACTIVATE, DEACTIVATE, SUFFIX, SALUTATIONS, DATE_FORMAT, SOURCE, DOCUMENT_STATUS, MODE, SEARCH_MAX_SIZE,
    EMPTY_FIELD_TEXT, EMPTY_DATE_TEXT, AUTO_SUGGEST_RESOURCE, FEATURE, ACTION_TYPE, DASHBOARD_TYPE,
    ENCRYPTED_ID_LENGTH, FORM_MODE, SLIDE_DIRECTION, TRANSITION_VARIANT, TABLE_ROW_HEIGHT_VARIANT, TRANSACTION_TYPE,
    RESERVATION_STATUS, TABLE_STATE, ENTITY_LEVEL, DELETE_OVERLAY_ACTION, DELETE_OVERLAY_DELETION_STATUS,
    USER_TYPE, WS_EVENT_TYPE, WS_EVENT_SOURCE, SEARCH_DATE_FORMAT, EMPTY_FUNC, UNIT_AGGREGATION, EMPTY_STRING,
    DATETIME_FORMAT, TIME_WINDOW, MILITARY_TYPE, MILITARY_BRANCH, EMPTY_OBJECT, NONE_OPTION, REPORT_TYPE,
    COMPARISON_OPERATION, TWELVE_HOUR_DATETIME_FORMAT, RENT_ASSESSMENT_STATUS, CONFIG_RESOURCE, PRETTY_DATE_FORMAT,
    INTERNAL_SOURCE, SESSION_TIMEOUT, FULL_DATE_FORMAT, FULL_DATE_TIME_FORMAT, LETTER_EDITOR_SOURCE, ALL_ROUNDING_TYPES,
    CARD_PROCESSOR_TRANSACTION_STATUS, INSURANCE_SOURCE, ONE_MINUTE, FIVE_MINUTES, TOKEN_TYPE, DOCUMENT_TYPE,
    DB_DATETIME_FORMAT, SETUP_STATUS, TENANT_CHANGE_TRACKING_STATUS, TENANT_CHANGE_TYPE,
    EMPTY_LIST, RENT_ASSESSMENT_MODE, OTHER_EXTERNAL_ACTIVITY_TRACKING_STATUS, OCCUPANCY_CHARGE_OPTION_LIST,
    MOVE_IN_BILL_EVERY_MONTH_OPTIONS_LIST, MOVE_OUT_CHARGES_OPTIONS_LIST, FULL_DATE_HYPHEN_FORMAT,
    TAXES, RENT_TAXABLE_YES_OR_NO_LIST, RETAIL_ITEMS_TAXABLE_YES_OR_NO_LIST, SMALL_TEXTFIELD_WIDTH,
    DROPDOWN_MAX_HEIGHT, OS_LIST, MAC_PLATFORMS, WINDOWS_PLATFORMS, IOS_PLATFORMS, BATCH_PAYMENT_MODE,
    BATCH_PAYMENT_TASKS, BATCH_DETAIL_TYPES, CHARGE_STYLE_CONFIG, FACILITY_ACTION_TYPE,
    FACILITY_LICENSE_STATUS, CONFIGURATION_STORE_TYPE, FIVE_SECONDS, DEMO_FACILITY_ID_LIST,
    DELINQUENCY_STATUS, DELINQUENCY_ACTION_TYPE, LOCKOUT_TYPE, DELINQUENCY_SCHEDULED_STATUS, CALENDAR_VIEW_TYPE,
    NUMBER_OF_DAYS_IN_WEEK, MAX_WEEKS_IN_MONTH, FULL_YEAR_FORMAT, SHORT_YEAR_FORMAT,
    EXPORT_DATE_FORMAT, CONFIGURATION_TYPE, DETAILED_DATETIME_FORMAT, SEARCH_DOCUMENT_SOURCE,
};
