/* eslint-disable import/prefer-default-export */
import { withStyles, Grid } from
'Commons/components/generic/componentlibrary/components/Components';
import { EMPTY_FIELD_TEXT } from 'Commons/config/constants/Constants';
import Typography from 'Generic/typography/components/Typography';
import Tooltip from 'Commons/components/generic/tooltip/components/Tooltip';
import SummaryFormatterStyle from '../styles/SummaryFormatterStyle';

const { deviceInfo: { isMobile, isPhablet } } = window;

const Description = (props) => {
    const { argument: [particulars, , , { date }, { isResponsive = false }], classes } = props;
    return (
        isResponsive || isMobile || isPhablet
            ? (
                <Grid container direction="column">
                    <Typography variant="body2" noWrap>{ particulars }</Typography>
                    <Typography variant="caption">{ date }</Typography>
                </Grid>
            )
            : (
                <Tooltip
                    placement="bottom"
                    event="onHover"
                    className={classes.ellipsis}
                    title={<Typography noWrap variant="body1">{ particulars }</Typography>}
                >
                    <Typography variant="body1" noWrap>{ particulars }</Typography>
                </Tooltip>
            )

    );
};
Description.propTypes = { argument: PropTypes.array, classes: PropTypes.object };
Description.defaultProps = { argument: [] };
const DescriptionFormatter = withStyles(SummaryFormatterStyle)(Description);

const DateFormatterComponent = (props) => {
    const { argument: [date], classes } = props;
    return (
        <Typography variant="body2" align="center" className={classes.date} fontFamily="Open Sans">
            { date }
        </Typography>
    );
};

DateFormatterComponent.propTypes = { argument: PropTypes.array, classes: PropTypes.object };
DateFormatterComponent.defaultProps = { argument: [] };
const DateFormatter = withStyles(SummaryFormatterStyle)(DateFormatterComponent);

const Balance = (props) => {
    const { argument, classes } = props;
    const [balance] = argument;
    let balanceToShow = '';
    if (balance !== EMPTY_FIELD_TEXT) {
        balanceToShow = `${balance}`;
    }
    return (
        <Typography
            variant="body2"
            fontFamily="open Sans"
            align="right"
            className={classes.balance}
        >
            {balanceToShow}
        </Typography>
    );
};

Balance.propTypes = { argument: PropTypes.array, classes: PropTypes.object };
Balance.defaultProps = { argument: [] };
const BalanceFormatter = withStyles(SummaryFormatterStyle)(Balance);

const dateFormatter = (...data) => <DateFormatter argument={data} />;
const balanceFormatter = (...data) => <BalanceFormatter argument={data} />;
const descriptionFormatter = (...data) => <DescriptionFormatter argument={data} />;

export { dateFormatter, balanceFormatter, descriptionFormatter };
