import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { FormTextField } from 'Generic/textfield/components/TextField';
import { SM_6, XL_9 } from 'Commons/config/constants/GridSpacingConstants';
import { IS_MAX_LENGTH_50 } from 'Commons/config/constants/Validators';
import localisable from 'Commons/config/strings/localisable';
import bevLeftSidebarStyles
from 'Commons/components/business/bev/components/setup/bevLeftSideBar/styles/BevLeftSidebarStyles';
import { isTwoDecimalPlaces } from 'Commons/helpers/utils/validator/Validator';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { FormDropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import { memo } from 'react';
import { PLACEHOLDER_TYPE } from '../../../../config/Constants';
import BevFormField from './BevFormFieldWrapper';

const useStyles = makeStyles(bevLeftSidebarStyles, { name: 'PlaceholderFormComponent' });
const PlaceholderFormComponent = ({
    setupModeCanvasRef,
    formProps: { setFieldValue, values: { placeholderType } = {} } = {},
    requiredFieldsConfig,
    requiredFieldsConfig: {
        width: isWidthRequired = false,
        depth: isDepthRequired = false,
        label: isLabelRequired = false,
        placeholderType: isTypeRequired = false,
    },
    setRequiredFieldsConfig,
}) => {
    const classes = useStyles();

    const placeholderTypeList = useConstructor(() => Object.values(PLACEHOLDER_TYPE));
    const { validatorsForWidthDepthAndAngleFields } = useConstructor(() => ({ validatorsForWidthDepthAndAngleFields: isTwoDecimalPlaces }));

    const requiredFieldsConfigLength = Object.keys(requiredFieldsConfig).length;

    const handleFieldChange = ({ target: { name: property, value: newLabel } = {} } = {}) => {
        setupModeCanvasRef.modifyEntityData(property, newLabel);
    };

    const handlePlaceholderTypeChange = (e, value) => {
        if (value !== PLACEHOLDER_TYPE.Others.value) {
            setRequiredFieldsConfig({
                type: true,
                width: true,
                depth: true,
                label: false,
            }, false);
            setFieldValue('label', value);
            setupModeCanvasRef.modifyEntityData('label', value);
        } else {
            setFieldValue('label', '', false);
            setupModeCanvasRef.modifyEntityData('label', '');
            setRequiredFieldsConfig(undefined, true);
        }
        setupModeCanvasRef.modifyEntityData('placeholderType', value);
    };

    return (
        <Grid container className={classes.sideBarTopMargin}>
            <BevFormField
                component={FormDropdown}
                key={`${requiredFieldsConfigLength}-${localisable.selectPlaceholder}`}
                label={localisable.selectPlaceholder}
                placeholder={localisable.selectPlaceholderType}
                name="placeholderType"
                GridProps={XL_9}
                onChange={handlePlaceholderTypeChange}
                required={isTypeRequired}
                list={placeholderTypeList}
            />
            {isLabelRequired && placeholderType === PLACEHOLDER_TYPE.Others.value && (
                <BevFormField
                    key={`${requiredFieldsConfigLength}-${localisable.editPlaceholderName}`}
                    component={FormTextField}
                    label={localisable.editPlaceholderName}
                    name="label"
                    placeholder={localisable.editPlaceholderName}
                    validate={{ ...IS_MAX_LENGTH_50 }}
                    required={isLabelRequired}
                    GridProps={XL_9}
                    onChange={handleFieldChange}
                    useField
                />
            )}
            <BevFormField
                key={`${requiredFieldsConfigLength}-${localisable.enterWidth}`}
                component={FormTextField}
                label={localisable.bevWidthFieldLabel}
                name="bev.width"
                type="number"
                placeholder={localisable.enterWidth}
                required={isWidthRequired}
                GridProps={SM_6}
                onChange={handleFieldChange}
                validate={validatorsForWidthDepthAndAngleFields}
            />
            <BevFormField
                key={`${requiredFieldsConfigLength}-${localisable.enterDepth}`}
                component={FormTextField}
                label={localisable.bevDepthFieldLabel}
                name="bev.depth"
                type="number"
                placeholder={localisable.enterDepth}
                required={isDepthRequired}
                GridProps={SM_6}
                onChange={handleFieldChange}
                validate={validatorsForWidthDepthAndAngleFields}
            />
            <BevFormField
                component={FormTextField}
                label={localisable.bevAngleFieldLabel}
                name="bev.angle"
                placeholder={localisable.bevAngleFieldPlaceholder}
                type="number"
                componentClasses={{ number: classes.number }}
                GridProps={SM_6}
                onChange={handleFieldChange}
                validate={validatorsForWidthDepthAndAngleFields}
            />
        </Grid>
    );
};


PlaceholderFormComponent.propTypes = {
    setupModeCanvasRef: PropTypes.object,
    formProps: PropTypes.object,
    requiredFieldsConfig: PropTypes.object.isRequired,
    setRequiredFieldsConfig: PropTypes.func,
};

PlaceholderFormComponent.defaultProps = {
    setupModeCanvasRef: {},
    formProps: {},
};

export default memo(PlaceholderFormComponent);
