import { memo } from 'react';
import Button from 'Generic/button/components/Button';
import { Grid, makeStyles, Typography } from 'Generic/componentlibrary/components/Components';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import localisable from 'Commons/config/strings/localisable';
import bevLeftSidebarStyles
from 'Commons/components/business/bev/components/setup/bevLeftSideBar/styles/BevLeftSidebarStyles';
import { POSITIONS, ROTATE_BY, TYPE_OF_ENTITY } from '../../../../config/Constants';

const useStyles = makeStyles(bevLeftSidebarStyles, { name: 'BevLeftSideBar' });

const IconButtonWithTooltip = ({ tooltip, ...props }) => (
    <Tooltip title={tooltip}>
        <Button
            variant="icon"
            iconType="custom"
            color="primary"
            {...props}
        />
    </Tooltip>
);

const Toolbox = ({ setupModeCanvasRef, shouldDisableToolBox, entityType }) => {
    const classes = useStyles();
    const {
        bevLeftAlignTooltip,
        bevRightAlignTooltip,
        bevTopAlignTooltip,
        bevBottomAlignTooltip,
        bevHorizontalCenterTooltip,
        bevVerticalCenterTooltip,
        bevRotateTooltip,
        bevDeleteTooltip,
    } = localisable;
    return (
        <Grid container direction="column" justify="space-around">
            <Typography variant="subtitle1">
                {localisable.bevToolBox}
            </Typography>
            <Grid container justify="space-around" className={classes.sideBarTopMargin}>
                <IconButtonWithTooltip
                    icon="cp-align-left"
                    tooltip={bevLeftAlignTooltip}
                    disabled={shouldDisableToolBox}
                    onClick={() => setupModeCanvasRef.align(POSITIONS.LEFT)}
                />
                <IconButtonWithTooltip
                    icon="cp-align-right"
                    tooltip={bevRightAlignTooltip}
                    disabled={shouldDisableToolBox}
                    onClick={() => setupModeCanvasRef.align(POSITIONS.RIGHT)}
                />
                <IconButtonWithTooltip
                    icon="cp-align-top"
                    tooltip={bevTopAlignTooltip}
                    disabled={shouldDisableToolBox}
                    onClick={() => setupModeCanvasRef.align(POSITIONS.TOP)}
                />
                <IconButtonWithTooltip
                    icon="cp-align-bottom"
                    tooltip={bevBottomAlignTooltip}
                    disabled={shouldDisableToolBox}
                    onClick={() => setupModeCanvasRef.align(POSITIONS.BOTTOM)}
                />
            </Grid>
            <Grid container justify="space-around" className={classes.sideBarTopMargin}>
                <IconButtonWithTooltip
                    icon="cp-align-horizontal"
                    tooltip={bevHorizontalCenterTooltip}
                    disabled={shouldDisableToolBox}
                    onClick={() => setupModeCanvasRef.align(POSITIONS.HCENTER)}
                />
                <IconButtonWithTooltip
                    icon="cp-align-vertical"
                    tooltip={bevVerticalCenterTooltip}
                    disabled={shouldDisableToolBox}
                    onClick={() => setupModeCanvasRef.align(POSITIONS.VCENTER)}
                />
                <IconButtonWithTooltip
                    icon="cp-rotate-90"
                    tooltip={bevRotateTooltip}
                    disabled={shouldDisableToolBox}
                    onClick={() => setupModeCanvasRef.rotate(ROTATE_BY.NINETY_DEG)}
                />
                <IconButtonWithTooltip
                    icon="cp-delete"
                    tooltip={bevDeleteTooltip}
                    disabled={shouldDisableToolBox || entityType === TYPE_OF_ENTITY.Unit.value}
                    onClick={() => setupModeCanvasRef.delete()}
                />
            </Grid>
        </Grid>
    );
};

Toolbox.propTypes = {
    setupModeCanvasRef: PropTypes.object,
    shouldDisableToolBox: PropTypes.bool,
    entityType: PropTypes.string,
};

Toolbox.defaultProp = {
    setupModeCanvasRef: {},
    shouldDisableToolBox: false,
};

IconButtonWithTooltip.propTypes = { tooltip: PropTypes.string };

export default memo(Toolbox);
