import localisable from 'Commons/config/strings/localisable';
import Button from 'Generic/button/components/Button';
import { clsx } from 'Commons/helpers/utils/clsx';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import { sortArray } from 'Commons/helpers/utils/DataHelpers';
import { Checkbox } from '../../checkbox/components/CheckBox';
import Form from '../../form/components/Form';
import { MenuItem, FormControlLabel, withStyles, Typography }
from '../../componentlibrary/components/Components';
import dropdownStyle from '../styles/DropdownStyle';
import Popdown from '../../popdown/components/Popdown';

class Dropdown extends React.Component {
    static indexByValue = list => list.reduce((accumulator, item) => ({
        ...accumulator,
        [item.value]: item.label,
    }), {});

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const { list } = nextProps;
        if (list !== prevState.list) {
            const indexedList = Dropdown.indexByValue(list);
            return { indexedList };
        }
        return null;
    };

    constructor(props) {
        super(props);
        const { trackValue, value = this.getDefaultValue() } = this.props;
        this.state = {
            open: false,
            value: trackValue ? value : undefined,
        };
    }

    componentDidMount() {
        const { onRef } = this.props;
        if (onRef) {
            onRef(this);
        }
    }

    componentWillUnmount() {
        const { onRef } = this.props;
        if (onRef) {
            onRef(undefined);
        }
    }

    onClickAway = () => {
        this.setState({ open: false });
    };

    onClick = () => {
        const { readOnly } = this.props;
        if (!readOnly) {
            this.setState(prevState => (!prevState.open ? { open: true } : null));
        }
    };

    getDefaultValue = () => {
        const { multiselect } = this.props;
        return multiselect ? [] : '';
    };

    getValue = () => {
        const { value: stateValue = this.getDefaultValue() } = this.state;
        const { value: propValue = this.getDefaultValue(), trackValue } = this.props;
        return trackValue ? stateValue : propValue;
    };

    clear = (event) => {
        const { onChange } = this.props;
        this.setState({ value: null });
        onChange(event, null);
    }

    onChangeMultiSelect = (event, item) => {
        const { onChange } = this.props;
        const value = [...this.getValue()];
        if (event.target.checked) {
            value.unshift(item.value);
        } else {
            const index = value.indexOf(item.value);
            if (index > -1) { value.splice(index, 1); }
        }
        this.setState({ value });
        onChange(event, value);
    };

    onChangeSingleSelect = (event, item) => {
        const { onChange, trackValue } = this.props;
        const { value } = item;
        const { value: prevValue } = this.state;
        const newState = { open: false };
        if (value !== prevValue) {
            if (trackValue) {
                newState.value = value;
            }
            onChange(event, value, item);
        }
        this.setState(newState);
    };

    renderValue = () => {
        const { multiselect } = this.props;
        const { indexedList = {} } = this.state;
        const value = this.getValue();
        const singleSelectValue = indexedList[value];
        if (multiselect) {
            return [...value].map(curValue => indexedList[curValue] || undefined).join(', ');
        }
        /* Label gets set as value of the input component, to avoid setting the object as value to the
        input component, setting this value as empty string. This will work for the use cases where
        we are showing different input component and that component takes care of showing the value */
        return typeof singleSelectValue === 'string' ? singleSelectValue : '';
    };

    renderLabel = (label, value = '', optionalFormatter = null) => {
        if (typeof (optionalFormatter) === 'function') {
            return optionalFormatter(label, value);
        }

        return (
            typeof label === 'string'
                ? <Typography variant="body1">{label}</Typography>
                : label
        );
    }


    isListEmpty = () => {
        const { list } = this.props;
        return !list.length;
    };

    render() {
        const {
            multiselect, list, maxHeight, renderValue = this.renderValue, trackValue, value: propValue,
            addon, classes, form, onChange, PopdownProps, disabled, textFieldClasses, noOptionsMessage,
            InputProps: {
                className,
                inputProps,
                classes: { root: inputRootClasses, disabled: inputDisabledClasses, ...inputClasses } = {},
                ...InputProps
            } = {}, ListItemProps: { className: listItemClassName, ...ListItemProps },
            className: dropdownClassName, readOnly, sortBy,
            optionalFormatter, clearable,
            ...props
        } = this.props;
        const { onRef, ...restProps } = props;
        const value = this.getValue();
        const { open } = this.state;
        return (
            <div className={clsx(classes.dropdown, dropdownClassName)}>
                <Popdown
                    value={renderValue()}
                    trackValue={false}
                    disabled={disabled}
                    InputProps={{
                        inputProps: { readOnly: true, ...inputProps },
                        onClick: this.onClick,
                        className,
                        classes: {
                            root: clsx(classes.inputRoot, inputRootClasses),
                            disabled: clsx(classes.disabled, inputDisabledClasses),
                            ...inputClasses,
                        },
                        ...InputProps,
                    }}
                    readOnly={readOnly}
                    addon={{
                        end: !readOnly && (
                            (value && clearable)
                                ? (
                                    <Button
                                        variant="icon"
                                        icon="cp-close"
                                        color="primary"
                                        disabled={disabled}
                                        iconType="custom"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.clear(event);
                                        }}
                                        IconProps={{ size: 'medium' }}
                                    />
                                )
                                : (
                                    <Button
                                        variant="icon"
                                        icon="cp-arrow-down"
                                        iconType="custom"
                                        color="primary"
                                        disabled={disabled}
                                        className={classes.iconArrowDown}
                                    />
                                )),
                        ...addon,
                    }}
                    open={open}
                    onClickAway={this.onClickAway}
                    classes={{ paper: classes.paper }}
                    maxHeight={maxHeight}
                    TextFieldClasses={textFieldClasses}
                    PaperProps={{ square: true }}
                    {...restProps}
                    {...PopdownProps}
                >
                    {
                        this.isListEmpty()
                            ? (
                                <Typography
                                    variant="body1"
                                    align="center"
                                    className={`${classes.listItem} ${classes.message}`}
                                >
                                    {noOptionsMessage}
                                </Typography>
                            )
                            : (sortBy ? sortArray(list, sortBy) : list).map((item, index) => {
                                const { isDisabled = false } = item;
                                const onClickProp = !multiselect
                                    ? { onClick: e => this.onChangeSingleSelect(e, item) } : {};
                                return (
                                    <MenuItem
                                        cy-id={`option_${index}`}
                                        disableRipple
                                        key={item.value}
                                        disabled={isDisabled}
                                        className={clsx(classes.listItem,
                                            { [classes.selected]: value === item.value && !multiselect },
                                            listItemClassName)
                                        }
                                        {...ListItemProps}
                                        {...onClickProp}
                                    >
                                        {
                                            multiselect
                                                ? (
                                                    <FormControlLabel
                                                        className={classes.formControlLabel}
                                                        control={(
                                                            <Checkbox
                                                                disableRipple
                                                                value={value.includes(item.value)}
                                                                color="primary"
                                                                onChange={e => this.onChangeMultiSelect(e, item)}
                                                            />
                                                        )}
                                                        label={this.renderLabel(item.label)}
                                                    />
                                                )
                                                : this.renderLabel(item.label, item.value, optionalFormatter || {})
                                        }
                                    </MenuItem>
                                );
                            })
                    }
                </Popdown>
            </div>
        );
    }
}

Dropdown.propTypes = {
    name: PropTypes.string,
    form: PropTypes.object,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    trackValue: PropTypes.bool,
    multiselect: PropTypes.bool,
    renderValue: PropTypes.func,
    className: PropTypes.string,
    InputProps: PropTypes.object,
    PopdownProps: PropTypes.object,
    ListItemProps: PropTypes.object,
    noOptionsMessage: PropTypes.string,
    textFieldClasses: PropTypes.object,
    classes: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    addon: PropTypes.shape({
        start: PropTypes.node,
        end: PropTypes.node,
    }),
    list: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
    onRef: PropTypes.func,
    sortBy: PropTypes.arrayOf(PropTypes.string),
    optionalFormatter: PropTypes.func,
    clearable: PropTypes.bool,
};

Dropdown.defaultProps = {
    list: [],
    addon: {},
    maxHeight: 200,
    trackValue: true,
    PopdownProps: {},
    disabled: false,
    ListItemProps: {},
    clearable: false,
    multiselect: false,
    sortBy: ['label'],
    onChange: () => { },
    textFieldClasses: {},
    form: { setFieldValue: () => { } },
    noOptionsMessage: localisable.noOptions,
};

const DropdownComponent = withStyles(dropdownStyle)(Dropdown);
DropdownComponent.displayName = FORM_COMPONENTS.DROPDOWN;
const FormDropdown = Form(DropdownComponent);

export {
    DropdownComponent as Dropdown,
    FormDropdown,
};
